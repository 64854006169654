import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import { purple900 } from 'material-ui/styles/colors';
import { Dimmer, Loader } from 'semantic-ui-react';
import ReactTable from "react-table";
import { filter, UserCell } from '../util/helpers'
import {
    Grid, Row, Col
} from 'react-bootstrap';
import Card from '../tim-components/Card.jsx';
import '../assets/css/bootstrap.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';

class UserDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: true,
            userDevices: []
        };
    }

    componentDidMount() {
        this.fetchAllUserDevices();
    }

    fetchAllUserDevices = () => {
        axios.get('/admin/support/user_devices')
            .then(response => {
                const userDevices = response.data.map(userDevice => {
                    let date;
                    if (moment(userDevice.created_at).isValid()) {
                        date = moment(userDevice.created_at).format('YYYY-MM-DD | HH:mm')
                    } else {
                        date = "-"
                    }

                    return {
                        displayName: userDevice.display_name,
                        userId: userDevice.user_id,
                        deviceId: userDevice.device_id,
                        createdAt: date
                    }
                });

                this.setState({
                    userDevices,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.error("Error: ", error);
                this.setState({
                    isProcessing: false
                });
            });
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        const { userDevices } = this.state

        return (
            <div>
                <h2 style={{ textAlign: 'center' }}> All User Device Id Pairings ({userDevices.length}) </h2>
                <div>
                    {this.state.isProcessing && userDevices.length === 0 ? <LoadingIndicator /> :

                        <div className="main-content">
                            <Grid fluid>
                                <Row>
                                    <Col md={12}>
                                        <Card
                                            content={
                                                <ReactTable
                                                    data={userDevices}
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: "User",
                                                            accessor: "displayName",
                                                            width: 200,
                                                            Cell: row => {
                                                                return UserCell(row.value, row.original.userId)
                                                            }
                                                        },
                                                        {
                                                            Header: "Device Id",
                                                            accessor: "deviceId",
                                                            width: 320,
                                                        },
                                                        {
                                                            Header: "Created At",
                                                            accessor: "createdAt",
                                                            width: 160
                                                        }
                                                    ]}
                                                    defaultPageSize={20}
                                                    defaultSorted={[
                                                        {
                                                            id: "updatedAt",
                                                            desc: true
                                                        }
                                                    ]}
                                                    showPaginationTop
                                                    showPaginationBottom
                                                    defaultFilterMethod={filter}
                                                    className="-striped -highlight"
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                </div>

                <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                    <Dimmer active={this.state.isProcessing} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </div>
        );
    }
}

export default UserDevices;
