import React, { Component } from 'react';
import axios from 'axios';
import CircularProgress from 'material-ui/CircularProgress';
import { UserCell, NumberCell } from '../util/helpers'
import {
    Grid,
    Row,
    Col
} from "react-bootstrap";
import { Table } from 'semantic-ui-react'
import RaisedButton from 'material-ui/RaisedButton'
import { notification } from 'antd'

export default class MobileMoneyProfile extends Component {

    state = {
        details: [],
        isProcessing: true,
        totalDeposits: 0,
        totalWithdrawals: 0,
        depositsCount: 0,
        withdrawalsCount: 0,
        currency: null,
        blocked: false
    }

    componentDidMount() {
        this.handleFetchLinkedStats()
    }

    handleFetchLinkedStats = () => {

        const { momophone } = this.props.match.params
        var total_deposits = 0;
        var total_withdrawals = 0;
        var deposits_count = 0;
        var withdrawals_count = 0;
        var linked_currency = null;
        axios.get(`/admin/support/stats/${momophone}`)
            .then(response => {
                console.log(response);
                let details = response.data.map(detail => {
                    total_deposits = total_deposits + detail.total_deposits_volume
                    total_withdrawals = total_withdrawals + detail.total_withdrawals_volume
                    deposits_count = deposits_count + detail.total_deposits_count
                    withdrawals_count = withdrawals_count + detail.total_withdrawals_count
                    linked_currency = detail.currency
                    return {
                        depositsCount: detail.total_deposits_count,
                        depositsVolume: detail.total_deposits_volume,
                        phone: detail.phone,
                        withdrawalsCount: detail.total_withdrawals_count,
                        withdrawalsVolume: detail.total_withdrawals_volume,
                        id: detail.user_id,
                        displayName: detail.display_name,
                        linkedAccountID: detail.linked_account_id
                    }
                });
                console.log(details);

                this.setState({
                    details,
                    totalDeposits: total_deposits,
                    totalWithdrawals: total_withdrawals,
                    depositsCount: deposits_count,
                    withdrawalsCount: withdrawals_count,
                    currency: linked_currency,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.log("Error: ", error.response)
            });

        axios.get(`/admin/support/blocked_momo_accounts/${momophone}`)
            .then(response => {
                console.log(response);
                this.setState({
                    blocked: true
                })
            })
            .catch(error => {
                console.log("Error: ", error.response)
                this.setState({
                    blocked: false
                })
            });
    }

    unblockMomoNumber = (name, id, momoNumber) => {

        this.setState({
            isProcessing: true
        })

        axios.post('/admin/support/blocked_momo_accounts/remove', {
            phone: momoNumber
        })
            .then(response => {
                console.log(response);
                const description = `${momoNumber} now unlocked`
                notification.success({
                    message: 'Success!',
                    description,
                });

                this.handleFetchLinkedStats()
                this.setState({
                    blocked: false
                });
            })
            .catch(error => {
                const description = error.response.data.error.message + "."
                notification.warning({
                    message: 'Uh oh!',
                    description,
                });

                console.error("Error: ", error);
                this.setState({
                    blocked: true
                });
            });


    }

    blockMomoNumber = (name, id, momoNumber) => {

        var phone = String(momoNumber)
        var userId = String(id)

        this.setState({
            isProcessing: true
        })

        axios.post('/admin/support/blocked_momo_accounts/add', {
            phone: phone,
            userId: userId
        })
            .then(response => {
                console.log(response);
                const description = `${phone}  now blocked`
                notification.success({
                    message: 'Success!',
                    description,
                });

                this.handleFetchLinkedStats()
                this.setState({
                    blocked: false
                });
            })
            .catch(error => {
                const description = error.response.data.error.message + "."
                notification.warning({
                    message: 'Uh oh!',
                    description,
                });

                console.error("Error: ", error);
                this.setState({
                    blocked: false
                });
            });
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color="black" />
            </div>
        );

        var momoNumber = this.props.match.params.momophone;

        return (
            <div style={{ marginTop: '1px' }}>
                <div>
                    {this.state.isProcessing ? <LoadingIndicator /> :
                        <div>

                            <div align="center">
                                <Grid fluid>
                                    <Row>
                                        <Col md={9}>
                                            <h3>Mobile Money Stats For: <b>{momoNumber}</b>
                                                {
                                                    this.state.blocked ? <span style={{ color: '#962C3E', fontWeight: 'bold' }}> BLOCKED</span> :
                                                        <span></span>
                                                }
                                            </h3>
                                        </Col>
                                        <Col md={2}>

                                        </Col>
                                    </Row>
                                </Grid>
                            </div>

                            <Grid fluid>
                                <Row>
                                    <Col md={12}>
                                        <Table fixed basic>
                                            <Table.Header>
                                                <Table.Row>
                                                    {
                                                        this.state.blocked ? <Table.HeaderCell></Table.HeaderCell> :
                                                            <Table.HeaderCell>Action</Table.HeaderCell>
                                                    }

                                                    <Table.HeaderCell>User</Table.HeaderCell>
                                                    <Table.HeaderCell>Deposit Count</Table.HeaderCell>
                                                    <Table.HeaderCell>Deposit Volume</Table.HeaderCell>
                                                    <Table.HeaderCell>Withdrawal Count</Table.HeaderCell>
                                                    <Table.HeaderCell>Withdrawal Volume</Table.HeaderCell>
                                                    <Table.HeaderCell>Linked Account ID</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    this.state.details.map((item) =>
                                                        <Table.Row>
                                                            {
                                                                this.state.blocked ? <Table.Cell><RaisedButton label="Unlock" onClick={() => this.unblockMomoNumber(item.displayName, item.id, item.phone)} /></Table.Cell> :
                                                                    <Table.Cell><RaisedButton label="Block" secondary={true} onClick={() => this.blockMomoNumber(item.displayName, item.id, item.phone)} /></Table.Cell>
                                                            }

                                                            <Table.Cell>{UserCell(item.displayName, item.id)}</Table.Cell>
                                                            <Table.Cell>{NumberCell(item.depositsCount)}</Table.Cell>
                                                            <Table.Cell>{this.state.currency} {NumberCell(item.depositsVolume)}</Table.Cell>
                                                            <Table.Cell>{NumberCell(item.withdrawalsCount)}</Table.Cell>
                                                            <Table.Cell>{this.state.currency} {NumberCell(item.withdrawalsVolume)}</Table.Cell>
                                                            <Table.Cell>{item.linkedAccountID}</Table.Cell>
                                                        </Table.Row>
                                                    )
                                                }
                                            </Table.Body>
                                            <Table.Footer>
                                                <Table.Row>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                    <Table.HeaderCell><label>Totals: </label></Table.HeaderCell>
                                                    <Table.HeaderCell>{NumberCell(this.state.depositsCount)}</Table.HeaderCell>
                                                    <Table.HeaderCell>{this.state.currency}  {NumberCell(this.state.totalDeposits)}</Table.HeaderCell>
                                                    <Table.HeaderCell>{NumberCell(this.state.withdrawalsCount)}</Table.HeaderCell>
                                                    <Table.HeaderCell>{this.state.currency}  {NumberCell(this.state.totalWithdrawals)}</Table.HeaderCell>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                </div>
            </div>
        )
    }
}