import Chipper from "@chippercash/chipper-sdk";
const TOKEN_KEY = "accessToken";

let initialEnvironment;

// This was based on the url
if (
  window.location.hostname === "web.chippercash.com" ||
  window.location.hostname === "chipper-cash.firebaseapp.com"
) {
  // Ensure production is always production
  initialEnvironment = "production";
} else if (
  window.location.hostname === "web-staging.chippercash.com" ||
  window.location.hostname === "chipper-staging.firebaseapp.com"
) {
  // Ensure staging is always staging
  initialEnvironment = "staging";
} else {
  // !!! HERE is where you can do whatever you want.
  initialEnvironment = "production";
}

let api;

export const setEnvironment = (environment) => {
  if (environment === "production") {
    setBaseURL(
      "https://api.chippercash.com/v1/",
      "https://auth.chippercash.com/",
      "https://compliance.chippercash.com/"
    );
  } else if (environment === "staging") {
    setBaseURL(
      "https://staging.chippercash.com/v1/",
      "https://auth-staging.chippercash.com/",
      "https://compliance-staging.chippercash.com/"
    );
  } else {
    // Local - Do whatever you want
    // setBaseURL("http://localhost:3002/v1/");
    setBaseURL(
      "https://api.chippercash.com/v1/",
      "https://auth.chippercash.com/",
      "https://compliance.chippercash.com/"
    );
  }
};

export const loadSavedToken = async () => {
  const token = await localStorage.getItem(TOKEN_KEY);
  console.log("@@ Token: ", token);
  if (token !== null) {
    api.config.setAuthToken(token);
  }
};

const setBaseURL = (url, authUrl, complianceUrl) => {
  console.log("Connecting api to ", url);
  api = new Chipper("", url);

  api.config.setAuthUrl(authUrl);
  api.config.setComplianceUrl(complianceUrl);
  window.complianceUrl = complianceUrl; // Temp Hack
};

setEnvironment(initialEnvironment);
export default api;
