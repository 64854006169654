import React, { Component } from 'react';
import axios from 'axios';
import RaisedButton from 'material-ui/RaisedButton';
import { purple900 } from 'material-ui/styles/colors';
import CircularProgress from 'material-ui/CircularProgress';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Phone, { isValidPhoneNumber } from 'react-phone-number-input'

const fieldStyle = {
    hintStyle: {
        color: purple900
    },
    underlineStyle: {
        borderColor: purple900
    },
    floatingLabelFocusStyle: {
        color: purple900
    },
};

const buttonStyle = {
    margin: 12
};

class AddLinkedAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            country: '',
            carrier: '',
            phone: '',

            isProcessing: false,

            message: ''
        };
    }

    componentDidMount() {
        const currency = localStorage.getItem('currency')
        const country = this.countryFor(currency)
        this.setState({
            currency,
            country
        })
    }

    carriersFor(country) {
        if (country === 'GH') {
            return [
                <MenuItem key={'MTN'} value={'MTN'} primaryText="MTN" />,
                <MenuItem key={'AIRTEL'} value={'AIRTEL'} primaryText="Airtel" />,
                <MenuItem key={'TIGO'} value={'TIGO'} primaryText="Tigo" />,
                <MenuItem key={'VODAFONE'} value={'VODAFONE'} primaryText="Vodafone" />,
            ];
        } else if (country === 'UG') {
            return [
                <MenuItem key={'MTN'} value={'MTN'} primaryText="MTN" />,
                <MenuItem key={'AIRTEL'} value={'AIRTEL'} primaryText="Airtel" />
            ];
        } else if (country === 'KE') {
            return [
                <MenuItem key={'MPESA'} value={'MPESA'} primaryText="MPESA" />
            ];
        }
    }

    nameFor = (country) => {
        switch (country) {
            case 'GH':
                return 'Ghanaian'
            case 'UG':
                return 'Ugandan'
            case 'KE':
                return 'Kenyan'
            default:
                break;
        }
    }

    countryFor(currency) {
        if (currency === 'GHS') {
            return 'GH'
        } else if (currency === 'UGX') {
            return 'UG'
        } else if (currency === 'KES') {
            return 'KE'
        }
    }

    handleCountryChange = (event, index, country) => {
        this.setState({
            country
        })
    }

    handleCarrierChange = (event, index, carrier) => {
        this.setState({
            carrier
        })
    }

    handlePhoneChange = (event) => {
        this.setState({
            phone: event.target.value
        });
    };

    addLinkedAccount = () => {
        this.setState({
            isProcessing: true
        })

        axios.post('/linked_accounts', {
            currency: this.state.currency,
            type: 'MOBILE_MONEY',
            carrier: this.state.carrier,
            country: this.state.country,
            phone: this.state.phone
        })
        .then(response => {
            console.log("Response: ", response)

            this.setState({
                isProcessing: false
            })

            // Go back to the cashout page
            this.props.history.push('/cashout');
        })
        .catch(error => {
            const message = error.response.data.error.message;
            console.log("Error: ", error)

            this.setState({
                isProcessing: false
            })

            window.notifications.addNotification({
                level: 'error',
                title: 'Cannot add Linked Account',
                message,
                position: 'bl'
            });
        })
    }

    render() {
        const { carrier, country, phone } = this.state
        const hasSelectedCarrier = !!carrier

        const LoadingIndicator = () => (
            <div>
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        return (
            <div style={{ textAlign: 'center' }}>
                <br />
                <h2 style={{fontSize: "1.3em", marginLeft: 10, marginRight: 10}}>Link a {this.nameFor(this.state.country)} mobile money account</h2>
                <br />
                
                <SelectField
                    style={{ textAlign: 'left' }}
                    hintText="Network"
                    floatingLabelText="Network"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.carrier}
                    onChange={this.handleCarrierChange}
                    selectedMenuItemStyle={fieldStyle.hintStyle}
                >
                    {this.carriersFor(country)}
                </SelectField>

                <br />
                <br />

                <Phone
                    style={{ width: 250, 'marginLeft': 'auto', 'marginRight': 'auto', 'fontSize': "1.1em"}}
                    country={country}
                    placeholder="Mobile Money Number"
                    value={phone}
                    onChange={phoneNumber => this.setState({ phone: phoneNumber })}
                    ref={(c) => this.phoneInput = c}
                />

                <div>
                    <RaisedButton
                        label="Link Mobile Money"
                        style={buttonStyle}
                        labelColor={purple900}
                        onTouchTap={this.addLinkedAccount}
                        disabled={this.state.isProcessing || !isValidPhoneNumber(this.state.phone) || !hasSelectedCarrier } />
                </div>

                {this.state.isProcessing ? <LoadingIndicator /> : ""}
            </div>
        )
    }
}

export default AddLinkedAccount;

/*(
        <SelectField
                    style={{ textAlign: 'left' }}
                    hintText="Country"
                    floatingLabelText="Country"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.country}
                    onChange={this.handleCountryChange}
                    selectedMenuItemStyle={fieldStyle.hintStyle}
                >
                    {countries}
                </SelectField>

        <TextField
            style={{ textAlign: 'left' }}
            hintText="Mobile Money Phone Number"
            floatingLabelText="Mobile Money Phone Number"
            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
            underlineFocusStyle={fieldStyle.underlineStyle}
            value={this.state.phone}
            onChange={this.handlePhoneChange}
            ref={(c) => this.lastInput = c} />
        <br />
)*/
