import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import { purple900 } from 'material-ui/styles/colors';
import { Dimmer, Loader, Label } from 'semantic-ui-react';
import ReactTable from "react-table";
import { colorForStatus } from '../util/colors'
import { filter, UserCell } from '../util/helpers'
import {
    Grid, Row, Col
} from 'react-bootstrap';
import Card from '../tim-components/Card.jsx';
import '../assets/css/bootstrap.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';
import RaisedButton from 'material-ui/RaisedButton'
import ReferralManualAdd from './ReferralManualAdd'

class Referrals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referrals: [],
            isProcessing: true,
        };
    }

    componentDidMount() {
        this.fetchAllReferrals();
    }

    fetchAllReferrals = () => {
        axios.get('/admin/support/referrals')
            .then(response => {
                console.log('Referrals: ', response.data)
                const referrals = response.data.map(referral => {
                    let date;
                    if (moment(referral.updated_at).isValid()) {
                        date = moment(referral.updated_at).format('YYYY-MM-DD | HH:mm')
                    } else {
                        date = "-"
                    }
                    let error = referral.last_error ? referral.last_error : '-'


                    return {
                        referrer: referral.referrer_display_name,
                        referrerId: referral.referrer_id,
                        invitedUser: referral.invited_user_display_name,
                        invitedUserId: referral.invited_user_id,
                        invitedUserTransactionCount: referral.invited_user_transaction_count,
                        status: referral.status,
                        updatedAt: date,
                        error,
                        actions: (
                            <div>
                                {(referral.status !== 'SETTLED') && <RaisedButton
                                    label="Complete"
                                    onTouchTap={() => this.processReferral(referral)}
                                />}
                            </div>
                        )
                    }
                });

                this.setState({
                    referrals,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.error("Error: ", error);
                this.setState({
                    isProcessing: false
                });
            });
    }

    processReferral = (referral) => {
        const message = `You sure you want to manually process this referral? ${referral.referrer_display_name} and ${referral.invited_user_display_name} will receive thier bonuses.`
        const response = window.confirm(message)
        if (response) {
            const url = `/admin/support/referrals/complete/${referral.id}`
            axios.post(url)
                .then(response => {
                    alert("This referral was processed successfully")
                    this.setState({
                        isProcessing: true
                    })
                    this.fetchAllReferrals()
                })
                .catch(error => {
                    let message
                    if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
                        message = error.response.data.error.message
                    } else if (error.message) {
                        message = error.message
                    }

                    alert(message)
                })
        }
    }

    handleManualAddAction = (referrerId, invitedUserId) => {
        axios.post('/admin/support/referrals/add', {
            referrerId,
            invitedUserId
        })
            .then(response => {
                console.log('Response: ', response)
                alert("Referral added successfully!")

                this.fetchAllReferrals()
            })
            .catch(error => {
                console.log('Error: ', error)
                alert(`Referral addition failed: ${JSON.stringify(error.response.data)}`)
            })
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        const { referrals } = this.state

        return (
            <div>
                <h2 style={{ textAlign: 'center' }}> All Referrals ({referrals.length}) </h2>


                <div style={{ width: 500 }}>
                    <ReferralManualAdd onAction={this.handleManualAddAction} />
                </div>


                <br /><br /><br />
                <div>
                    {this.state.isProcessing && referrals.length === 0 ? <LoadingIndicator /> :

                        <div className="main-content">
                            <Grid fluid>
                                <Row>
                                    <Col md={12}>
                                        <Card
                                            content={
                                                <ReactTable
                                                    data={referrals}
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: "Referrer",
                                                            accessor: "referrer",
                                                            width: 120,
                                                            Cell: row => {
                                                                return UserCell(row.value, row.original.referrerId)
                                                            }
                                                        },
                                                        {
                                                            Header: "Invited User",
                                                            accessor: "invitedUser",
                                                            width: 120,
                                                            Cell: row => {
                                                                return UserCell(row.value, row.original.invitedUserId)
                                                            }
                                                        },
                                                        {
                                                            Header: "Invited User Transaction Count",
                                                            accessor: "invitedUserTransactionCount",
                                                            width: 140
                                                        },
                                                        {
                                                            Header: "Status",
                                                            accessor: "status",
                                                            width: 100,
                                                            Cell: row => {
                                                                const status = row.value
                                                                return (
                                                                    <Label color={colorForStatus(status)} size={'mini'}>{status}</Label>
                                                                )
                                                            },
                                                        },
                                                        {
                                                            Header: "Updated At",
                                                            accessor: "updatedAt",
                                                            width: 140
                                                        },
                                                        {
                                                            Header: "Last Error",
                                                            accessor: "error",
                                                            width: 200,
                                                            style: { 'whiteSpace': 'unset' }
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    defaultPageSize={20}
                                                    defaultSorted={[
                                                        {
                                                            id: "updatedAt",
                                                            desc: true
                                                        }
                                                    ]}
                                                    showPaginationTop
                                                    showPaginationBottom
                                                    defaultFilterMethod={filter}
                                                    className="-striped -highlight"
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                </div>

                <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                    <Dimmer active={this.state.isProcessing} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </div>
        );
    }
}

export default Referrals;
