import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import { purple900 } from 'material-ui/styles/colors';
import RaisedButton from 'material-ui/RaisedButton'
import { Dimmer, Loader, Label } from 'semantic-ui-react';
import { colorForStatus } from '../util/colors'
import { filter, UserCell, NumberCell, MobileMoneyProfile, WithdrawalCell } from '../util/helpers'
import ReactTable from "react-table";
import {
    Grid, Row, Col
} from 'react-bootstrap';
import Card from '../tim-components/Card.jsx';
import '../assets/css/bootstrap.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';
import '../index.css';
import MetaTags from 'react-meta-tags';

class Withdrawals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withdrawals: [],
            isProcessing: true,
        };
    }

    componentDidMount() {
        this.fetchAllWithdrawals();
    }

    fetchAllWithdrawals = () => {
        axios.get('/admin/support/withdrawals')
            .then(response => {
                console.log('response: ', response.data)
                const withdrawals = response.data.map(tx => {
                    let date;
                    if (moment(tx.updated_at).isValid()) {
                        date = moment(tx.updated_at).format('YYYY-MM-DD | HH:mm')
                    } else {
                        date = "-"
                    }
                    let error = tx.error_message ? tx.error_message : '-'

                    return {
                        id: tx.id,
                        user: tx.display_name,
                        mmCurrency: (tx.mobile_money_currency == null) ? tx.bank_currency : tx.mobile_money_currency,
                        originAmount: parseFloat(tx.origin_amount),
                        status: tx.status,
                        mmPhone: (tx.mobile_money_phone == null) ? tx.bank_account_number : tx.mobile_money_phone,
                        updatedAt: date,
                        mmCarrier: (tx.mobile_money_carrier == null) ? tx.bank_name : tx.mobile_money_carrier,
                        mmCountry: (tx.mobile_money_country == null) ? tx.bank_currency : tx.mobile_money_country,
                        accountType: tx.linked_account_type,
                        userId: tx.user_id,
                        error,
                        retry: (
                            <div>
                                {(tx.status === 'EXTERNAL_FAILED' || tx.status === 'PAYMENT_SUBMITTED') && <RaisedButton
                                    label="Retry"
                                    onTouchTap={() => this.retryWithdrawal(tx)}
                                />}
                            </div>
                        ),
                        refund: (
                            <div>
                                {(tx.status === 'EXTERNAL_FAILED' || tx.status === 'PAYMENT_SUBMITTED') && <RaisedButton
                                    label="Refund"
                                    onTouchTap={() => this.refundWithdrawal(tx)}
                                />}
                            </div>
                        )
                    }
                });

                this.setState({
                    withdrawals,
                    isProcessing: false
                });
            })
            .catch(error => {
                this.setState({
                    isProcessing: false
                });
            });
    }

    retryWithdrawal = (tx) => {
        const message = `You sure you want to retry this withdrawal to ${tx.display_name} of ${tx.mobile_money_currency} ${tx.origin_amount}?`
        const response = window.confirm(message)
        if (response) {
            this.setState({
                isProcessing: true
            })
            const url = `/admin/support/withdrawals/retry/${tx.id}`
            axios.post(url)
                .then(response => {
                    alert("This transaction was successfully retried")
                    this.fetchAllWithdrawals()
                })
                .catch(error => {
                    this.setState({
                        isProcessing: false
                    })
                    alert(error)
                })
        }
    }

    refundWithdrawal = (tx) => {
        const message = `You sure you want to refund this withdrawal from ${tx.display_name} of ${tx.mobile_money_currency} ${tx.origin_amount}? - The money will be sent back to ${tx.display_name}`
        const response = window.confirm(message)
        if (response) {
            this.setState({
                isProcessing: true
            })
            const url = `/admin/support/withdrawals/refund/${tx.id}`
            axios.post(url)
                .then(response => {
                    alert("This transaction was successfully refunded")
                    this.fetchAllWithdrawals()
                })
                .catch(error => {
                    this.setState({
                        isProcessing: false
                    })
                    alert(error)
                })
        }
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        const { withdrawals } = this.state

        return (
            <div>
                <MetaTags>
                    <title>Chipper Cash | Withdrawals</title>
                    <meta property="og:title" content="Chipper Cash | Withdrawals" />
                </MetaTags>
                <h2 style={{ textAlign: 'center' }}> All Withdrawals ({withdrawals.length}) </h2>

                <div>
                    {this.state.isProcessing && this.state.withdrawals.length === 0 ? <LoadingIndicator /> :
                        <div className="main-content">
                            <Grid fluid>
                                <Row>
                                    <Col md={12}>
                                        <Card
                                            content={
                                                <ReactTable
                                                    data={withdrawals}
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: "Withdrawal ID",
                                                            accessor: "id",
                                                            width: 60,
                                                            Cell: row => {
                                                                return WithdrawalCell(row.original.id)
                                                            }
                                                        },
                                                        {
                                                            Header: "User",
                                                            accessor: "user",
                                                            width: 160,
                                                            Cell: row => {
                                                                return UserCell(row.value, row.original.userId)
                                                            }
                                                        },
                                                        {
                                                            Header: "Currency",
                                                            accessor: "mmCurrency",
                                                            width: 60,
                                                        },
                                                        {
                                                            Header: "Amount",
                                                            accessor: "originAmount",
                                                            Cell: row => {
                                                                return NumberCell(row.value)
                                                            }
                                                        },
                                                        {
                                                            Header: "Status",
                                                            accessor: "status",
                                                            width: 155,
                                                            Cell: row => {
                                                                const status = row.value
                                                                return (
                                                                    <Label color={colorForStatus(status)} size={'mini'}>{status}</Label>
                                                                )
                                                            },
                                                        },
                                                        {
                                                            Header: "Account Number",
                                                            accessor: "mmPhone",
                                                            width: 130,
                                                            Cell: row => {
                                                                if (row.value.substring(0, 1) == "+") {
                                                                    return MobileMoneyProfile(row.value)
                                                                } else {
                                                                    return row.value
                                                                }
                                                            }
                                                        },
                                                        {
                                                            Header: "Updated At",
                                                            accessor: "updatedAt",
                                                            width: 160,
                                                        },
                                                        {
                                                            Header: "Carrier/Bank",
                                                            accessor: "mmCarrier",
                                                        },
                                                        {
                                                            Header: "Country",
                                                            accessor: "mmCountry"
                                                        },
                                                        {
                                                            Header: "Account type",
                                                            accessor: "accountType"
                                                        },
                                                        {
                                                            Header: "Error",
                                                            accessor: "error",
                                                            style: { 'whiteSpace': 'unset' },
                                                            width: 160,
                                                        },
                                                        {
                                                            Header: "Retry",
                                                            accessor: "retry",
                                                            filterable: false,
                                                        },
                                                        {
                                                            Header: "Refund",
                                                            accessor: "refund",
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    defaultPageSize={20}
                                                    defaultSorted={[
                                                        {
                                                            id: "updatedAt",
                                                            desc: true
                                                        }
                                                    ]}
                                                    showPaginationTop
                                                    showPaginationBottom
                                                    defaultFilterMethod={filter}
                                                    className="-striped -highlight"
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                </div>

                <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                    <Dimmer active={this.state.isProcessing} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </div>
        );
    }
}

export default Withdrawals;
