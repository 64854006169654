import React, { Component } from 'react';
import axios from 'axios';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import { purple900 } from 'material-ui/styles/colors';
import ChipInput from 'material-ui-chip-input'
import AutoComplete from 'material-ui/AutoComplete';
import TextField from 'material-ui/TextField';
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import Avatar from 'material-ui/Avatar';
import CircularProgress from 'material-ui/CircularProgress';
import RaisedButton from 'material-ui/RaisedButton';
import Dialog from 'material-ui/Dialog';
import { Statistic } from 'semantic-ui-react'
import { Divider } from 'semantic-ui-react'
import formattedCurrency from '../util/currencyFormatter'


const currencies = [
    <MenuItem key={1} value={'GHS'} primaryText="🇬🇭 Ghana Cedi" />,
    <MenuItem key={2} value={'UGX'} primaryText="🇺🇬 Uganda Shillings" />,
    <MenuItem key={3} value={'KES'} primaryText="🇰🇪 Kenyan Shillings" />
];

const buttonStyle = {
    margin: 12
};

const avatarStyle = {
    objectFit: 'cover'
};

const fieldStyle = {
    hintStyle: {
        color: purple900
    },
    underlineStyle: {
        borderColor: purple900
    },
    floatingLabelFocusStyle: {
        color: purple900
    },
};

class SendMoney extends Component {

    constructor(props) {
        super(props);

        this.state = {
            input: '',
            selectedPhones: [],

            amount: '',
            currency: '',
            note: '',
            isProcessing: false,

            open: false,

            dialogTitle: '',
            dialogDetail: '',
            txComplete: false,

            recipientId: '',
            confirmTitle: '',
            confirmOpen: false,
            confirmAction: '',

            fetchingSuggestions: true,
            suggestions: [],
            filteredSuggestions: [],
            users: []
        };
    }

    handleError = (error, title) => {
        let message
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
            message = error.response.data.error.message
        } else if (error.message) {
            message = error.message
        }

        this.setState({
            dialogTitle: title,
            dialogDetail: `Reason: ${message}`,
            txComplete: false,
            open: true,
            isProcessing: false
        });
    }

    componentDidMount() {
        this.handleInputChange("");

        axios.get('/users')
            .then(response => {
                console.log("Users: ", response.data)
                const users = response.data.users
                this.setState({
                    users,
                    fetchingSuggestions: false // What does this do exactly
                });
            })
            .catch(error => {
                console.error("Getting users error: ", error)
                this.setState({
                    fetchingSuggestions: false
                });
            })
    }

    getFlag(value) {
        if (value === 'UGX') {
            return '🇺🇬'
        } else if (value === 'GHS') {
            return '🇬🇭'
        } else if (value === 'KES') {
            return '🇰🇪'
        }
    }

    handleAddChip(chip) {
        if (chip.identifier) {
            if (chip.identifier === '#loading') {
                return;
            }
        } else {
            chip.identifier = chip.name;
        }

        this.setState({
            selectedPhones: [chip],
            input: chip.identifier
        });

        console.log('Selected: ', chip)
    }

    handleDeleteChip(chip, index) {
        console.log("Chip: ", chip, index);

        this.setState({
            selectedPhones: [],
            input: ""
        });
    }

    handleCurrencyChange = (event, index, currency) => {
        this.setState({
            currency
        })
    }

    handleAmountChange = (event) => {
        this.setState({
            amount: event.target.value,
        });
    };

    handleNoteChange = (event) => {
        this.setState({
            note: event.target.value,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    }

    handleInputChange = (input) => {
        let filtered = [];

        if (input.length < 1) {
            // Do nothing
        } else {
            // Search all users
            filtered = this.state.users.filter(user => {
                if (user.display_name.toLowerCase().indexOf(input.toLowerCase()) !== -1) return true
                if (user.identifier.toLowerCase().indexOf(input.toLowerCase()) !== -1) return true
                return false
            })
        }

        // Create the MenuItems to be shown in the datasource
        const filteredSuggestions = filtered.map(suggestion => {
            let displayIdentifier = suggestion.identifier

            const formattedPhone = formatPhoneNumber(parsePhoneNumber(suggestion.identifier), 'International');
            if (formattedPhone) {
                displayIdentifier = formattedPhone
            }

            return {
                identifier: suggestion.user_id,
                name: `${suggestion.display_name} ${this.getFlag(suggestion.primary_currency)}`,
                value: (
                    <MenuItem
                        style={{ height: 70 }}
                        leftIcon={
                            <Avatar
                                src={suggestion.avatar}
                                size={200}
                                style={avatarStyle}
                            />}>
                        <div>
                            {suggestion.display_name}
                            <p style={{ color: 'gray', marginTop: -10, fontSize: "0.8em" }}>{`${this.getFlag(suggestion.primary_currency)} ${displayIdentifier}`}</p>
                        </div>
                    </MenuItem>
                )
            }
        })

        const loadingSuggestion = {
            identifier: "#loading",
            name: "#loading",
            value: (
                <MenuItem
                    style={{ height: 70 }}>
                    <div>
                        <CircularProgress
                            style={{ marginLeft: 90, marginTop: 15 }}
                            size={40}
                            thickness={1.5}
                            color={purple900} />
                    </div>
                </MenuItem>
            )
        }

        const noUser = {
            identifier: "#loading",
            name: "#loading",
            value: (
                <MenuItem
                    style={{ height: 30 }}>
                    <div style={{ marginLeft: 67, marginTop: 25 }}>
                        <h4>No User Found</h4>
                    </div>
                </MenuItem>
            )
        }

        if (input.length > 0 && filteredSuggestions.length === 0) {
            filteredSuggestions.push(noUser);
        }

        this.setState({
            filteredSuggestions,
            input
        });
    }

    goBack = () => {
        // Go back to the Activity List.
        if (this.state.txComplete) {
            this.props.history.push('/admin');
        }
        this.handleClose();
    }

    send = () => {

        this.setState({
            isProcessing: true,
        });

        const amount = this.state.amount
        const currency = this.state.currency
        const selected = this.state.selectedPhones[0];

        if (!selected) {
            window.notifications.addNotification({
                level: 'error',
                title: 'Cannot complete transaction!',
                message: 'Please pick a recipient',
                position: 'bl'
            });

            this.setState({
                isProcessing: false,
            });

            return;
        }

        const note = this.state.note

        console.log('Send has been tapped!', amount, currency, note, selected.identifier)

        // Go call payFromBase
        axios.post('/admin/super/payFromBase', {
            userId: selected.identifier,
            amount,
            note,
        })
            .then(response => {
                console.log('Response: ', response)

                this.setState({
                    dialogTitle: "Transaction completed!",
                    dialogDetail: null,
                    txComplete: true,
                    open: true,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.log('Error: ', error)
                this.handleError(error, 'Transaction Failed')
            })
    }

    render() {
        const actions = [
            <FlatButton label="OK"
                style={{ color: purple900 }}
                onTouchTap={this.goBack}
            />
        ];

        const LoadingIndicator = () => (
            <div>
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        return (
            <div style={{ textAlign: 'center' }}>
                <h2 style={{ textAlign: 'center' }}>Admin Send Money</h2>

                <ChipInput
                    value={this.state.selectedPhones}
                    style={{ textAlign: 'left' }}
                    floatingLabelText="Recipient (Eg. Chessa)"
                    hintText="Enter Name/Phone"
                    openOnFocus={true}
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    onRequestAdd={(chip) => this.handleAddChip(chip)}
                    onRequestDelete={(chip, index) => this.handleDeleteChip(chip)}
                    onBlur={this.handleChipBlur}
                    filter={AutoComplete.noFilter}
                    dataSource={this.state.filteredSuggestions}
                    dataSourceConfig={{ text: 'name', value: 'value' }}
                    onUpdateInput={this.handleInputChange}
                    ref={(c) => this.recipientInput = c}
                />
                <br />

                <TextField
                    hintText="Amount to pay"
                    inputmode="numeric"
                    type="number"
                    floatingLabelText={`Amount`}
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.amount}
                    onChange={this.handleAmountChange}
                    ref={(c) => this.amountInput = c} />
                <p>The currency of the amount sent is always the same as the primary currency of the user</p>
                <br />

                <TextField
                    style={{ textAlign: 'left' }}
                    hintText="Note"
                    floatingLabelText="Note"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.note}
                    onChange={this.handleNoteChange}
                    multiLine={true}
                    ref={(c) => this.noteInput = c}
                />

                <br />

                {this.state.isProcessing ? <LoadingIndicator /> : ""}

                <RaisedButton
                    label="Send"
                    style={buttonStyle}
                    labelColor={purple900}
                    onTouchTap={this.send}
                    disabled={this.state.isProcessing} />

                <Dialog
                    title={this.state.dialogTitle}
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}>

                    <p>
                        {this.state.dialogDetail}
                    </p>

                </Dialog>

            </div>
        )
    }
}

export default SendMoney;
