import React, { Component } from 'react';
import axios from 'axios';
import Card from '../tim-components/Card.jsx';
import Toggle from 'material-ui/Toggle';
import { Switch, notification } from 'antd';

export default class UserPreferences extends Component {

    state = {
        push_notification_enabled: false,
        sms_notification_enabled: false,
    }

    componentDidMount() {
        this.fetchUserPreferences();
    }

    fetchUserPreferences = () => {
        axios.get(`/admin/support/user_preferences/${this.props.user.id}`)
            .then(response => {
                let preferences = response.data;
                this.setState({
                    push_notification_enabled: preferences.push_notification_enabled,
                    sms_notification_enabled: preferences.sms_notification_enabled,
                })
            })
            .catch(error => {
                console.error("Error fetching user preferences: ", error);
            });
    }

    togglePushNotifications = () => {
        axios.put(`/admin/support/user_preferences/${this.props.user.id}`, {
            pushNotificationEnabled: !this.state.push_notification_enabled,
            smsNotificationEnabled: this.state.sms_notification_enabled,
        })
            .then(response => {
                console.log("push toggled", response);
                this.fetchUserPreferences();

                const description = !this.state.push_notification_enabled ? `${this.props.user.display_name}'s push notifications are enabled` : `${this.props.user.display_name}'s push notifications are disabled`
                notification.success({
                    message: 'Success',
                    description,
                });
            })
            .catch(error => {
                console.log("error", error)
            })
    }

    toggleSMSNotifications = () => {
        axios.put(`/admin/support/user_preferences/${this.props.user.id}`, {
            pushNotificationEnabled: this.state.push_notification_enabled,
            smsNotificationEnabled: !this.state.sms_notification_enabled,
        })
            .then(response => {
                console.log("push toggled", response);
                this.fetchUserPreferences();

                const description = !this.state.sms_notification_enabled ? `${this.props.user.display_name}'s SMS notifications are enabled` : `${this.props.user.display_name}'s sms notifications are disabled`
                notification.success({
                    message: 'Success',
                    description,
                });
            })
            .catch(error => {
                console.log("error", error)
            })
    }

    render() {

        let preferences = this.state;
        const push_notification = preferences.push_notification_enabled
        const sms_notification = preferences.sms_notification_enabled

        return (
            <Card
                title={'User Preferences'}
                content={
                    <div>
                        <div>
                            <span>Push Notifications:
                                <span> {push_notification ? <span className='preferenceOn'>On</span> : <span className='preferenceOff'>Off</span>}
                                    <br /><Switch checked={push_notification} onChange={() => this.togglePushNotifications()} />
                                </span>
                            </span>
                        </div>
                        <div>
                            <span>SMS Notifications:
                                <span> {sms_notification ? <span className='preferenceOn'>On</span> : <span className='preferenceOff'>Off</span>}
                                    <br /><Switch checked={sms_notification} onChange={() => this.toggleSMSNotifications()} />
                                </span>
                            </span>
                        </div>
                    </div>
                }
            />
        )
    }
}