import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import { purple900 } from 'material-ui/styles/colors';
import { Dimmer, Loader, Label } from 'semantic-ui-react';
import { colorForStatus } from '../util/colors'
import { filter, UserCell, NumberCell } from '../util/helpers'
import {
    Grid, Row, Col
} from 'react-bootstrap';
import ReactTable from "react-table";
import Card from '../tim-components/Card.jsx';
import '../assets/css/bootstrap.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';

class Requests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            isProcessing: true
        };
    }

    componentDidMount() {
        this.fetchAllRequests();
    }

    fetchAllRequests = () => {
        axios.get('/admin/support/requests')
            .then(response => {
                const requests = response.data.map(tx => {
                    console.log("Req: ", tx);
                    let date;
                    if (moment(tx.updated_at).isValid()) {
                        date = moment(tx.updated_at).format('YYYY-MM-DD | HH:mm')
                    } else {
                        date = "-"
                    }
                    let sender = `${tx.sender_first_name} ${tx.sender_last_name}`;
                    let recipient = `${tx.recipient_first_name} ${tx.recipient_last_name}`
                    return {
                        sender,
                        recipient,
                        destinationAmount: parseFloat(tx.destination_amount),
                        destinationCurrency: tx.destination_currency,
                        originAmount: parseFloat(tx.origin_amount),
                        originCurrency: tx.origin_currency,
                        status: tx.status,
                        updatedAt: date,
                        id: tx.id,
                        note: tx.note,
                        senderId: tx.sender_id,
                        recipientId: tx.recipient_id
                    }
                });

                this.setState({
                    requests,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.error("Error: ", error);
                this.setState({
                    isProcessing: false
                });
            });
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        const { requests } = this.state

        return (
            <div>
                <h2 style={{ textAlign: 'center' }}> All Requests ({this.state.requests.length}) </h2>
                <br />
                <div>
                    {this.state.isProcessing && this.state.requests.length === 0 ? <LoadingIndicator /> :

                        <div className="main-content">
                            <Grid fluid>
                                <Row>
                                    <Col md={12}>

                                        <Card
                                            content={

                                                <ReactTable
                                                    data={requests}
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: "Sender",
                                                            accessor: "sender",
                                                            Cell: row => {
                                                                return UserCell(row.value, row.original.senderId)
                                                            }
                                                        },
                                                        {
                                                            Header: "Recipient",
                                                            accessor: "recipient",
                                                            Cell: row => {
                                                                return UserCell(row.value, row.original.recipientId)
                                                            }
                                                        },
                                                        {
                                                            Header: "Origin Amount",
                                                            accessor: "originAmount",
                                                            Cell: row => {
                                                                return NumberCell(row.value)
                                                            }
                                                        },
                                                        {
                                                            Header: "Origin Currency",
                                                            accessor: "originCurrency",
                                                        },
                                                        {
                                                            Header: "Destination Amount",
                                                            accessor: "destinationAmount",
                                                            Cell: row => {
                                                                return NumberCell(row.value)
                                                            }
                                                        },
                                                        {
                                                            Header: "Destination Currency",
                                                            accessor: "destinationCurrency"
                                                        },
                                                        {
                                                            Header: "Status",
                                                            accessor: "status",
                                                            Cell: row => {
                                                                const status = row.value
                                                                return (
                                                                    <Label color={colorForStatus(status)} size={'mini'}>{status}</Label>
                                                                )
                                                            },
                                                        },
                                                        {
                                                            Header: "Completed At",
                                                            accessor: "updatedAt",
                                                            width: 130
                                                        },
                                                        {
                                                            Header: "Payment Id",
                                                            accessor: "id"
                                                        },
                                                        {
                                                            Header: "Note",
                                                            accessor: "note",
                                                            style: { 'whiteSpace': 'unset' }
                                                        },
                                                        {
                                                            Header: "Error",
                                                            accessor: "error"
                                                        },
                                                    ]}
                                                    defaultPageSize={20}
                                                    defaultSorted={[
                                                        {
                                                            id: "updatedAt",
                                                            desc: true
                                                        }
                                                    ]}
                                                    showPaginationTop
                                                    showPaginationBottom
                                                    defaultFilterMethod={filter}
                                                    className="-striped -highlight"
                                                />

                                            }
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>

                    }
                </div>

                <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                    <Dimmer active={this.state.isProcessing} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>

            </div>
        );
    }
}

export default Requests;
