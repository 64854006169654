import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import { purple900 } from 'material-ui/styles/colors';
import { Dimmer, Loader } from 'semantic-ui-react';
import { filter, UserCell, NumberCell, UserAmplitudeCell, CheckoutCell } from '../util/helpers'
import {
    Grid, Row, Col
} from 'react-bootstrap';
import ReactTable from "react-table";
import Card from '../tim-components/Card.jsx';
import '../assets/css/bootstrap.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';
import MetaTags from 'react-meta-tags';
import api from '../util/api';
import { Button } from 'antd/lib/radio';
import { Input } from 'antd';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            searchKey: "",
            startingState: false,
            isProcessing: false
        }
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = () => {
        axios.get('/admin/support/users')
            .then(response => {
                const users = response.data.map(user => {
                    let presence = "-";
                    if (user.last_presence) {
                        presence = moment(user.last_presence).format('YYYY-MM-DD | HH:mm')
                    }

                    return {
                        name: `${user.first_name} ${user.last_name}`,
                        tag: user.tag,
                        currency: user.primary_currency,
                        balance: parseFloat(user.balance),
                        joined: moment(user.created_at).format('YYYY-MM-DD | HH:mm'),
                        lastPresence: presence,
                        numTransactions: parseFloat(user.num_transactions),
                        id: user.user_id
                    }
                });

                this.setState({
                    users,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.error("Error: ", error);
                this.setState({
                    isProcessing: false
                });
            });
    }

    searchUsers = async (term) => {
        this.setState({
            isProcessing: true
        });

        // console.log(term)
        var searchTerms = await api.routes.users.search(term)

        const users = searchTerms.data.results.map(user => {
            return {
                avatar: user.avatar,
                name: `${user.first_name} ${user.last_name}`,
                tag: user.tag,
                currency: user.primary_currency,
                id: user.id
            }
        });

        this.setState({
            users,
            isProcessing: false,
            startingState: true,
        });

    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        const { users } = this.state

        return (
            <div>
                <MetaTags>
                    <title>Chipper Cash | Users</title>
                    <meta property="og:title" content="Chipper Cash | Withdrawals" />
                </MetaTags>
                {
                    (this.state.users.length === 0) ?
                        <h2 style={{ textAlign: 'center' }}>All Users</h2>
                        :
                        <h2 style={{ textAlign: 'center' }}>{this.state.users.length} Users Found</h2>
                }
                <center>
                    <Input
                        style={{
                            width: '70%'
                        }}
                        placeholder="Search for a user by a first name, last name or tag"
                        type="input"
                        onChange={(event) => this.setState({ searchKey: event.target.value })}
                    /><br />
                    <Button style={{ marginTop: "15px", marginBottom: "15px" }} onClick={() => this.searchUsers(this.state.searchKey)}>Begin Search</Button>
                </center>
                {
                    this.state.startingState ?
                        this.state.isProcessing ? <LoadingIndicator /> :
                            <div className="main-content">
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            <Card
                                                content={
                                                    <ReactTable
                                                        data={users}
                                                        filterable
                                                        columns={[
                                                            {
                                                                Header: "Name",
                                                                accessor: "name",
                                                                Cell: row => {
                                                                    const id = row.row.id
                                                                    return UserCell(row.value, id)
                                                                }
                                                            },
                                                            {
                                                                Header: "Tag",
                                                                accessor: "tag",
                                                                Cell: row => {
                                                                    return CheckoutCell(row.value)
                                                                }
                                                            },
                                                            {
                                                                Header: "Primary Currency",
                                                                accessor: "currency"
                                                            },
                                                            {
                                                                Header: "Amplitude",
                                                                accessor: "id",
                                                                Cell: row => {
                                                                    const id = row.row.id
                                                                    return UserAmplitudeCell(id)
                                                                }
                                                            },
                                                        ]}
                                                        defaultPageSize={20}
                                                        defaultSorted={[
                                                            {
                                                                // id: "joined",
                                                                // desc: true
                                                            }
                                                        ]}
                                                        showPaginationTop
                                                        showPaginationBottom
                                                        defaultFilterMethod={filter}
                                                        className="-striped -highlight"
                                                    />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </div> : <span></span>
                }
                <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                    <Dimmer active={this.state.isProcessing} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </div >
        );
    }
}

export default Users;
