import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import { purple900 } from 'material-ui/styles/colors';
import { Card, CardActions, CardHeader, CardTitle } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import Avatar from 'material-ui/Avatar';
import LinearProgress from 'material-ui/LinearProgress';
import currencyFormatter from 'currencyformatter.js';

const avatarStyle = { objectFit: 'cover' }

function formattedCurrency(amount, currency) {
    const num = parseFloat(amount);
    return currencyFormatter.format(num, { currency });
}

class WithdrawalRequestCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false
        };
    }

    handleMarkCompleted() {
        console.log("Mark completed called...: ", this.props);

        this.setState({
            isProcessing: true
        });

        // Mark Complete.
        axios.put(`/admin/support/withdrawals/${this.props.id}`, {
            action: "approve"
        })
            .then(response => {
                console.log("Response from approval: ", response);
                this.props.onMarkWithdrawSuccess();
            })
            .catch(error => {
                console.log("Error: ", error);
                this.state = {
                    isProcessing: false
                };
                this.props.onMarkWithdrawError(error);
            });
    }



    render() {

        const Progress = () => (
            <LinearProgress
                mode="indeterminate"
                color={purple900}>
            </LinearProgress>
        );

        const flag = () => {
            console.log("Flat: ", this.props.country)
            if (this.props.country === 'GH') {
                return "🇬🇭";
            } else if (this.props.country === 'UG') {
                return "🇺🇬";
            } else if (this.props.country === 'KE') {
                return "🇰🇪"
            }
        }

        return (
            <Card style={{ margin: 10 }}>
                <CardHeader
                    title={`Withdrawal Request from ${this.props.displayName}`}
                    subtitle={this.props.timestamp}
                    avatar={<Avatar
                        src={this.props.avatar}
                        style={avatarStyle}
                    />}
                />
                <CardTitle title={`Withdrawing: ${formattedCurrency(this.props.destinationAmount, this.props.destinationCurrency)} to Mobile Money: ${this.props.phone} on carrier: ${this.props.carrier} ${flag()}`} />
                <CardActions style={{ width: '100%', textAlign: 'right' }}>
                    <FlatButton style={{ color: 'green' }} label="Mark as complete" onTouchTap={this.handleMarkCompleted.bind(this)} />
                </CardActions>
                {this.state.isProcessing ? <Progress /> : ""}
            </Card>
        )
    }
}

class PendingWithdrawals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            withdrawals: [],
            isProcessing: true
        };
    }

    componentDidMount() {
        this.fetchWithdrawals();
    }

    fetchWithdrawals = () => {
        axios.get('/admin/support/withdrawals/pending')
            .then(response => {
                const _withdrawals = response.data;
                console.log("Withdrawal response: ", _withdrawals);

                const withdrawals = _withdrawals.map(w => {
                    return {
                        id: w.id,
                        displayName: w.display_name,
                        avatar: w.avatar,
                        originAmount: w.origin_amount,
                        destinationAmount: w.destination_amount,
                        originCurrency: w.origin_currency,
                        destinationCurrency: w.destination_currency,
                        country: w.mobile_money_country,
                        phone: w.mobile_money_phone,
                        timestamp: moment(w.updated_at).format('MMMM Do YYYY, h:mma'),
                        carrier: w.mobile_money_carrier || '[Unspecified]'
                    }
                });

                this.setState({
                    withdrawals,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.error("Error: ", error);
                this.setState({
                    isProcessing: false
                });
            });
    }

    handleMarkWithdrawSuccess() {
        this.fetchWithdrawals();
    }

    handleMarkWithdrawError(error) {
        console.error("Unable to complete withdrawal request: ", error);
    }

    render() {

        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        const withdrawalItems = this.state.withdrawals.map(w => {
            return (
                <WithdrawalRequestCard
                    key={w.id}
                    id={w.id}
                    displayName={w.displayName}
                    avatar={w.avatar}

                    originAmount={w.originAmount}
                    destinationAmount={w.destinationAmount}
                    originCurrency={w.originCurrency}
                    destinationCurrency={w.destinationCurrency}

                    carrier={w.carrier}
                    phone={w.phone}
                    country={w.country}

                    timestamp={w.timestamp}

                    onMarkWithdrawSuccess={e => this.handleMarkWithdrawSuccess()}
                    onMarkWithdrawError={e => this.handleMarkWithdrawError(e)} />
            );
        });

        const EmptyStateView = () => (
            <h3 style={{ textAlign: 'center' }}>No pending withdrawals need your attention</h3>
        );

        let rendered = null;
        if (withdrawalItems.length > 0) {
            rendered = withdrawalItems;
        } else {
            rendered = <EmptyStateView />
        }

        return (
            <div className="container">
                <h2 style={{ textAlign: 'center' }}> All Pending Withdrawal Requests ({this.state.withdrawals.length}) </h2>
                {this.state.isProcessing ? <LoadingIndicator /> : rendered}
            </div>
        );
    }
}

export default PendingWithdrawals;
