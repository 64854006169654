import React, { Component } from "react";
import Card from "../tim-components/Card.jsx";
import Avatar from "material-ui/Avatar";
import ListItem from "material-ui/List/ListItem";
import { UserCell } from "../util/helpers";

export default class UserPreferences extends Component {
  render() {
    let referrer = this.props.referrer;
    return (
      <Card
        title={"Referred By"}
        content={
          <div>
            <div>
              {referrer ? (
                <ListItem
                  disabled={true}
                  leftAvatar={<Avatar src={referrer.avatar} />}
                >
                  <span>
                    {UserCell(referrer.display_name, referrer.id)} <br />
                    {referrer.tag}
                  </span>
                </ListItem>
              ) : (
                <p style={{ textAlign: "center", color: "grey" }}>
                  Not a referred user
                </p>
              )}
            </div>
          </div>
        }
      />
    );
  }
}
