
import React, { Component } from 'react';
import { Input, Button } from 'antd'
import Card from '../tim-components/Card.jsx';

export default class ReferralManualAdd extends Component {
    state = {
        referrerId: '',
        invitedUserId: ''
    }

    handleReferrerIdChange = (event) => {
        this.setState({
            referrerId: event.target.value,
        });
    };

    handleInvitedUserIdChange = (event) => {
        this.setState({
            invitedUserId: event.target.value,
        });
    };

    handleAction = (event) => {
        const { onAction } = this.props
        const { referrerId, invitedUserId } = this.state

        onAction(referrerId, invitedUserId)
    }

    render() {
        const { referrerId, invitedUserId } = this.props

        return (
            <Card
            title={'Manually add a referral by entering both the referrers userId and invited userId'}
            content={
                <div>
                    <Input
                    style={{width: '100%', margin: 5}}
                    placeholder='Referrer Id'
                    size={'default'}
                    defaultValue={referrerId}
                    onChange={this.handleReferrerIdChange} />

                    <Input
                    style={{width: '100%', margin: 5}}
                    placeholder='Invited User Id'
                    size={'default'}
                    defaultValue={invitedUserId}
                    onChange={this.handleInvitedUserIdChange}
                    />

                    <br />
                    <Button style={{ margin: 5 }} type={'primary'} onClick={this.handleAction}>{`Add Referral`}</Button>
                </div>
                }
            />
        )
    }
}

