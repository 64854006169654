import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import IconButton from 'material-ui/IconButton';
import ActivityList from '../ActivityList'
import { Dimmer, Loader, Label, Table } from 'semantic-ui-react';
import { colorForStatus, NumberFormat } from '../util/colors'
import { UserCell } from '../util/helpers'
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import {
    Grid,
    Row,
    Col
} from "react-bootstrap";
import Card from '../tim-components/Card.jsx';
import UserCard from '../tim-components/UserCard';
import formattedCurrency from '../util/currencyFormatter'
import MoveMoney from './MoveMoney'
import { List, notification, Button } from 'antd'
import ToggleUserLock from './ToggleUserLock'
import SendNotification from './SendNotification'
import ResetPin from './ResetPin'
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import UserVolumes from './UserVolumes';
import UserPreferences from './UserPreferences';
import '../index.css';
// Because it shows beneath the top black bar and needs to offset
notification.config({
    top: 100
})

export default class DepositPage extends Component {
    state = {
        deposit: [],
        beyonic_id: null,
        beyonic_status: null,
        isFetchingVolume: true,
    }

    componentDidMount() {
        this.fetchDeposit()
    }

    fetchDeposit = () => {
        const { id } = this.props.match.params
        console.log("Deposit: " + id)
        axios.get(`/admin/support/deposits/${id}`)
            .then(response => {
                console.log(response.data)
                this.setState({
                    deposit: response.data,
                    beyonic_id: response.data.details.beyonic_id,
                    beyonic_status: response.data.details.status,
                    isFetchingVolume: false,
                })
            })
            .catch(error => {
                console.error("Error fetching user preferences: ", error);
            });
    }

    completeDeposit = (tx) => {
        const message = `You sure you want to manually complete this deposit from ${tx.display_name} of ${tx.mobile_money_currency} ${tx.origin_amount}? Funds will be transferred into the users Chipper account`
        const response = window.confirm(message)
        if (response) {
            const url = `/admin/super/deposits/complete/${tx.id}`
            axios.post(url)
                .then(response => {
                    alert("This deposit was completed successfully")
                    this.setState({
                        isProcessing: true
                    })
                    this.fetchDeposit()
                })
                .catch(error => {
                    let message
                    if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
                        message = error.response.data.error.message
                    } else if (error.message) {
                        message = error.message
                    }

                    alert(message)
                })
        }
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color="black" />
            </div>
        );

        const deposit = this.state.deposit
        console.log(this.state.beyonic_id)
        console.log(this.state.beyonic_status)

        return (
            <div style={{ padding: '0px 10%' }}>
                {this.state.isFetchingVolume ?
                    <LoadingIndicator /> :
                    <div>
                        <Grid fluid>
                            <Row>
                                <h3>DEPOSIT ACCOUNT DETAILS</h3>
                                <div style={{ paddingBottom: '40px', paddingLeft: '50px' }}>
                                    <Col md={6}>
                                        <Avatar
                                            src={deposit.avatar}
                                            size={65}
                                            style={{ marginBottom: "25px" }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <br /><Label className="center" color={colorForStatus(`${deposit.status}`)} size={'medium'}>{deposit.status}</Label>
                                            {/* <span><b>Chipper Deposit ID:</b> {deposit.id} </span> */}
                                        </div>
                                    </Col>
                                </div>

                                <Col md={12}>
                                    <Table fixed basic>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Account ID</Table.HeaderCell>
                                                <Table.HeaderCell>Account Name</Table.HeaderCell>
                                                <Table.HeaderCell>Chipper Deposit ID</Table.HeaderCell>
                                                <Table.HeaderCell>Action</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>{deposit.user_id}</Table.Cell>
                                                <Table.Cell>{UserCell(deposit.display_name, deposit.user_id)}</Table.Cell>
                                                <Table.Cell>{deposit.id}</Table.Cell>
                                                <Table.Cell>
                                                    {(deposit.status !== 'SETTLED') && <RaisedButton
                                                        label="Complete"
                                                        onTouchTap={() => this.completeDeposit(deposit)}
                                                    />}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Col>
                            </Row>
                        </Grid>


                        <Grid fluid style={{ marginTop: '75px' }}>
                            <Row>
                                <h3 style={{ marginBottom: "25px" }}>DEPOSIT PAYMENT</h3>
                                <Col md={12}>
                                    <Table basic>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Provider</Table.HeaderCell>
                                                <Table.HeaderCell>Account Type</Table.HeaderCell>
                                                <Table.HeaderCell>Account Number</Table.HeaderCell>
                                                <Table.HeaderCell>Amount</Table.HeaderCell>
                                                <Table.HeaderCell>Updated At</Table.HeaderCell>
                                                <Table.HeaderCell>Provider Link</Table.HeaderCell>
                                                <Table.HeaderCell>Provider Status</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>BEYONIC</Table.Cell>
                                                <Table.Cell>{deposit.linked_account_type}</Table.Cell>
                                                <Table.Cell>{deposit.mobile_money_phone}</Table.Cell>
                                                <Table.Cell>{deposit.destination_currency} {deposit.destination_amount}</Table.Cell>
                                                <Table.Cell>{moment(deposit.updated_at).format('YYYY-MM-DD | HH:mm')}</Table.Cell>
                                                <Table.Cell><a style={{ color: '#5433BF' }} target="_blank" href={`https://app.beyonic.com/c2b/c2bpaymentrequest/read/${this.state.beyonic_id}/`}>{this.state.beyonic_id}</a></Table.Cell>
                                                {
                                                    this.state.beyonic_status ?
                                                        <Table.Cell>{(this.state.beyonic_status).toUpperCase()}</Table.Cell> :
                                                        <Table.Cell></Table.Cell>

                                                }
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                }
            </div>
        )
    }
}