import React, { Component } from "react";
import axios from "axios";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import { purple900 } from "material-ui/styles/colors";
import ChipInput from "material-ui-chip-input";
import AutoComplete from "material-ui/AutoComplete";
import TextField from "material-ui/TextField";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import Avatar from "material-ui/Avatar";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import Dialog from "material-ui/Dialog";
import { Statistic } from "semantic-ui-react";
import { Divider } from "semantic-ui-react";
import formattedCurrency from "../util/currencyFormatter";
import Snackbar from "material-ui/Snackbar";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

const fieldStyle = {
  hintStyle: {
    color: purple900
  },
  underlineStyle: {
    borderColor: purple900
  },
  floatingLabelFocusStyle: {
    color: purple900
  }
};

const buttonStyle = {
  margin: 12
};

class Base extends Component {
  constructor(props) {
    super(props);

    this.state = {
      issuerKES: "",
      baseKES: "",
      amountKES: "",

      issuerGHS: "",
      baseGHS: "",
      amountGHS: "",

      issuerUGX: "",
      baseUGX: "",
      amountUGX: "",

      issuerTZS: "",
      baseTZS: "",
      amountTZS: "",

      issuerRWF: "",
      baseRWF: "",
      amountRWF: "",

      issuerNGN: "",
      baseNGN: "",
      amountNGN: "",

      issuerZAR: "",
      baseZAR: "",
      amountZAR: "",

      snackOpen: false,
      snackMessage: ""
    };
  }

  componentDidMount() {
    this.refreshAccounts();
  }

  refreshAccounts = () => {
    this.setState({
      isProcessing: true,
      amountGHS: "",
      amountKES: "",
      amountUGX: "",
      amountTZS: "",
      amountRWF: "",
      amountNGN: "",
      amountZAR: ""
    });

    axios
      .get("/admin/support/accounts")
      .then(response => {
        console.log("Accounts: ", response.data);

        const accounts = response.data;
        let issuerGHS,
          baseGHS,
          issuerKES,
          baseKES,
          issuerUGX,
          baseUGX,
          issuerTZS,
          baseTZS,
          issuerRWF,
          baseRWF,
          issuerNGN,
          baseNGN,
          issuerZAR,
          baseZAR = 0;

        // Bad codododooeee hahaha. Send the police
        accounts.forEach(element => {
          if (element.user_id === "base-ghs") {
            baseGHS = element.balance;
          } else if (element.user_id === "issuer-ghs") {
            issuerGHS = element.balance;
          } else if (element.user_id === "base-kes") {
            baseKES = element.balance;
          } else if (element.user_id === "issuer-kes") {
            issuerKES = element.balance;
          } else if (element.user_id === "issuer-ugx") {
            issuerUGX = element.balance;
          } else if (element.user_id === "base-ugx") {
            baseUGX = element.balance;
          } else if (element.user_id === "issuer-tzs") {
            issuerTZS = element.balance;
          } else if (element.user_id === "base-tzs") {
            baseTZS = element.balance;
          } else if (element.user_id === "issuer-rwf") {
            issuerRWF = element.balance;
          } else if (element.user_id === "base-rwf") {
            baseRWF = element.balance;
          } else if (element.user_id === "issuer-ngn") {
            issuerNGN = element.balance;
          } else if (element.user_id === "base-ngn") {
            baseNGN = element.balance;
          } else if (element.user_id === "issuer-zar") {
            issuerZAR = element.balance;
          } else if (element.user_id === "base-zar") {
            baseZAR = element.balance;
          }
        });

        this.setState({
          issuerGHS,
          baseGHS,
          issuerKES,
          baseKES,
          issuerUGX,
          baseUGX,
          issuerTZS,
          baseTZS,
          issuerRWF,
          baseRWF,
          issuerNGN,
          baseNGN,
          issuerZAR,
          baseZAR,
          isProcessing: false
        });
      })
      .catch(error => {
        console.error("Boo Error: ", error);
        this.setState({
          isProcessing: false
        });
      });
  };

  addGHS = () => {
    this.setState({
      isProcessing: true
    });
    console.log(this.state.amountGHS);
    axios
      .post("/admin/super/circulation", {
        amount: this.state.amountGHS,
        currency: "GHS"
      })
      .then(response => {
        this.setState({
          snackOpen: true,
          snackMessage: `Added ${formattedCurrency(
            this.state.amountGHS,
            "GHS"
          )} to circulation`
        });
        this.refreshAccounts();
      })
      .catch(error => {
        console.log("WTF: err", error);
        this.handleError(error, "Failed to add GHS");
      });
  };

  addUGX = () => {
    this.setState({
      isProcessing: true
    });

    axios
      .post("/admin/super/circulation", {
        amount: this.state.amountUGX,
        currency: "UGX"
      })
      .then(response => {
        this.setState({
          snackOpen: true,
          snackMessage: `Added ${formattedCurrency(
            this.state.amountUGX,
            "UGX"
          )} to circulation`
        });
        this.refreshAccounts();
      })
      .catch(error => {
        console.log("WTF: err", error);
        this.handleError(error, "Failed to add UGX");
      });
  };

  addKES = () => {
    this.setState({
      isProcessing: true
    });

    axios
      .post("/admin/super/circulation", {
        amount: this.state.amountKES,
        currency: "KES"
      })
      .then(response => {
        this.setState({
          snackOpen: true,
          snackMessage: `Added ${formattedCurrency(
            this.state.amountKES,
            "KES"
          )} to circulation`
        });
        this.refreshAccounts();
      })
      .catch(error => {
        console.log("WTF: err", error);
        this.handleError(error, "Failed to add KES");
      });
  };

  addTZS = () => {
    this.setState({
      isProcessing: true
    });

    axios
      .post("/admin/super/circulation", {
        amount: this.state.amountTZS,
        currency: "TZS"
      })
      .then(response => {
        this.setState({
          snackOpen: true,
          snackMessage: `Added ${formattedCurrency(
            this.state.amountTZS,
            "TZS"
          )} to circulation`
        });
        this.refreshAccounts();
      })
      .catch(error => {
        console.log("WTF: err", error);
        this.handleError(error, "Failed to add TZS");
      });
  };

  addRWF = () => {
    this.setState({
      isProcessing: true
    });

    axios
      .post("/admin/super/circulation", {
        amount: this.state.amountRWF,
        currency: "RWF"
      })
      .then(response => {
        this.setState({
          snackOpen: true,
          snackMessage: `Added ${formattedCurrency(
            this.state.amountRWF,
            "RWF"
          )} to circulation`
        });
        this.refreshAccounts();
      })
      .catch(error => {
        console.log("WTF: err", error);
        this.handleError(error, "Failed to add RWF");
      });
  };

  addNGN = () => {
    this.setState({
      isProcessing: true
    });

    axios
      .post("/admin/super/circulation", {
        amount: this.state.amountNGN,
        currency: "NGN"
      })
      .then(response => {
        this.setState({
          snackOpen: true,
          snackMessage: `Added ${formattedCurrency(
            this.state.amountNGN,
            "NGN"
          )} to circulation`
        });
        this.refreshAccounts();
      })
      .catch(error => {
        console.log("WTF: err", error);
        this.handleError(error, "Failed to add NGN");
      });
  };

  addZAR = () => {
    this.setState({
      isProcessing: true
    });

    axios
      .post("/admin/super/circulation", {
        amount: this.state.amountZAR,
        currency: "ZAR"
      })
      .then(response => {
        this.setState({
          snackOpen: true,
          snackMessage: `Added ${formattedCurrency(
            this.state.amountZAR,
            "ZAR"
          )} to circulation`
        });
        this.refreshAccounts();
      })
      .catch(error => {
        console.log("WTF: err", error);
        this.handleError(error, "Failed to add ZAR");
      });
  };

  handleAmountGHSChange = event => {
    this.setState({
      amountGHS: event.target.value
    });
  };

  handleAmountUGXChange = event => {
    this.setState({
      amountUGX: event.target.value
    });
  };

  handleAmountKESChange = event => {
    this.setState({
      amountKES: event.target.value
    });
  };

  handleAmountTZSChange = event => {
    this.setState({
      amountTZS: event.target.value
    });
  };

  handleAmountRWFChange = event => {
    this.setState({
      amountRWF: event.target.value
    });
  };

  handleAmountNGNChange = event => {
    this.setState({
      amountNGN: event.target.value
    });
  };

  handleAmountZARChange = event => {
    this.setState({
      amountZAR: event.target.value
    });
  };

  handleSnackClose = () => {
    this.setState({
      snackOpen: false,
      snackMessage: ""
    });
  };

  handleError = error => {
    let message;
    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message
    ) {
      message = error.response.data.error.message;
    } else if (error.message) {
      message = error.message;
    }

    this.setState({
      snackOpen: true,
      snackMessage: message,
      isProcessing: false
    });
  };

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <br />

        <Statistic>
          <Statistic.Label>issuer-ghs</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.issuerGHS, "GHS")}
          </Statistic.Value>
          <Divider />
          <Statistic.Label>base-ghs</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.baseGHS, "GHS")}
          </Statistic.Value>
        </Statistic>

        <br />

        <TextField
          hintText="GHS Amount to add"
          inputMode="numeric"
          type="number"
          floatingLabelText={`Amount (GHS)`}
          floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
          underlineFocusStyle={fieldStyle.underlineStyle}
          value={this.state.amountGHS}
          onChange={this.handleAmountGHSChange}
          ref={c => (this.amountInput = c)}
        />

        <br />

        <RaisedButton
          label="Add more GHS to circulation"
          style={buttonStyle}
          labelColor={purple900}
          onTouchTap={this.addGHS}
          disabled={this.state.isProcessing}
        />

        <Divider />

        <Statistic>
          <Statistic.Label>issuer-ugx</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.issuerUGX, "UGX")}
          </Statistic.Value>
          <Divider />
          <Statistic.Label>base-ugx</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.baseUGX, "UGX")}
          </Statistic.Value>
        </Statistic>

        <br />

        <TextField
          hintText="UGX Amount to add"
          inputMode="numeric"
          type="number"
          floatingLabelText={`Amount (UGX)`}
          floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
          underlineFocusStyle={fieldStyle.underlineStyle}
          value={this.state.amountUGX}
          onChange={this.handleAmountUGXChange}
          ref={c => (this.amountInput = c)}
        />

        <br />

        <RaisedButton
          label="Add more UGX to circulation"
          style={buttonStyle}
          labelColor={purple900}
          onTouchTap={this.addUGX}
          disabled={this.state.isProcessing}
        />
        <Divider />

        <Statistic>
          <Statistic.Label>issuer-kes</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.issuerKES, "KES")}
          </Statistic.Value>
          <Divider />
          <Statistic.Label>base-kes</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.baseKES, "KES")}
          </Statistic.Value>

          <TextField
            hintText="KES Amount to add"
            inputMode="numeric"
            type="number"
            floatingLabelText={`Amount (KES)`}
            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
            underlineFocusStyle={fieldStyle.underlineStyle}
            value={this.state.amountKES}
            onChange={this.handleAmountKESChange}
            ref={c => (this.amountInput = c)}
          />

          <br />

          <RaisedButton
            label="Add more KES to circulation"
            style={buttonStyle}
            labelColor={purple900}
            onTouchTap={this.addKES}
            disabled={this.state.isProcessing}
          />
        </Statistic>

        <Divider />
        <Statistic>
          <Statistic.Label>issuer-tzs</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.issuerTZS, "TZS")}
          </Statistic.Value>
          <Divider />
          <Statistic.Label>base-tzs</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.baseTZS, "TZS")}
          </Statistic.Value>

          <TextField
            hintText="TZS Amount to add"
            inputMode="numeric"
            type="number"
            floatingLabelText={`Amount (TZS)`}
            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
            underlineFocusStyle={fieldStyle.underlineStyle}
            value={this.state.amountTZS}
            onChange={this.handleAmountTZSChange}
            ref={c => (this.amountInput = c)}
          />

          <br />

          <RaisedButton
            label="Add more TZS to circulation"
            style={buttonStyle}
            labelColor={purple900}
            onTouchTap={this.addTZS}
            disabled={this.state.isProcessing}
          />
        </Statistic>

        <Divider />

        <Statistic>
          <Statistic.Label>issuer-rwf</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.issuerRWF, "RWF")}
          </Statistic.Value>
          <Divider />
          <Statistic.Label>base-rwf</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.baseRWF, "RWF")}
          </Statistic.Value>

          <TextField
            hintText="RWF Amount to add"
            inputMode="numeric"
            type="number"
            floatingLabelText={`Amount (RWF)`}
            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
            underlineFocusStyle={fieldStyle.underlineStyle}
            value={this.state.amountRWF}
            onChange={this.handleAmountRWFChange}
            ref={c => (this.amountInput = c)}
          />

          <br />

          <RaisedButton
            label="Add more RWF to circulation"
            style={buttonStyle}
            labelColor={purple900}
            onTouchTap={this.addRWF}
            disabled={this.state.isProcessing}
          />
        </Statistic>

        <Divider />

        <Statistic>
          <Statistic.Label>issuer-ngn</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.issuerNGN, "NGN")}
          </Statistic.Value>
          <Divider />
          <Statistic.Label>base-ngn</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.baseNGN, "NGN")}
          </Statistic.Value>

          <TextField
            hintText="NGN Amount to add"
            inputMode="numeric"
            type="number"
            floatingLabelText={`Amount (NGN)`}
            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
            underlineFocusStyle={fieldStyle.underlineStyle}
            value={this.state.amountNGN}
            onChange={this.handleAmountNGNChange}
            ref={c => (this.amountInput = c)}
          />

          <br />

          <RaisedButton
            label="Add more NGN to circulation"
            style={buttonStyle}
            labelColor={purple900}
            onTouchTap={this.addNGN}
            disabled={this.state.isProcessing}
          />
        </Statistic>

        <Divider />

        <Statistic>
          <Statistic.Label>issuer-zar</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.issuerZAR, "ZAR")}
          </Statistic.Value>
          <Divider />
          <Statistic.Label>base-zar</Statistic.Label>
          <Statistic.Value>
            {formattedCurrency(this.state.baseZAR, "ZAR")}
          </Statistic.Value>

          <TextField
            hintText="ZAR Amount to add"
            inputMode="numeric"
            type="number"
            floatingLabelText={`Amount (ZAR)`}
            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
            underlineFocusStyle={fieldStyle.underlineStyle}
            value={this.state.amountZAR}
            onChange={this.handleAmountZARChange}
            ref={c => (this.amountInput = c)}
          />

          <br />

          <RaisedButton
            label="Add more ZAR to circulation"
            style={buttonStyle}
            labelColor={purple900}
            onTouchTap={this.addZAR}
            disabled={this.state.isProcessing}
          />
        </Statistic>

        <Dimmer.Dimmable dimmed={this.state.isProcessing}>
          <Dimmer active={this.state.isProcessing} inverted page>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>

        <Snackbar
          open={this.state.snackOpen}
          message={this.state.snackMessage}
          autoHideDuration={4000}
          onRequestClose={this.handleSnackClose}
        />
      </div>
    );
  }
}

export default Base;
