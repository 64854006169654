import React from 'react';
import Avatar from 'material-ui/Avatar';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardTitle } from 'material-ui/Card';

const PaymentListItem = props => {

    let title = props.title;
    let amount = props.amount;
    let note = props.note;
    let isCredit = props.isCredit;
    let timestamp = props.timestamp;
    let user = props.user;

    const avatarStyle = { objectFit: 'cover' };

    const amountColor = () => {
        if (isCredit) {
            return "green";
        } else {
            return "red";
        }
    };


    return (
        <div  style={{cursor: 'pointer'}} onClick={props.handleFetch}>
          <Card style={{margin: 10}}>
            <p style={{textAlign: 'right', float: 'right', margin: 15, fontSize: 15, color: amountColor()}}>
            {amount}
            </p>
            <CardHeader
              title={title}
              titleStyle={{marginRight: 65}}
              subtitle={timestamp}
              avatar={<Avatar
                  src={user.avatar}
                  style={avatarStyle}
               />}
            />
            <CardTitle title={note} titleStyle={{fontSize: 20}}/>
          </Card>
        </div>
    );
};

PaymentListItem.propTypes = {
    title: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
    isCredit: PropTypes.bool.isRequired,
    updatedAt: PropTypes.instanceOf(Date).isRequired,
    user: PropTypes.object.isRequired,
    amount: PropTypes.string.isRequired
};

export default PaymentListItem;