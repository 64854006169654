
import { Button, Input } from 'antd';
import React, { Component } from 'react';
import Card from '../tim-components/Card.jsx';

export default class WhitelistedStocksManualAdd extends Component {
    state = {
        newSymbol: ''
    }

    handleAction = () => {
        const { onAction } = this.props
        const { newSymbol } = this.state

        onAction(newSymbol)
    }

    render() {
        const { newSymbol } = this.props

        return (
            <Card
                title={'Manually add a stock symbol to be whitelisted'}
                content={
                    <div>
                        <Input
                            style={{ width: '100%', margin: 5 }}
                            placeholder='Symbol'
                            size={'default'}
                            defaultValue={newSymbol}
                            onChange={e => this.setState({ newSymbol: e.target.value })} />

                        <br />
                        <Button style={{ margin: 5 }} type={'primary'} onClick={this.handleAction}>{`Add Symbol`}</Button>
                    </div>
                }
            />
        )
    }
}

