import React, { Component } from 'react';
import Avatar from 'material-ui/Avatar';
import FlatButton from 'material-ui/FlatButton';
import { Card, CardActions, CardHeader, CardTitle, CardText } from 'material-ui/Card';

const avatarStyle = {objectFit: 'cover'};

class YourRequestCard extends Component {

  handleOnCancel() {
    this.props.handleCancel()
  }

  render() {
    return (
      <Card style={{margin: 10}}>
      <p style={{textAlign: 'right', float: 'right', margin: 15, fontSize: 15}}>{this.props.amount}</p>
      <CardHeader
        title={`You requested from ${this.props.user.first_name} ${this.props.user.last_name}`}
        titleStyle={{marginRight: 65}}
        subtitle={this.props.timestamp}
        avatar={<Avatar
         src={this.props.user.avatar}
         style={avatarStyle} />}
      />
      <CardTitle title={this.props.note} titleStyle={{fontSize: 20}} />
      <CardActions style={{width: '100%', textAlign: 'right'}}>
        <FlatButton label="Cancel this request" onTouchTap={this.handleOnCancel.bind(this)} />
      </CardActions>
    </Card>
    )
  }
}

export default YourRequestCard;
