
import React, { Component } from 'react';
import { Input, Button } from 'antd'
import Card from '../tim-components/Card.jsx';
import axios from 'axios'
import { notification } from 'antd'
const { TextArea } = Input

export default class SendNotification extends Component {
    state = {
        text: '',
        loading: false
    }

    handleTextChange = (event) => {
        this.setState({
            text: event.target.value,
        });
    };

    handleSendNotification = async () => {
        const { id: userId } = this.props.user
        const { text } = this.state

        this.setState({
            loading: true
        })

        try {
            await axios.post('/admin/support/sms', {
                userId,
                text: `Chipper Cash: ${text}`
            })

            notification.success({
                message: 'Success!',
                description: 'SMS Sent Successfully'
            });

            this.setState({
                text: '',
                loading: false
            })
        } catch (error) {
            notification.error({
                message: "Error",
                description: `An error occurred trying to send this sms ${error.message}`
            })
            this.setState({
                loading: false
            })
        }
    }

    render() {
        const { loading, text } = this.state
        return (
            <Card
                title={'Send SMS Notification'}
                category={"Your message will be prepended with 'Chipper Cash:'"}
                content={
                    <div>
                        <TextArea value={text} placeholder="SMS Message..." autosize={{ minRows: 3 }} onChange={this.handleTextChange} style={{ margin: 5 }} />
                        <br />
                        <Button loading={loading} style={{ margin: 5 }} type={'primary'} onClick={this.handleSendNotification}>{'Send SMS Notification'}</Button>
                    </div>
                }
            />
        )
    }
}

