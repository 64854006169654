import React, { Component } from 'react';
import Avatar from 'material-ui/Avatar';
import moment from 'moment';
import FlatButton from 'material-ui/FlatButton';
import { purple900 } from 'material-ui/styles/colors';
import { Card, CardActions, CardHeader, CardTitle, CardText } from 'material-ui/Card';
import LinearProgress from 'material-ui/LinearProgress';
import axios from 'axios';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const avatarStyle = {objectFit: 'cover'};

class OtherRequestCard extends Component {

    handleOnApprove() {
        this.props.handleApprove();
    }

    handleOnDecline() {
        this.props.handleDecline();
    }

    render() {
    	return (
            <div>
                <Card style={{margin: 10}}>
                    <p style={{textAlign: 'right', float: 'right', margin: 15, fontSize: 15}}>{this.props.amount}</p>
                    <CardHeader
                      title={`${this.props.user.first_name} ${this.props.user.last_name} sent you a request for money`}
                      titleStyle={{marginRight: 65}}
                      subtitle={this.props.timestamp}
                      avatar={<Avatar
                       src={this.props.user.avatar}
                       style={avatarStyle} />}
                    />
                    <CardTitle title={this.props.note} titleStyle={{fontSize: 20}} />
                    <CardActions style={{width: '100%', textAlign: 'right'}}>
                        <FlatButton style={{color: 'red'}} label="Decline" onTouchTap={this.handleOnDecline.bind(this)} />
                        <FlatButton style={{color: 'green'}} label="Approve" onTouchTap={this.handleOnApprove.bind(this)} />
                    </CardActions>
                  </Card>
            </div>
      )
    }
}
export default OtherRequestCard;
