import React, { Component } from 'react';
import { purple900 } from 'material-ui/styles/colors';
import axios from 'axios';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import formattedCurrency from './util/currencyFormatter'

import SelectField from 'material-ui/SelectField';
import { MenuItem } from 'material-ui/IconMenu';
import { Link } from 'react-router-dom';

import { Icon } from 'react-fa';
import { Message } from 'semantic-ui-react'

import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

const buttonStyle = {
    margin: 12
};

const fieldStyle = {
    hintStyle: {
        color: purple900
    },
    underlineStyle: {
        borderColor: purple900
    },
    floatingLabelFocusStyle: {
        color: purple900
    },
};

class CashOut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            balance: '',

            amount: '',
            currency: '',

            linkedAccounts: [],
            selectedLinkedAccount: '',

            txComplete: false,

            dialogTitle: '',
            dialogDetail: '',

            dialogOpen: false,

            confirmTitle: '',
            confirmAction: '',
            confirmOpen: false,

            isProcessing: false,
            snackOpen: false,
            message: '',

            addCashDisabled: false,
            cashOutDisabled: false
        };

        this.cashOut = this.cashOut.bind(this);
        this.cashIn = this.cashIn.bind(this);
        this.handleSnackClose = this.handleSnackClose.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleCarrierChange = this.handleCarrierChange.bind(this);
    }

    componentWillMount() {
        const currency = localStorage.getItem('currency')

        this.setState({
            isProcessing: true,
            currency
        });

        axios.get('/users/balance', {
            params: {
                currency
            }
        })
            .then(response => {
                const balance = response.data.balance
                this.setState({
                    balance,
                    isProcessing: false
                })
            })
            .catch(error => {
                this.setState({
                    isProcessing: false
                })
            })

        this.fetchLinkedAccounts()
    }

    fetchLinkedAccounts = () => {
        this.setState({
            isFetchingLinkedAccounts: true
        })
        axios.get('/linked_accounts')
            .then(response => {
                const linked_accounts = response.data.accounts
                const reversedLinkedAccounts = linked_accounts.reverse()

                if (reversedLinkedAccounts.length > 0) {
                    this.setState({
                        linkedAccounts: linked_accounts,
                        selectedLinkedAccount: linked_accounts[0].id,
                    })
                    this.checkLinkedAccountButtonStatus(linked_accounts[0].id)
                } else {
                    // This user has no linked accounts.
                    // We need to get her to add one.
                    // Else this entire screen is useless.
                    this.props.history.push('/linkAccount');
                }

                this.setState({
                    isFetchingLinkedAccounts: false
                })
            })
            .catch(error => {
                console.error("Error getting las: ", error)

                this.setState({
                    isFetchingLinkedAccounts: false
                })
            })

    }

    cashIn() {
        console.log("Will submit cash IN: ", this.state.amount, this.state.selectedLinkedAccount, this.state.currency);

        this.dismissKeyboard();

        this.verifyCashInAmount()
            .then(() => {
                this.setState({
                    isProcessing: true
                });

                return axios.post(`/deposits/prepare`, {
                    amount: this.state.amount,
                    currency: this.state.currency,
                    linkedAccountId: this.state.selectedLinkedAccount
                })
            })
            .then(response => {
                console.log("Response from deposits/prepare: ", response);

                const { mobile_money_phone, mobile_money_currency, mobile_money_carrier, mobile_money_country } = response.data.account
                const { id, origin_currency, origin_amount, destination_currency, destination_amount, exchange_rate } = response.data.transfer
                const phone = formatPhoneNumber(parsePhoneNumber(mobile_money_phone, { country: mobile_money_country }), 'National');


                let title
                let detail
                if (origin_currency === destination_currency) {
                    title = `Confirm you want to cash in ${formattedCurrency(destination_amount, destination_currency)}?`
                    detail = `You will receive a mobile money request for this amount on ${mobile_money_carrier} ${phone}`
                } else {
                    title = `Confirm you want to cash in ${formattedCurrency(destination_amount, destination_currency)}? `
                    detail = `You will receive a mobile money request for ${formattedCurrency(origin_amount, origin_currency)}  on ${mobile_money_carrier} ${phone}  | ${destination_currency} 1 == ${origin_currency} ${1 / exchange_rate}`
                }

                this.setState({
                    linkedAccountId: response.data.account.id,
                    confirmTitle: title,
                    confirmDetail: detail,
                    confirmOpen: true,
                    confirmAction: 'cashin',
                    isProcessing: false,
                    transferId: id,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
                if (error.response) {
                    const message = error.response.data.error.message;

                    this.setState({
                        dialogTitle: `Error occured preparing this deposit`,
                        dialogDetail: `Reason: ${message}`,
                        txComplete: false,
                        dialogOpen: true,
                        isProcessing: false
                    });
                }
            });
    }

    finalCashIn = () => {
        const linkedAccountId = this.state.linkedAccountId
        const transferId = this.state.transferId

        this.setState({
            confirmOpen: false,
            confirmTitle: '',
            confirmDetail: '',
            confirmAction: '',
            isProcessing: true
        })

        axios.post('/deposits', {
            linkedAccountId,
            transferId
        })
            .then(response => {
                console.log("Response from cash in:", response)

                this.setState({
                    isProcessing: false,
                    dialogOpen: true,
                    txComplete: true,
                    dialogTitle: `Cash in for ${formattedCurrency(this.state.amount, this.state.currency)} successfully requested!`,
                    dialogDetail: `Please complete the instructions you recieve on your device. It might say 'Beyonic' or something. That's our partner. They are cool.`,
                    amount: ''
                });
            })
            .catch(error => {
                const message = error.response.data.error.message;

                console.error("Error requesting withdrawal: ", message);

                this.setState({
                    dialogTitle: "Deposit Failed",
                    dialogDetail: `Reason: ${message}`,
                    txComplete: false,
                    dialogOpen: true,
                    isProcessing: false
                });

                console.log("Error: ", error.response);
            })
    }

    cashOut() {
        console.log("Will submit cash out: ", this.state.amount, this.state.selectedLinkedAccount, this.state.currency);

        this.dismissKeyboard();

        this.verifyCashoutAmount()
            .then(() => {
                this.setState({
                    isProcessing: true
                });

                return axios.post(`/withdrawals/prepare`, {
                    amount: this.state.amount,
                    currency: this.state.currency,
                    linkedAccountId: this.state.selectedLinkedAccount
                })
            })
            .then(response => {
                console.log("Response from withdrawals/prepare: ", response);
                console.log("prepare log: ", response)
                const { mobile_money_phone, mobile_money_currency, mobile_money_carrier, mobile_money_country } = response.data.account
                const { id, origin_currency, origin_amount, destination_currency, destination_amount, exchange_rate } = response.data.transfer
                const phone = formatPhoneNumber(parsePhoneNumber(mobile_money_phone, { country: mobile_money_country }), 'National');

                let title
                let detail
                if (origin_currency === destination_currency) {
                    title = `Confirm you want to cash out ${formattedCurrency(origin_amount, origin_currency)} to ${mobile_money_carrier} ${phone}?`
                } else {
                    title = `Confirm you want to cash out  ${formattedCurrency(origin_amount, origin_currency)} (${formattedCurrency(destination_amount, destination_currency)}) to ${mobile_money_carrier} ${phone}?`
                    detail = `${origin_currency} 1 == ${destination_currency} ${exchange_rate}`
                }

                this.setState({
                    linkedAccountId: response.data.account.id,
                    confirmTitle: title,
                    confirmDetail: detail,
                    confirmOpen: true,
                    confirmAction: 'cashout',
                    isProcessing: false,
                    transferId: id,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
                if (error.response) {
                    const message = error.response.data.error.message;

                    this.setState({
                        dialogTitle: `Error occured preparing this withdrawal`,
                        dialogDetail: `Reason: ${message}`,
                        txComplete: false,
                        dialogOpen: true,
                        isProcessing: false
                    });
                }
            });
    }

    finalCashOut = () => {
        const linkedAccountId = this.state.linkedAccountId
        const transferId = this.state.transferId

        this.setState({
            confirmOpen: false,
            confirmTitle: '',
            confirmDetail: '',
            confirmAction: '',
            isProcessing: true
        })

        axios.post('/withdrawals', {
            linkedAccountId,
            transferId
        })
            .then(response => {

                this.setState({
                    isProcessing: false,
                    dialogOpen: true,
                    txComplete: true,
                    dialogTitle: `Withdrawal Request for ${formattedCurrency(this.state.amount, this.state.currency)} successfully sent!`,
                    dialogDetail: `Funds will arrive in a few minutes`,
                    amount: ''
                });
            })
            .catch(error => {
                const message = error.response.data.error.message;

                console.error("Error requesting withdrawal: ", message);

                this.setState({
                    dialogTitle: "Withdrawal Failed",
                    dialogDetail: `Reason: ${message}`,
                    txComplete: false,
                    dialogOpen: true,
                    isProcessing: false
                });

                console.log("Error: ", error.response);
            })
    }


    verifyCashInAmount() {
        return new Promise((resolve, reject) => {

            if (this.state.amount.length === 0) {
                let error = Error("You need to input an amount")
                alert(error)
                reject(error)
                return
            }

            let _amount = parseFloat(this.state.amount);
            if (_amount % 1 !== 0) {
                const rounded = Math.round(_amount)
                let error = Error(`${_amount} is an invalid amount. Currently, you need to input a whole number.\n\nTip: Try using ${rounded}`)
                alert(error)
                reject(error)
                return
            }

            resolve()
        });
    }

    verifyCashoutAmount() {
        return new Promise((resolve, reject) => {

            if (this.state.amount.length === 0) {
                let error = Error("You need to input an amount")
                alert(error)
                reject(error)
                return
            }

            let _amount = parseFloat(this.state.amount);
            if (_amount % 1 !== 0) {
                const rounded = Math.round(_amount)
                let error = Error(`${_amount} is an invalid amount. Currently, you need to input a whole number.\n\nTip: Try using ${rounded}`)
                alert(error)
                reject(error)
                return
            }

            resolve()
        });
    }

    handleSnackClose() {
        this.setState({
            snackOpen: false,
        });
    }

    handleAmountChange(event) {
        this.setState({
            amount: event.target.value,
        });
    }

    handlePhoneChange(event) {
        this.setState({
            phone: event.target.value
        });
    }

    handleCarrierChange(event) {
        this.setState({
            carrier: event.target.value
        });
    }

    dismissKeyboard() {
        this.amountInput.blur();
        //        this.phoneInput.blur();
        //        this.carrierInput.blur();
    }

    // Dialog Show/Hide
    handleOpen = () => {
        this.setState({ dialogOpen: true });
    }

    handleClose = () => {
        this.setState({ dialogOpen: false });
    }

    handleConfirmClose = () => {
        this.setState({ confirmOpen: false });
    }

    handleLinkedAccountChange = (event, index, selectedLinkedAccount) => {
        this.setState({
            selectedLinkedAccount
        })
        this.checkLinkedAccountButtonStatus(selectedLinkedAccount)
    }

    checkLinkedAccountButtonStatus(selectedLinkedAccount) {
        let addCashDisabled = false
        let cashOutDisabled = false

        const selected = this.state.linkedAccounts.filter(la => {
            return la.id === selectedLinkedAccount
        })[0]

        if (!selected) { return }

        if (selected.mobile_money_carrier === 'VODAFONE' && selected.mobile_money_country === 'GH') {
            cashOutDisabled = true
        } else if (selected.mobile_money_carrier === 'AIRTEL' && selected.mobile_money_country === 'GH') {
            addCashDisabled = true
        }

        this.setState({
            addCashDisabled,
            cashOutDisabled
        })
    }

    dialogAction = () => {
        // Go back to the Activity List.
        if (this.state.txComplete) {
            this.props.history.push('/activity');
        }

        this.setState({
            dialogOpen: false
        });
    }

    showAddLinkedAccount = () => {
        console.log('pressed..')
    }

    renderLoading() {
        return (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );
    }

    messageForSelectedLinkedAccount() {

        // Find that linked account. 
        const selected = this.state.linkedAccounts.filter(la => {
            return la.id === this.state.selectedLinkedAccount
        })[0]

        if (!selected) { return }

        // Temporary messages
        if (selected.mobile_money_carrier === 'VODAFONE' && selected.mobile_money_country === 'GH') {
            const message = `Cash outs to ${selected.mobile_money_carrier} ${selected.mobile_money_country} coming soon!`
            return (
                <Message warning>
                    <p>{message}</p>
                </Message >
            )
        } else if (selected.mobile_money_carrier === 'AIRTEL' && selected.mobile_money_country === 'GH') {
            const message = `Cash ins from ${selected.mobile_money_carrier} ${selected.mobile_money_country} coming soon!`
            return (
                <Message warning>
                    <p>{message}</p>
                </Message >
            )
        }

        return <div></div>
    }

    render() {

        if (this.state.isFetchingLinkedAccounts) {
            return this.renderLoading()
        }

        const actions = [
            <FlatButton label="OK"
                style={{ color: purple900 }}
                onTouchTap={this.dialogAction}
            />
        ];

        const cashOutActions = [
            <FlatButton label="No, cancel"
                style={{ color: 'gray' }}
                onTouchTap={this.handleConfirmClose}
            />,
            <FlatButton label="Yes, Cash Out"
                style={{ color: purple900 }}
                onTouchTap={this.finalCashOut}
            />
        ]

        const cashInActions = [
            <FlatButton label="No, cancel"
                style={{ color: 'gray' }}
                onTouchTap={this.handleConfirmClose}
            />,
            <FlatButton label="Yes, Cash In"
                style={{ color: purple900 }}
                onTouchTap={this.finalCashIn}
            />
        ]

        let accounts = this.state.linkedAccounts.map(account => {
            const text = `${account.mobile_money_carrier}: ${account.mobile_money_phone} (${account.mobile_money_currency})`
            return <MenuItem
                key={account.id}
                value={account.id}
                primaryText={text} />
        })

        // Add new Linked Account
        accounts.push(
            <MenuItem
                key='add'
                value='add'
                primaryText='+ Link another account'
                containerElement={<Link to="/linkAccount" />} />
        )

        const LoadingIndicator = () => (
            <div>
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        return (
            <div style={{ textAlign: 'center' }}>
                {this.state.linkedAccounts.length > 0
                    ? <div>
                        {this.messageForSelectedLinkedAccount()}
                        <div style={{ paddingTop: '7%' }}>
                            <Icon name="money" size="2x" style={{ paddingBottom: '1%', color: '#4527A0' }} /><br />
                            <h2 style={{ fontSize: "1.3em", marginLeft: 5, marginRight: 5 }}>Transfer money to/from your mobile money accounts</h2>
                        </div>

                        <h4 style={{ fontWeight: 600 }}> Chipper Balance: {formattedCurrency(this.state.balance, this.state.currency)}</h4>
                        <TextField
                            inputMode="numeric"
                            type="number"
                            style={{ textAlign: 'left' }}
                            hintText={`Amount (in ${this.state.currency})`}
                            floatingLabelText={`Amount (in ${this.state.currency})`}
                            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                            underlineFocusStyle={fieldStyle.underlineStyle}
                            value={this.state.amount}
                            onChange={this.handleAmountChange}
                            ref={(c) => this.amountInput = c} />

                        <br />

                        <SelectField
                            style={{ textAlign: 'left' }}
                            hintText="Linked Account"
                            floatingLabelText="Linked Account"
                            floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                            underlineFocusStyle={fieldStyle.underlineStyle}
                            value={this.state.selectedLinkedAccount}
                            onChange={this.handleLinkedAccountChange}
                            selectedMenuItemStyle={fieldStyle.hintStyle}
                        >
                            {accounts}
                        </SelectField>

                        <div style={{ paddingTop: 20 }}>
                            <RaisedButton
                                label="Add Cash"
                                labelColor={purple900}
                                onTouchTap={this.cashIn}
                                disabled={this.state.isProcessing || this.state.addCashDisabled} 
                                style={{ width: '180px', borderBottom: '1px solid #4527A0'}} />

                            <RaisedButton
                                label="Cash Out"
                                style={buttonStyle}
                                labelColor={purple900}
                                onTouchTap={this.cashOut}
                                disabled={this.state.isProcessing || this.state.cashOutDisabled} 
                                style={{ width: '180px', borderTop: '1px solid #4527A0'}} />
                        </div>

                        {this.state.isProcessing ? <LoadingIndicator /> : ""}

                        <Snackbar
                            open={this.state.snackOpen}
                            message={this.state.message}
                            autoHideDuration={4000}
                            onRequestClose={this.handleSnackClose}
                        />

                        <Dialog
                            title={this.state.dialogTitle}
                            actions={actions}
                            modal={false}
                            open={this.state.dialogOpen}
                            onRequestClose={this.handleClose}>

                            <p>
                                {this.state.dialogDetail}
                            </p>

                        </Dialog>

                        <Dialog
                            title={this.state.confirmTitle}
                            actions={this.state.confirmAction === 'cashout' ? cashOutActions : cashInActions}
                            modal={false}
                            open={this.state.confirmOpen}
                            onRequestClose={this.handleConfirmClose}>
                            <p>
                                {this.state.confirmDetail}
                            </p>
                        </Dialog>
                    </div>
                    :
                    <div>
                        <h4>Loading... </h4>
                    </div>
                }
            </div>
        );
    }
}

export default CashOut;
