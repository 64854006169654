import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { purple900 } from 'material-ui/styles/colors';
import CircularProgress from 'material-ui/CircularProgress';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import axios from 'axios';
import _ from 'lodash'

const fieldStyle = {
    hintStyle: {
        color: purple900
    },
    underlineStyle: {
        borderColor: purple900
    },
    floatingLabelFocusStyle: {
        color: purple900
    },
};

const buttonStyle = {
    margin: 12
};

const headerStyle = {
    marginLeft: 20,
    marginRight: 20
};

const currencies = [
    <MenuItem key={1} value={'GHS'} primaryText="🇬🇭 Ghana Cedi" />,
    <MenuItem key={2} value={'UGX'} primaryText="🇺🇬 Uganda Shillings" />,
    <MenuItem key={3} value={'KES'} primaryText="🇰🇪 Kenyan Shillings" />
];

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            password1: '',
            password2: '',

            password1obscure: '',
            password2obscure: '',

            isFetching: false,
            currency: ''
        };
    }

    componentDidMount() {
        // TODO: Should read from the db, and update this form accordingly
        // Eg. If they signed in from Google or something
    }

    handleFirstNameChange = (event) => {
        this.setState({
            firstName: event.target.value,
        });
    };

    handleLastNameChange = (event) => {
        this.setState({
            lastName: event.target.value,
        });
    };

    avatarURL = (name) => {
        const first = name.toUpperCase().charAt(0);
        const url = `https://imgur.com/images/avatars/${first}.png`;
        return url;
    }

    handlePassword1Change = (event) => {
        const input = event.target.value
        const obscure = _.repeat('•', input.length)
        let password1 = this.state.password1;
        const last = input[input.length - 1]

        if (input.length === 0) {
            password1 = ''
        } else if (isNaN(last)) {
            // Backspace - Remove Last Character
            password1 = password1.slice(0, -1)
        } else {
            // Append last character
            password1 = password1 += last
        }

        this.setState({
            password1,
            password1obscure: obscure
        });
    };

    handleCurrencyChange = (event, index, currency) => {
        this.setState({
            currency
        })
    }

    handlePassword2Change = (event) => {
        const input = event.target.value
        const obscure = _.repeat('•', input.length)
        let password2 = this.state.password2;
        const last = input[input.length - 1]

        if (input.length === 0) {
            password2 = ''
        } else if (isNaN(last)) {
            // Backspace - Remove Last Character
            password2 = password2.slice(0, -1)
        } else {
            // Append last character
            password2 = password2 += last
        }

        this.setState({
            password2,
            password2obscure: obscure
        });
    };

    validatePinMatch() {
        return new Promise((resolve, reject) => {

            const pin1 = this.state.password1;
            const pin2 = this.state.password2;

            if (pin1 !== pin2) {
                let error = new Error("PINs don't match");
                reject(error);
            }

            if (isNaN(pin1) || isNaN(pin2)) {
                let error = new Error("PINs need to be numbers");
                reject(error);
            }

            if (pin1.length !== 6 || pin2.length !== 6) {
                let error = new Error("PINs need to be 6 digits");
                reject(error);
            }

            // TODO: Verify the pin is actually a digit.
            // Resolve here if the pin is good to be saved
            resolve();
        });
    }

    register = (event) => {
        this.dismissKeyboard();

        this.setState({
            isFetching: true
        });

        this.validatePinMatch()
            .then(() => {
                return axios.post('/users', {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    currency: this.state.currency,
                    pin: this.state.password1
                })
            })
            .then(result => {
                // Saved. Move on to /activity
                this.props.history.push('/activity');
            })
            .catch(error => {
                console.log("Error: ", error.response);

                let message = '';
                if (error.response) {
                    message = error.response.data.error.message;
                } else {
                    message = error.message;
                }

                this.setState({
                    password1: '',
                    password2: '',
                    password1obscure: '',
                    password2obscure: '',

                    isFetching: false
                });

                window.notifications.addNotification({
                    level: 'error',
                    message,
                    position: 'bc'
                });
            });
    };

    dismissKeyboard() {
        this.firstInput.blur();
        this.lastInput.blur();
        this.password1Input.blur();
        this.password2Input.blur();
    }

    render() {
        const LoadingIndicator = () => (
            <div>
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        return (
            <div style={{ textAlign: 'center' }}>

                <br />
                <h2 style={headerStyle}> Welcome aboard! Please complete your profile </h2>
                <br />

                <TextField
                    style={{ textAlign: 'left' }}
                    hintText="First Name"
                    floatingLabelText="First Name"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.firstName}
                    onChange={this.handleFirstNameChange}
                    ref={(c) => this.firstInput = c} />

                <br />

                <TextField
                    style={{ textAlign: 'left' }}
                    hintText="Last Name"
                    floatingLabelText="Last Name"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.lastName}
                    onChange={this.handleLastNameChange}
                    ref={(c) => this.lastInput = c} />

                <br />

                <SelectField
                    style={{ textAlign: 'left' }}
                    hintText="Currency"
                    floatingLabelText="Currency"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.currency}
                    onChange={this.handleCurrencyChange}
                    selectedMenuItemStyle={fieldStyle.hintStyle}
                >
                    {currencies}
                </SelectField>

                <h4 style={headerStyle}> Choose a 6 digit pin number. Remember this number. You will need it in the future to keep your account secure.</h4>

                <TextField
                    style={{ textAlign: 'left' }}
                    hintText="Pin"
                    floatingLabelText="Pin"
                    pattern="\d*"
                    inputmode="numeric"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.password1obscure}
                    onChange={this.handlePassword1Change}
                    ref={(c) => this.password1Input = c} />

                <br />

                <TextField
                    style={{ textAlign: 'left' }}
                    hintText="Confirm Pin"
                    pattern="\d*"
                    inputmode="numeric"
                    floatingLabelText="Confirm Pin"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.password2obscure}
                    onChange={this.handlePassword2Change}
                    ref={(c) => this.password2Input = c} />

                <div>
                    <br />
                    <RaisedButton
                        label="Register"
                        style={buttonStyle}
                        labelColor={purple900}
                        onTouchTap={this.register}
                        disabled={this.state.isFetching} />
                    <br />
                </div>
                <br />
                {this.state.isFetching ? <LoadingIndicator /> : ""}
            </div>
        )
    }
}

export default Register;
