const Formatter = require('currencyformatter.js')

function formattedCurrency (amount, currency) {
    const GHS = {
        currency: 'GHS',
        symbol: '₵',			
        locale: 'en',			
        group: ',',
        pattern: '!#,##0.00' 
    }

    const UGX = {
        currency: 'UGX',
        symbol: 'UGX',
        locale: 'en',
        group: ',',
        pattern: '! #,##0.00'
    }

    const RWF = {
        currency: 'RWF',
        symbol: 'RWF',
        locale: 'en',
        group: ',',
        pattern: '! #,##0.00'
    }

    switch (currency) {
        case 'GHS':
            return Formatter.format(amount, GHS)
        case 'UGX':
            return Formatter.format(amount, UGX)
        case 'RWF':
            return Formatter.format(amount, RWF)
        default:
            return Formatter.format(amount, { currency, locale: 'en_US' })
    }
}

export default formattedCurrency;
