import React, { Component } from 'react';
import { List } from 'material-ui/List';
import formattedCurrency from './util/currencyFormatter'
import { formattedTimestamp } from './util/helpers'
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';

// Cards
import PaymentListItem from './PaymentListItem';
import PaymentInvitationCard from './PaymentInvitationCard';
import StrikethroughCard from './StrikethroughCard'
import YourRequestCard from './YourRequestCard';
import OtherRequestCard from './OtherRequestCard';
import WithdrawalCard from './WithdrawalCard';
import DepositCard from './DepositCard';
import AirtimeCard from './AirtimeCard';
import './App.css';

export default class ActivityList extends Component {

    getListItems = items => {
        return items.map(tx => {
            if (!tx) {
                return null
            }
            if (tx.type === 'settled_card') {
                let sign = tx.isCredit ? "+" : "-";
                let amount = `${sign} ${formattedCurrency(tx.amount, tx.currency)}`;
                return {
                    title: tx.title,
                    user: tx.relevantUser,
                    note: tx.note,
                    isCredit: tx.isCredit,
                    id: `${tx.id}${tx.type}${tx.updatedAt}`,
                    updatedAt: new Date(tx.updatedAt),
                    timestamp: formattedTimestamp(tx.updatedAt),
                    amount: amount,
                    cardType: tx.type
                };
            } else if (tx.type === 'payment_invitation_card') {
                let sign = tx.isCredit ? "+" : "-";
                let amount = `${sign} ${formattedCurrency(tx.amount, tx.currency)}`;
                return {
                    title: tx.title,
                    user: tx.relevantUser,
                    note: tx.note,
                    isCredit: false,
                    id: `${tx.id}${tx.type}${tx.updatedAt}`,
                    updatedAt: new Date(tx.updatedAt),
                    timestamp: formattedTimestamp(tx.updatedAt),
                    amount: amount,
                    cardType: tx.type
                };
            } else if (tx.type === 'strikethrough_card') {
                let amount = `${formattedCurrency(tx.amount, tx.currency)}`;
                return {
                    title: tx.title,
                    user: tx.relevantUser,
                    note: tx.note,
                    id: `${tx.id}${tx.type}${tx.updatedAt}`,
                    updatedAt: new Date(tx.updatedAt),
                    timestamp: formattedTimestamp(tx.updatedAt),
                    amount: amount,
                    cardType: tx.type
                };
            } else if (tx.type === 'your_request_card') {
                let amount = formattedCurrency(tx.amount, tx.currency);
                return {
                    user: tx.relevantUser,
                    amount: amount,
                    id: tx.id,
                    note: tx.note,
                    currency: tx.currency,
                    timestamp: formattedTimestamp(tx.updatedAt),
                    cardType: tx.type
                }
            } else if (tx.type === 'other_request_card') {
                let amount = formattedCurrency(tx.amount, tx.currency);
                return {
                    user: tx.relevantUser,
                    amount,
                    id: tx.id,
                    note: tx.note,
                    currency: tx.currency,
                    timestamp: formattedTimestamp(tx.updatedAt),
                    cardType: tx.type
                };
            } else if (tx.type === 'withdrawal_card') {
                let amount = formattedCurrency(tx.amount, tx.currency);
                return {
                    user: tx.relevantUser,
                    title: tx.title,
                    amount: amount,
                    updatedAt: formattedTimestamp(tx.updatedAt),
                    id: `${tx.id}${tx.type}${tx.updatedAt}`,
                    currency: tx.currency,
                    cardType: tx.type,
                    status: tx.status,
                    withdrawal: tx.withdrawal
                }
            } else if (tx.type === 'deposit_card') {
                let amount = formattedCurrency(tx.amount, tx.currency);
                return {
                    user: tx.relevantUser,
                    title: tx.title,
                    amount: amount,
                    updatedAt: formattedTimestamp(tx.updatedAt),
                    id: `${tx.id}${tx.type}${tx.updatedAt}`,
                    currency: tx.currency,
                    cardType: tx.type,
                    status: tx.status,
                    deposit: tx.deposit
                }
            } else if (tx.type === 'airtime_purchase_card') {
                let amount = formattedCurrency('' + tx.airtime.receiveValue, tx.currency);
                let saveAmount = formattedCurrency('' + tx.airtime.receiveValue - tx.airtime.destinationAmount, tx.airtime.currency);
                let purchaseAmount = formattedCurrency('' + tx.airtime.destinationAmount, tx.currency);
                let receiveValue = tx.airtime.receiveValue;
                return {
                    user: tx.airtime.userId,
                    title: tx.title,
                    amount: amount,
                    saveAmount: saveAmount,
                    purchaseAmount: purchaseAmount,
                    receiveValue: receiveValue,
                    updatedAt: formattedTimestamp(tx.updatedAt),
                    id: `${tx.id}${tx.type}${tx.updatedAt}`,
                    currency: tx.airtime.destinationCurrency,
                    cardType: tx.type,
                    status: tx.status,
                    airtime: tx.airtime
                }
            } else {
                // Return nulls. They will get filtered out below
                return null
            }
        }).filter(a => a)
    }

    render() {
        const { isFetching, items } = this.props

        if (!items) {
            console.log("No items pased into ActivityList")
            return
        }

        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color="black" />
            </div>
        );

        const EmptyStateView = () => (
            <div className="posCenter">
                You have no activity on Chipper.
            </div>
        );

        const listItems = this.getListItems(items).map(tx => {
            if (tx.cardType === 'settled_card') {
                return (
                    <PaymentListItem
                        key={tx.id}
                        title={tx.title}
                        user={tx.user}
                        note={tx.note}
                        isCredit={tx.isCredit}
                        updatedAt={tx.updatedAt}
                        timestamp={tx.timestamp}
                        amount={tx.amount}>
                    </PaymentListItem>
                );
            } else if (tx.cardType === 'payment_invitation_card') {
                return (
                    <PaymentInvitationCard
                        key={tx.id}
                        title={tx.title}
                        user={tx.user}
                        note={tx.note}
                        isCredit={tx.isCredit}
                        updatedAt={tx.updatedAt}
                        timestamp={tx.timestamp}
                        amount={tx.amount} />
                );
            } else if (tx.cardType === 'strikethrough_card') {
                return (
                    <StrikethroughCard
                        key={tx.id}
                        title={tx.title}
                        user={tx.user}
                        note={tx.note}
                        updatedAt={tx.updatedAt}
                        timestamp={tx.timestamp}
                        amount={tx.amount} />
                );
            } else if (tx.cardType === 'your_request_card') {
                return (
                    <YourRequestCard
                        key={tx.id}
                        id={tx.id}
                        user={tx.user}
                        amount={tx.amount}
                        currency={tx.currency}
                        timestamp={tx.timestamp}
                        note={tx.note}
                        handleCancel={e => this.props.handleCancel(tx)} />
                );
            } else if (tx.cardType === 'other_request_card') {
                return (
                    <OtherRequestCard
                        key={tx.id}
                        id={tx.id}
                        user={tx.user}
                        amount={tx.amount}
                        timestamp={tx.timestamp}
                        note={tx.note}
                        currency={tx.currency}
                        handleApprove={e => this.props.handleApprove(tx)}
                        handleDecline={e => this.props.handleDecline(tx)} />
                )
            } else if (tx.cardType === 'withdrawal_card') {
                return (
                    <WithdrawalCard
                        key={tx.id}
                        id={tx.id}
                        title={tx.title}
                        user={tx.user}
                        amount={tx.amount}
                        currency={tx.currency}
                        status={tx.status}
                        updatedAt={tx.updatedAt}
                        withdrawal={tx.withdrawal} />
                )
            } else if (tx.cardType === 'deposit_card') {
                return (
                    <DepositCard
                        key={tx.id}
                        id={tx.id}
                        title={tx.title}
                        user={tx.user}
                        amount={tx.amount}
                        currency={tx.currency}
                        status={tx.status}
                        updatedAt={tx.updatedAt}
                        deposit={tx.deposit} />
                )
            } else if (tx.cardType === 'airtime_purchase_card') {
                return (
                    <AirtimeCard
                        key={tx.id}
                        id={tx.id}
                        title={tx.title}
                        user={tx.user}
                        amount={tx.amount}
                        saveAmount={tx.saveAmount}
                        purchaseAmount={tx.purchaseAmount}
                        receiveValue={tx.receiveValue}
                        currency={tx.currency}
                        status={tx.status}
                        updatedAt={tx.updatedAt}
                        airtime={tx.airtime} />
                )
            }
        });

        let activityRendered = null;
        if (listItems.length > 0) {
            activityRendered = listItems;
        } else {
            activityRendered = <EmptyStateView />;
        }

        const Activity = () => {
            return (
                <List>
                    <h3 style={{ margin: 20 }}>Activity </h3>
                    {activityRendered}
                </List>
            );
        };

        return (
            <div>
                <div className="container">
                    {isFetching && items.length === 0 ? <LoadingIndicator /> : <Activity />}
                </div>
            </div>
        );
    }
}
