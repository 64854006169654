import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import { purple900 } from 'material-ui/styles/colors';
import { Dimmer, Loader, Label } from 'semantic-ui-react';
import { notification } from "antd";
import { colorForStatus } from '../util/colors'
import { filter, UserCell, NumberCell } from '../util/helpers'
import ReactTable from "react-table";
import {
    Grid, Row, Col
} from 'react-bootstrap';
import Card from '../tim-components/Card.jsx';
import '../assets/css/bootstrap.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';
import Toggle from 'material-ui/Toggle';
import MetaTags from 'react-meta-tags';

notification.config({
    top: 100
});

class CarrierControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carriercontrols: [],
            isProcessing: true,
        };
    }

    componentDidMount() {
        this.fetchAllServices();
    }

    fetchAllServices = () => {
        axios.get('/services')
            .then(response => {
                console.log('response: ', response.data)
                const carriercontrols = response.data.map(tx => {
                    let date;
                    if (moment(tx.updated_at).isValid()) {
                        date = moment(tx.updated_at).format('YYYY-MM-DD | HH:mm')
                    } else {
                        date = "-"
                    }
                    let error = tx.error_message ? tx.error_message : '-'

                    return {
                        carrier: tx.carrier,
                        country: tx.country,
                        deposits_available: tx.deposits_available,
                        withdrawals_available: tx.withdrawals_available,
                        updatedAt: date,
                        depositaction: (
                            <div>
                                {<Toggle
                                    label=""
                                    defaultToggled={tx.deposits_available}
                                    thumbSwitchedStyle={{ backgroundColor: '#333' }}
                                    trackSwitchedStyle={{ backgroundColor: '#666' }}
                                    onToggle={() => this.handleDepositToggle(tx.carrier, tx.country, !tx.deposits_available)}
                                />}
                            </div>
                        ),
                        withdrawalaction: (
                            <div>
                                {<Toggle
                                    label=""
                                    defaultToggled={tx.withdrawals_available}
                                    thumbSwitchedStyle={{ backgroundColor: '#333' }}
                                    trackSwitchedStyle={{ backgroundColor: '#666' }}
                                    onToggle={() => this.handleWithdrawalToggle(tx.carrier, tx.country, !tx.withdrawals_available)}
                                />}
                            </div>
                        )
                    }
                });

                this.setState({
                    carriercontrols,
                    isProcessing: false
                });
            })
            .catch(error => {
                this.setState({
                    isProcessing: false
                });
            });
    }

    handleDepositToggle = (carrier, country, depositBoolean) => {
        // What's the end point.
        const url = `/admin/support/services/deposits`
        axios.put(url, {
            carrier: carrier,
            country: country,
            available: depositBoolean
        })
            .then(response => {
                const description = country + ": " + carrier + " toggled.";
                notification.success({
                    message: "Success",
                    description
                });
                this.setState({
                    isProcessing: true
                })
                this.fetchAllServices()
            })
            .catch(error => {
                alert(error)
            })
    }

    handleWithdrawalToggle = (carrier, country, withdrawalBoolean) => {
        // What's the end point.
        const url = `/admin/support/services/withdrawals`
        axios.put(url, {
            carrier: carrier,
            country: country,
            available: withdrawalBoolean
        })
            .then(response => {
                const description = country + ": " + carrier + " toggled.";
                notification.success({
                    message: "Success",
                    description
                });
                this.setState({
                    isProcessing: true
                })
                this.fetchAllServices()
            })
            .catch(error => {
                alert(error)
            })
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        const { carriercontrols } = this.state

        return (
            <div>
                <MetaTags>
                    <title>Chipper Cash | Carrier Controls</title>
                    <meta property="og:title" content="Chipper Cash | Withdrawals" />
                </MetaTags>
                <h2 style={{ textAlign: 'center' }}> Carrier Controls ({carriercontrols.length}) </h2>

                <br /><br /><br />
                <div>
                    {this.state.isProcessing && this.state.carriercontrols.length === 0 ? <LoadingIndicator /> :
                        <div className="main-content">
                            <Grid fluid>
                                <Row>
                                    <Col md={12}>
                                        <Card
                                            content={
                                                <ReactTable
                                                    data={carriercontrols}
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: "Carrier",
                                                            accessor: "carrier",
                                                        },
                                                        {
                                                            Header: "Country",
                                                            accessor: "country",
                                                        },
                                                        {
                                                            Header: "Deposits",
                                                            accessor: "depositaction",
                                                            filterable: false,
                                                        },
                                                        {
                                                            Header: "Withdrawals",
                                                            accessor: "withdrawalaction",
                                                            filterable: false,
                                                        },
                                                        {
                                                            Header: "Updated At",
                                                            accessor: "updatedAt",
                                                        }
                                                    ]}
                                                    defaultPageSize={12}
                                                    defaultSorted={[
                                                        {
                                                            id: "country",
                                                            desc: true
                                                        }
                                                    ]}
                                                    showPaginationTop
                                                    showPaginationBottom
                                                    defaultFilterMethod={filter}
                                                    className="-striped -highlight"
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                </div>

                <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                    <Dimmer active={this.state.isProcessing} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </div>
        );
    }
}

export default CarrierControls;
