import React, { Component } from 'react';
import { Switch } from 'antd'
import Card from '../tim-components/Card.jsx';

export default class ToggleUserLock extends Component {
    onChange = (checked) => {
        const { lock } = this.props
        var prompt_label = ""

        if (checked) {
            prompt_label = "Note to unfreeze this account:"
        } else {
            prompt_label = "Note to freeze this account:"
        }

        var freeze_note = prompt(prompt_label);
        if (freeze_note != null) {
            lock(!checked, freeze_note)
        } else {
            alert("User Not Frozen")
        }
    }

    render() {
        const { user, fetchingLock } = this.props
        let title = user.is_locked ? 'This user is locked and cannot move any money' : 'Online! Can move money.'
        let subtitle = user.is_locked ? 'Turn this switch ON to unlock a user' : 'Turn this switch OFF to lock a user'
        return (
            <Card
                title={'Toggle User Lock'}
                category={subtitle}
                content={
                    <div>
                        {fetchingLock ?
                            <div>Loading...</div> :
                            <div>
                                <Switch checked={!user.is_locked} onChange={this.onChange} />
                                <br />
                                <p>{title}</p>
                            </div>
                        }
                    </div>
                }
            />
        )
    }
}
