import { Link } from "react-router-dom";
import { purple900 } from "material-ui/styles/colors";
import React from "react";
import moment from "moment";
import RaisedButton from 'material-ui/RaisedButton'

/* Use this function for a generic filtering within our DataTables */
export const filter = (filter, row) => {
  const id = filter.pivotId || filter.id;
  const term = filter.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  return new RegExp(term, "gi").test(row[id]);

  /*
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
        */
};

const currentYear = moment().year();
export const formattedTimestamp = date => {
  return moment(date).calendar(null, {
    sameElse: function (now) {
      if (this.year() < currentYear) {
        return "MMM D, YYYY [at] h:mm A";
      } else {
        return "MMM D [at] h:mm A";
      }
    }
  });
};

export const UserCell = (name, id) => {

  return (
    <div>
      <Link
        target="_blank"
        to={`/admin/user/${id}`}
        style={{ color: purple900 }}
      >
        {name}
      </Link>
    </div>
  );
};

export const DepositCell = id => {
  return (
    <div>
      <Link
        target="_blank"
        to={`/admin/deposit/${id}`}
        style={{ color: purple900 }}
      >
        {id}
      </Link>
    </div>
  );
};

export const WithdrawalCell = id => {
  return (
    <div>
      <Link
        target="_blank"
        to={`/admin/withdrawal/${id}`}
        style={{ color: purple900 }}
      >
        {id}
      </Link>
    </div>
  );
};

export const UserAmplitudeCell = id => {
  return (
    <div>
      <a
        target="_blank"
        href={`https://analytics.amplitude.com/chippercash/project/204512/search/${id}`}
      >
        {id}
      </a>
    </div>
  );
};
/*
       return (
           row[id] !== undefined ?
               String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
               :
               true
       );
       */

export const NumberCell = value => {
  return <span style={{ fontSize: 14 }}>{value.toLocaleString()}</span>;
};

export const MobileMoneyProfile = value => {
  return (
    <div>
      <Link
        target="_blank"
        to={`/admin/mobilemoney/${value}`}
        style={{ color: purple900 }}
      >
        {value}
      </Link>
    </div>
  );
};

export const CheckoutCell = tag => {
  return (
    <div>
      <a target="_blank" href={`https://web.chippercash.com/me/${tag}`}>
        {tag}
      </a>
    </div>
  );
};

export const DocumentForm = (id) => {
  return (
    <div>
      <center>
        <Link
          target="_blank"
          to={`/admin/pendingkycdetails/${id}`}
          style={{ color: purple900 }}
        >
          <RaisedButton label="Enter Details" />

        </Link>
      </center>
    </div>
  );
}

export const DocumentFormPush = (id) => {
  return (
    <div>
      <center>
        <Link
          target="_blank"
          to={`/admin/pendingkycdetails/${id}`}
          style={{ color: purple900 }}
        >
          <RaisedButton label="Enter Details" />

        </Link>
      </center>
    </div>
  );
}


export const ProfileDocumentForm = (id) => {
  return (
    <div>
      <center>
        <Link
          target="_blank"
          to={`/admin/pendingkycdetails/${id}`}
          style={{ color: purple900 }}
        >
          {id}
        </Link>
      </center>
    </div>
  );
}

// Error handling helpers
export const handleError = error => {
  const message = errorMessage(error);
  alert(`Error: ${message}`);
};

export const errorMessage = error => {
  let message = "";
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    message = error.response.data.error.message;
  } else if (error.message) {
    message = error.message;
  }
  return message;
};
