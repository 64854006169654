import React, { Component } from "react";
import { Input, Button, Select } from "antd";
import Card from "../tim-components/Card.jsx";
import axios from "axios";
import { notification } from "antd";
const { TextArea } = Input;

export default class SendIntercomNotification extends Component {
  state = {
    text: "",
    loading: false,
    adminId: "2690512"
  };

  handleTextChange = event => {
    this.setState({
      text: event.target.value
    });
  };

  handleIntercomAdminChange = adminId => {
    this.setState({
      adminId
    });
  };

  handleSendIntercomNotification = async () => {
    const { id: userId } = this.props.user;
    const { text, adminId } = this.state;

    this.setState({
      loading: true
    });

    try {
      await axios.post("/admin/support/intercom", {
        userId,
        text,
        adminId
      });

      notification.success({
        message: "Success!",
        description: "Intercom message sent successfully"
      });

      this.setState({
        text: "",
        loading: false
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: `An error occurred trying to send this sms ${
          error.message
          }`
      });
      this.setState({
        loading: false
      });
    }
  };

  render() {
    const { loading, text, adminId } = this.state;
    return (
      <Card
        title={"Send Intercom Notification"}
        category={"Send a message directly to this user via Intercom."}
        content={
          <div>
            <Select
              defaultValue={adminId}
              placeholder={"Who should send this message?"}
              style={{ width: "100%", margin: 5 }}
              onChange={this.handleIntercomAdminChange}
            >
              <Select.Option key={"christine"} value={"2690512"}>
                {`Christine`}
              </Select.Option>
              <Select.Option key={"caroline"} value={"2929506"}>
                {`Caroline`}
              </Select.Option>
            </Select>
            <br />
            <TextArea
              value={text}
              placeholder="Intercom Message..."
              autosize={{ minRows: 3 }}
              onChange={this.handleTextChange}
              style={{ margin: 5 }}
            />
            <br />
            <Button
              loading={loading}
              style={{ margin: 5 }}
              type={"primary"}
              onClick={this.handleSendIntercomNotification}
            >
              {"Send Intercom Notification"}
            </Button>
          </div>
        }
      />
    );
  }
}
