import React, { Component } from "react";
import "../index.css";
import axios from "axios";
import moment from "moment";
import CircularProgress from "material-ui/CircularProgress";
import IconButton from "material-ui/IconButton";
import ActivityList from "../ActivityList";
import { Dimmer, Loader, Label, Table } from "semantic-ui-react";
import { colorForStatus, NumberFormat } from "../util/colors";
import { CheckoutCell } from "../util/helpers";
import Avatar from "material-ui/Avatar";
import RaisedButton from "material-ui/RaisedButton";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../tim-components/Card.jsx";
import UserCard from "../tim-components/UserCard";
import formattedCurrency from "../util/currencyFormatter";
import MoveMoney from "./MoveMoney";
import { List, notification, Button, Input } from "antd";
import ToggleUserLock from "./ToggleUserLock";
import SendNotification from "./SendNotification";
import ResetPin from "./ResetPin";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import UserVolumes from "./UserVolumes";
import UserPreferences from "./UserPreferences";
import QRCode from "qrcode.react";
import { Segment } from "semantic-ui-react";

const { TextArea } = Input;

// Because it shows beneath the top black bar and needs to offset
notification.config({
  top: 100
});

export default class UserTag extends Component {
  state = {
    text: "",
    loading: false
  };

  handleTextChange = event => {
    this.setState({
      text: event.target.value
    });
  };

  render() {
    const { user } = this.props;
    const { loading, text } = this.state;

    return (
      <Card
        title={"QR & Tag"}
        content={
          <div>
            <div>
              {
                <div>
                  <Segment compact raised>
                    <QRCode size={130} value={`chippercash://pay/${user.id}`} />
                  </Segment>
                  <div>
                    {user.tag && (
                      <p style={{ textAlign: "left" }}>
                        Current tag: {CheckoutCell(`@${user.tag}`)}{" "}
                      </p>
                    )}
                    <Input
                      value={text}
                      placeholder="Tag to transfer..."
                      autosize={{ minRows: 3 }}
                      onChange={this.handleTextChange}
                      style={{ margin: 5 }}
                    />
                    <br />
                    <Button
                      loading={loading}
                      style={{ margin: 5 }}
                      type={"primary"}
                      onClick={() => this.props.transfer(user.id, text)}
                    >
                      {"Transfer Tag"}
                    </Button>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      />
    );
  }
}
