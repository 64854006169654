import React, { Component } from 'react';
import Avatar from 'material-ui/Avatar';
import moment from 'moment';
import FlatButton from 'material-ui/FlatButton';
import formattedCurrency from './util/currencyFormatter'
import { purple900 } from 'material-ui/styles/colors';
import { Card, CardActions, CardHeader, CardTitle, CardText } from 'material-ui/Card';
import LinearProgress from 'material-ui/LinearProgress';
import axios from 'axios';
import Phone, { formatPhoneNumber, parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

const WithdrawalCard = props => {
  let title = props.title;
  let amount = props.amount; // this is already formatted. wth?
  let withdrawal = props.withdrawal; // full withdrawal object
  let carrier = withdrawal.mobile_money_carrier == null ? withdrawal.bank_name : withdrawal.mobile_money_carrier
  let phone = withdrawal.mobile_money_phone == null ? withdrawal.bank_account_number : withdrawal.mobile_money_phone
  let country = withdrawal.mobile_money_country == null ? withdrawal.bank_currency : withdrawal.mobile_money_country
  let updatedAt = props.updatedAt;
  let user = props.user;
  let status = props.status;

  const avatarStyle = { objectFit: 'cover' };
  const formattedPhone = withdrawal.mobile_money_phone == null ? withdrawal.bank_account_number : formatPhoneNumber(parsePhoneNumber(phone, { country }), 'National');

  let description = "";
  if (status === 'SETTLED') {
    description = `Cash out to ${carrier}: ${formattedPhone} is complete`;
  } else {
    description = `Money is on it's way to ${carrier}: ${formattedPhone}. Your cash out request will be processed soon. Hold tight.`;
  }

  return (
    <div>
      <Card style={{ margin: 10 }}>
        <p style={{ textAlign: 'right', float: 'right', margin: 15, fontSize: 15 }}>{amount}</p>
        <CardHeader
          title={title}
          titleStyle={{ marginRight: 65 }}
          subtitle={updatedAt}
          avatar={<Avatar
            src={user.avatar}
            style={avatarStyle}
          />}
        />
        <CardTitle title={description} titleStyle={{ fontSize: 20 }} />
      </Card>
    </div>
  );
};

export default WithdrawalCard;
