import axios from "axios";
import ReactDOM from "react-dom";
// css imports for the phone number input during login
import "react-phone-number-input/rrui.css";
import "react-phone-number-input/style.css";
import injectTapEventPlugin from "react-tap-event-plugin";
import "./index.css";
import routes from "./routes";


injectTapEventPlugin();

// Set up initial defaults on Axios
if (
  window.location.hostname === "web.chippercash.com" ||
  window.location.hostname === "chipper-cash.firebaseapp.com"
) {
  axios.defaults.baseURL = "https://api.chippercash.com/v1";
} else if (
  window.location.hostname === "web-staging.chippercash.com" ||
  window.location.hostname === "chipper-staging.firebaseapp.com"
) {
  axios.defaults.baseURL = "https://staging.chippercash.com/v1";
} else {
  // Do whatever you want.
  axios.defaults.baseURL = "https://api.chippercash.com/v1";
  // axios.defaults.baseURL = "https://staging.chippercash.com/v1";
  // axios.defaults.baseURL = "http://localhost:3002/v1";
  // axios.defaults.baseURL = "https://chipper-core-staging-pr-933.herokuapp.com/v1"
}

// Axios interceptor to refresh an expired token, and send that
// new token to the server
axios.interceptors.response.use(undefined, function (error) {
  const response = error.response;

  console.log("[Firebase Token]: Error: ", response);
  console.dir(error);
  const originalRequest = error.config;

  // Need to return this and keep this promise alive.
  return new Promise((resolve, reject) => {
    window.firebase.auth().onAuthStateChanged(user => {
      if (
        user &&
        response &&
        response.status === 401 &&
        response.data.error.code === "4002" &&
        !originalRequest._retry
      ) {
        const code = response.data.error.code;
        console.log("[Firebase Token] authState user: ", user);
        console.log("[Firebase Token] code", code);
        console.log("[Firebase Token] status", response.status);

        user
          .getIdToken(true)
          .then(token => {
            console.log(
              "[Firebase Token] Firebase Token renewing firebase token with: ",
              token
            );

            originalRequest._retry = true;
            localStorage.setItem("accessToken", token);
            axios.defaults.headers["Authorization"] = token;
            originalRequest.headers["Authorization"] = token;

            resolve(axios(originalRequest));
          })
          .catch(error => {
            console.log("[Firebase Token] Failed to renew token: ", error);
            reject(error);
          });
      } else {
        reject(error);
      }
    });
  });
});

/*
axios.defaults.validateStatus = function (status) {
    return status < 400; // Reject only if the status code is greater than or equal to 400
};
*/

ReactDOM.render(routes, document.getElementById("root"));
