import React, { Component } from 'react';
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import MenuItem from 'material-ui/MenuItem';
import ChipInput from 'material-ui-chip-input'
import formattedCurrency from './util/currencyFormatter'

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { purple900 } from 'material-ui/styles/colors';
import CircularProgress from 'material-ui/CircularProgress';
import Avatar from 'material-ui/Avatar';
import { Icon } from 'react-fa';

import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

import axios from 'axios';

const avatarStyle = {
    objectFit: 'cover'
};

const fieldStyle = {
    hintStyle: {
        color: purple900
    },
    underlineStyle: {
        borderColor: purple900
    },
    floatingLabelFocusStyle: {
        color: purple900
    },
};

class PayRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            input: '',
            selectedPhones: [],

            amount: '',
            currency: '',
            note: '',
            open: false,

            dialogTitle: '',
            dialogDetail: '',
            txComplete: false,

            isProcessing: false,

            recipientId: '',
            confirmTitle: '',
            confirmOpen: false,
            confirmAction: '',

            fetchingSuggestions: true,
            suggestions: [],
            filteredSuggestions: [],
            users: []
        };
    }

    componentDidMount() {
        const currency = localStorage.getItem('currency')
        this.setState({
            currency
        })

        // Kick off the suggestions
        // (lol wut? Wow What do you mean. Maijid your code is shit :))
        this.handleInputChange("");

        // Fetch for all the user's on Chipper.
        // Haxxx - We should use a better way of searching really.
        axios.get('/users')
            .then(response => {
                const users = response.data.users
                this.setState({
                    users,
                    fetchingSuggestions: false
                });
                this.handleInputChange(this.state.input)
            })
            .catch(error => {
                console.error("Getting users error: ", error)
                this.setState({
                    fetchingSuggestions: false
                });
            })
    }

    obfuscated = (formattedPhone) => {
        const numbers = formattedPhone.split(' ')
        const first = numbers[0]
        const last = numbers[numbers.length - 1]
        const obfuscated = `${first} *** *** ${last}`
        return obfuscated
    }

    handleInputChange = (input) => {
        let filtered = [];

        if (input.length < 1) {
            // Do nothing
        } else {
            // Search all users
            filtered = this.state.users.filter(user => {
                if (user.display_name.toLowerCase().indexOf(input.toLowerCase()) !== -1) return true
                if (user.identifier.toLowerCase().indexOf(input.toLowerCase()) !== -1) return true
                return false
            })
        }

        // Create the MenuItems to be shown in the datasource
        const filteredSuggestions = filtered.map(suggestion => {
            let displayIdentifier = suggestion.identifier

            const formattedPhone = formatPhoneNumber(parsePhoneNumber(suggestion.identifier), 'International');
            if (formattedPhone) {
                displayIdentifier = this.obfuscated(formattedPhone)
            }

            return {
                identifier: suggestion.user_id,
                name: `${suggestion.display_name} ${this.getFlag(suggestion.primary_currency)}`,
                value: (
                    <MenuItem
                        style={{ height: 70 }}
                        leftIcon={
                            <Avatar
                                src={suggestion.avatar}
                                size={200}
                                style={avatarStyle}
                            />}>
                        <div>
                            {suggestion.display_name}
                            <p style={{ color: 'gray', marginTop: -10, fontSize: "0.8em" }}>{`${this.getFlag(suggestion.primary_currency)} ${displayIdentifier}`}</p>
                        </div>
                    </MenuItem>
                )
            }
        })

        const loadingSuggestion = {
            identifier: "#loading",
            name: "#loading",
            value: (
                <MenuItem
                    style={{ height: 70 }}>
                    <div>
                        <CircularProgress
                            style={{ marginLeft: 90, marginTop: 15 }}
                            size={40}
                            thickness={1.5}
                            color={purple900} />
                    </div>
                </MenuItem>
            )
        }

        const noUser = {
            identifier: "#nouser",
            name: "#nouser",
            value: (
                <MenuItem>
                    <div style={{ marginLeft: 60, marginTop: 30 }}>
                        <h5 style={{textAlign: 'left'}}>No User Found</h5>
                    </div>
                </MenuItem>
            )
        }

        // If we're still fetching, put in the loadingSuggestions
        const { fetchingSuggestions } = this.state
        if (fetchingSuggestions) {
            filteredSuggestions.push(loadingSuggestion)
        } else if (input.length > 0 && filteredSuggestions.length === 0) {
            filteredSuggestions.push(noUser);
        }

        this.setState({
            filteredSuggestions,
            input
        });
    };

    getFlag(value) {
        if (value === 'UGX') {
            return '🇺🇬'
        } else if (value === 'GHS') {
            return '🇬🇭'
        } else if (value === 'KES') {
            return '🇰🇪'
        }
    }

    handleAmountChange = (event) => {
        this.setState({
            amount: event.target.value,
        });
    };

    handleNoteChange = (event) => {
        this.setState({
            note: event.target.value,
        });
    };

    // Dialog Show/Hide
    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleConfirmClose = () => {
        this.setState({ confirmOpen: false });
    }

    goBack = () => {
        // Go back to the Activity List.
        if (this.state.txComplete) {
            this.props.history.push('/activity');
        }
        this.handleClose();
    }

    handleError = (error, title) => {
        let message
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
            message = error.response.data.error.message
        } else if (error.message) {
            message = error.message
        }

        this.setState({
            dialogTitle: title,
            dialogDetail: `Reason: ${message}`,
            txComplete: false,
            open: true,
            isProcessing: false
        });
    }

    // Pay Tapped
    send = () => {

        this.dismissKeyboard();
        this.setState({
            isProcessing: true
        });

        // Let's make sure we've got an identifier here, else show an
        // instant error
        const selected = this.state.selectedPhones[0];
        console.log("Selected: ", selected)

        if (!selected) {

            // Just test this piece out
            window.notifications.addNotification({
                level: 'error',
                title: 'Cannot complete transaction!',
                message: 'Please pick a recipient',
                position: 'bl'
            });

            this.setState({
                isProcessing: false,
            });

            return;
        }

        if (this.state.amount === '') {

            // Just test this piece out
            window.notifications.addNotification({
                level: 'error',
                title: 'Cannot complete transaction!',
                message: 'Need to input an amount',
                position: 'bl'
            });

            this.setState({
                isProcessing: false
            });

            return;
        }

        const identifier = selected.identifier;

        console.log("Ready to post")
        console.log("Identifier: ", identifier)
        console.log("Amount:", this.state.amount)
        console.log("note: ", this.state.note)
        console.log("Currency: ", this.state.currency)

        let firstName, lastName, userId, recipientCurrency;
    
        axios.get(`/users/${identifier}`)
            .then(response => {
                const user = response.data

                const { first_name, last_name, user_id, primary_currency } = user
                firstName = first_name
                lastName = last_name
                userId = user_id
                recipientCurrency = primary_currency

                const meCurrency = this.state.currency

                return axios.get('/currencies/exchange-rate', {
                    params: {
                        from: meCurrency,
                        to: recipientCurrency
                    }
                })
            })
            .then(response => {
                const rate = response.data

                const origin_currency = this.state.currency
                const destination_currency = recipientCurrency
                const origin_amount = this.state.amount
                const destination_amount = origin_amount * rate

                // TODO: Add the rate to the message
                // ${formattedCurrency(1, destination_currency)} = ${formattedCurrency(1/rate, origin_currency)}
                let title
                if (origin_currency === destination_currency) {
                    title = `Confirm you want to send ${formattedCurrency(origin_amount, origin_currency)} to ${firstName} ${lastName}?`
                } else {
                    title = `Confirm you want to send ${formattedCurrency(origin_amount, origin_currency)} (${formattedCurrency(destination_amount, destination_currency)}) to ${firstName} ${lastName}?`
                }

                this.setState({
                    recipientId: userId,
                    confirmTitle: title,
                    confirmOpen: true,
                    confirmAction: 'pay',
                    isProcessing: false,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
                this.handleError(error, 'Payment Failed')
            })
    }

    finalSend = () => {
        const recipientId = this.state.recipientId
        const note = this.state.note
        const amount = this.state.amount

        this.setState({
            confirmOpen: false,
            confirmTitle: '',
            confirmAction: '',
            isProcessing: true
        })

        axios.post('/payments', {
            recipientId,
            amount,
            note
        })
            .then(response => {
                let data = response.data.data;

                this.setState({
                    dialogTitle: "Transaction completed!",
                    dialogDetail: null,
                    txComplete: true,
                    open: true,
                    isProcessing: false
                });
            })
            .catch(error => {
                const message = error.response.data.error.message;

                // Show an error dialog or something
                this.setState({
                    dialogTitle: "Transaction Failed",
                    dialogDetail: `Reason: ${message}`,
                    txComplete: false,
                    open: true,
                    isProcessing: false
                });

                console.log("Error: ", error.response);
            })
    }

    finalRequest = () => {
        const recipientId = this.state.recipientId
        const note = this.state.note
        const amount = this.state.amount

        this.setState({
            confirmOpen: false,
            confirmTitle: '',
            confirmAction: '',
            isProcessing: true
        })

        axios.post('/requests', {
            recipientId,
            amount,
            note
        })
            .then(response => {
                console.log('Data: ', response.data)

                this.setState({
                    dialogTitle: "Request completed!",
                    dialogDetail: null,
                    txComplete: true,
                    open: true,
                    isProcessing: false
                });
            })
            .catch(error => {
                const message = error.response.data.error.message;
                // Show an error dialog or something
                this.setState({
                    dialogTitle: "Request Failed",
                    dialogDetail: `Reason: ${message}`,
                    txComplete: false,
                    open: true,
                    isProcessing: false
                })

                console.log("Error: ", error.response);
            })
    }

    // Request Tapped
    request = () => {
        this.dismissKeyboard();
        this.setState({
            isProcessing: true
        });

        // Instant error
        const selected = this.state.selectedPhones[0];

        if (!selected) {
            this.setState({
                isProcessing: false
            });

            window.notifications.addNotification({
                level: 'error',
                title: 'Cannot complete transaction!',
                message: 'Please pick a recipient',
                position: 'bl'
            });

            return;
        }

        if (this.state.amount === '') {
            window.notifications.addNotification({
                level: 'error',
                title: 'Cannot complete transaction!',
                message: 'Need to input an amount',
                position: 'bl'
            });
            this.setState({
                isProcessing: false
            });

            return;
        }

        const identifier = selected.identifier;

        axios.get(`/users/${identifier}`)
        .then(response => {
            const amount = this.state.amount
            const currency = this.state.currency
            const { first_name, last_name, user_id } = response.data

            let confirmTitle = `Confirm you want to request ${formattedCurrency(amount, currency)} from ${first_name} ${last_name}?`

            this.setState({
                recipientId: user_id,
                confirmTitle,
                confirmAction: 'request',
                confirmOpen: true,
                isProcessing: false
            })
        })
        .catch(error => {
            console.log("Error: ", error);
            this.handleError(error, 'Request Failed')
        })
    }

    handleChipBlur = (chip) => {
        // For when you tap away from the name/phone field while
        // something is already on screen
        const hasRecipient = this.state.selectedPhones.length > 0;
        if (hasRecipient) {

        } else {
            // We go nothing, Gotta use this on blur
            if (this.state.input.length > 0) {
                const _chip = { identifier: this.state.input, name: this.state.input };
                this.handleAddChip(_chip);
            }
        }

        // Clear out input and reset things
        this.setState({
            input: ''
        });
        this.handleInputChange("")
    }

    handleAddChip(chip) {
        console.log("handle add chip", chip);

        // Depending on how this chip was added,
        // the identifier is always what's sent to
        // the api.

        if (chip.identifier) {
            // We good
            if (chip.identifier === '#loading') {
                return;
            }

        } else {
            // This was probably in the case of adding from keyboard
            chip.identifier = chip.name;
        }

        console.log("Handle add chip [update]: ", chip);

        this.setState({
            selectedPhones: [chip],
            input: chip.identifier
        });
    }

    handleDeleteChip(chip, index) {
        console.log("Chip: ", chip, index);

        // There's only 1. Just delete it.
        this.setState({
            selectedPhones: [],
            input: ""
        });
    }

    dismissKeyboard() {
        this.recipientInput.blur();
        this.amountInput.blur();
        this.noteInput.blur();
    }

    render() {
        const actions = [
            <FlatButton label="OK"
                style={{ color: purple900 }}
                onTouchTap={this.goBack}
            />
        ];

        const payActions = [
            <FlatButton label="No, cancel"
                style={{ color: 'gray' }}
                onTouchTap={this.handleConfirmClose}
            />,
            <FlatButton label="Yes, Send"
                style={{ color: purple900 }}
                onTouchTap={this.finalSend}
            />
        ]

        const requestActions = [
            <FlatButton label="No, cancel"
                style={{ color: 'gray' }}
                onTouchTap={this.handleConfirmClose}
            />,
            <FlatButton label="Yes, Request"
                style={{ color: purple900 }}
                onTouchTap={this.finalRequest}
            />
        ]

        const LoadingIndicator = () => (
            <div>
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );


        return (
            <div style={{ textAlign: 'center' }}>

                <div style={{ paddingTop: '7%' }}>
                    <Icon name="paper-plane" size="2x" style={{ paddingBottom: '1%', color: '#4527A0' }} /><br />
                    <h2 style={{ fontSize: "1.3em", marginLeft: 5, marginRight: 5 }}>Pay or request money from people in Ghana, Kenya & Uganda</h2>
                </div>

                <ChipInput
                    value={this.state.selectedPhones}
                    style={{ textAlign: 'left' }}
                    floatingLabelText="Recipient (Eg. Chessa)"
                    hintText="Enter Name/Phone"
                    openOnFocus={true}
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    onRequestAdd={(chip) => this.handleAddChip(chip)}
                    onRequestDelete={(chip, index) => this.handleDeleteChip(chip)}
                    onBlur={this.handleChipBlur}
                    filter={AutoComplete.noFilter}
                    dataSource={this.state.filteredSuggestions}
                    dataSourceConfig={{ text: 'name', value: 'value' }}
                    onUpdateInput={this.handleInputChange}
                    ref={(c) => this.recipientInput = c}
                />

                <br />

                <TextField
                    hintText="Amount to pay/request for?"
                    inputmode="numeric"
                    type="number"
                    floatingLabelText={`Amount (in ${this.state.currency})`}
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.amount}
                    onChange={this.handleAmountChange}
                    ref={(c) => this.amountInput = c} />

                <br />

                <TextField
                    style={{ textAlign: 'left' }}
                    hintText="Note"
                    floatingLabelText="What's it for?"
                    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                    underlineFocusStyle={fieldStyle.underlineStyle}
                    value={this.state.note}
                    onChange={this.handleNoteChange}
                    multiLine={true}
                    ref={(c) => this.noteInput = c}
                />

                <br />

                <div style={{ paddingTop: '20' }}>
                    <RaisedButton
                        label="Request"
                        labelColor={purple900}
                        onTouchTap={this.request}
                        style={{ width: '180px', borderBottom: '1px solid #4527A0'}}
                        disabled={this.state.isProcessing} />

                    <RaisedButton
                        label="Pay"
                        labelColor={purple900}
                        onTouchTap={this.send}
                        style={{ width: '180px', borderTop: '1px solid #4527A0'}}
                        disabled={this.state.isProcessing} />
                </div>

                <br />

                {this.state.isProcessing ? <LoadingIndicator /> : ""}

                <Dialog
                    title={this.state.dialogTitle}
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}>

                    <p>
                        {this.state.dialogDetail}
                    </p>

                </Dialog>

                <Dialog
                    title={this.state.confirmTitle}
                    actions={this.state.confirmAction == 'pay' ? payActions : requestActions}
                    modal={false}
                    open={this.state.confirmOpen}
                    onRequestClose={this.handleConfirmClose}>
                </Dialog>
            </div>
        );
    }
}

export default PayRequest;

/*
<AutoComplete
    floatingLabelText="Phone number"
    openOnFocus={true}
    filter={AutoComplete.noFilter}
    dataSource={this.state.filteredSuggestions}
    floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
    underlineFocusStyle={fieldStyle.underlineStyle}
    searchText={this.state.phone}
    onUpdateInput={this.handlePhoneChange}
    ref={(c) => this.phoneInput = c} />
*/
