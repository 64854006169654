import React, { Component } from "react";
import axios from "axios";
import { purple900 } from "material-ui/styles/colors";
import CircularProgress from "material-ui/CircularProgress";
import moment from "moment";
import { Label } from "semantic-ui-react";
import { filter, UserCell, NumberCell, DocumentForm } from "../util/helpers";
import ReactTable from "react-table";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../tim-components/Card.jsx";
import "../assets/css/bootstrap.css";
import "../assets/sass/light-bootstrap-dashboard.css";
import "../assets/css/demo.css";
import "../assets/css/pe-icon-7-stroke.css";
import { colorForStatus } from "../util/colors";
import MetaTags from "react-meta-tags";

export default class PendingKYC extends Component {
    state = {
        pendingReviews: [],
        isProcessing: true,
    };

    componentDidMount() {
        this.getPendingDocuments();
    }

    getPendingDocuments = () => {
        this.setState({
            isProcessing: true,
        });
        axios
            .get(`${window.complianceUrl}manual_review/queue?size=10000`)
            .then((response) => {
                console.log("details=> ", response.data.queue);
                const pendingReviews = response.data.queue.map((tx) => {
                    let date;
                    if (moment(tx.submittedAt).isValid()) {
                        date = moment(tx.submittedAt).format("YYYY-MM-DD | HH:mm");
                    } else {
                        date = "-";
                    }
                    return {
                        ownerId: tx.ownerId,
                        owner: tx.owner,
                        issuedBy: tx.issuedBy,
                        documentType: tx.documentType,
                        id: tx.id,
                        status: "PENDING",
                        submittedAt: date,
                    };
                });
                // console.log(pendingReviews)
                this.setState({
                    pendingReviews,
                    isProcessing: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isProcessing: false,
                });
            });
    };

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress size={70} thickness={3} color={purple900} />
            </div>
        );

        const { pendingReviews } = this.state;
        return (
            <div>
                <MetaTags>
                    <title>Chipper Cash | Pending KYC</title>
                    <meta property="og:title" content="Chipper Cash | Withdrawals" />
                </MetaTags>
                <h2 style={{ textAlign: "center" }}> KYC Reviews Pending</h2>
                <div>
                    {this.state.isProcessing ? (
                        <LoadingIndicator />
                    ) : (
                            <div className="main-content">
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            <Card
                                                content={
                                                    <ReactTable
                                                        data={pendingReviews}
                                                        filterable
                                                        columns={[
                                                            {
                                                                Header: "ID",
                                                                accessor: "id",
                                                                width: 40,
                                                            },
                                                            {
                                                                Header: "User ID",
                                                                accessor: "ownerId",
                                                                Cell: (row) => {
                                                                    const id = row.row.ownerId;
                                                                    return UserCell(id, id);
                                                                },
                                                            },
                                                            {
                                                                Header: "User",
                                                                accessor: "owner",
                                                                Cell: (row) => {
                                                                    const name = row.value;
                                                                    return <span>{name}</span>;
                                                                },
                                                            },
                                                            {
                                                                Header: "Type",
                                                                accessor: "documentType",
                                                            },
                                                            {
                                                                Header: "Issuing Country",
                                                                accessor: "issuedBy",
                                                            },
                                                            {
                                                                Header: "Submitted At",
                                                                accessor: "submittedAt",
                                                            },
                                                            {
                                                                Header: "Status",
                                                                accessor: "status",
                                                                width: 155,
                                                                filterable: false,
                                                                Cell: (row) => {
                                                                    const status = row.value;
                                                                    return (
                                                                        <center>
                                                                            <Label
                                                                                color={colorForStatus(status)}
                                                                                size={"mini"}
                                                                            >
                                                                                {status}
                                                                            </Label>
                                                                        </center>
                                                                    );
                                                                },
                                                            },
                                                            {
                                                                Header: "Enter Details",
                                                                accessor: "id",
                                                                filterable: false,
                                                                Cell: (row) => {
                                                                    const id = row.row.id;
                                                                    return DocumentForm(id);
                                                                },
                                                            },
                                                        ]}
                                                        defaultPageSize={12}
                                                        defaultSorted={[
                                                            {
                                                                id: "submittedAt",
                                                                asc: true,
                                                            },
                                                        ]}
                                                        showPaginationTop
                                                        showPaginationBottom
                                                        defaultFilterMethod={filter}
                                                        className="-striped -highlight"
                                                    />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </div>
                        )}
                </div>
            </div>
        );
    }
}
