import React, { Component } from "react";
//import { browserHistory } from 'react-router';
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import { purple900 } from "material-ui/styles/colors";
import Phone, {
  formatPhoneNumber,
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import axios from "axios";
import CircularProgress from "material-ui/CircularProgress";
import Snackbar from "material-ui/Snackbar";
import Divider from "material-ui/Divider";

import api from "./util/api";

const firebase = window.firebase;

let showSignInWithGoogleOption;
if (
  window.location.hostname === "wercash.com" ||
  window.location.hostname === "chipper-staging.firebaseapp.com" ||
  window.location.hostname === "chipper-cash.firebaseapp.com"
) {
  showSignInWithGoogleOption = false;
} else {
  // Show Google option only when we're not in production
  showSignInWithGoogleOption = true;
}

// Authentication global vars
let recaptchaVerifier;
let unsubscribe;

const fieldStyle = {
  hintStyle: {
    color: purple900,
  },
  underlineStyle: {
    borderColor: purple900,
  },
  floatingLabelFocusStyle: {
    color: purple900,
  },
};

const buttonStyle = {
  margin: 12,
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      browserCountry: "",

      phone: "",
      email: "",
      password: "",
      code: "",
      isFetching: false,

      snackOpen: false,
      message: "",

      confirmationResult: null,
    };
  }

  componentWillUnmount() {
    if (unsubscribe) {
      unsubscribe();
    }
  }

  async componentWillMount() {
    // If there's a token already, go straight to the Activity Feed
    const token = localStorage.getItem("accessToken");
    if (token) {
      //this.props.history.push('/activity');
    }

  }
  componentDidMount() {
    this.fetchUserIPDetails();
  }

  fetchUserIPDetails = () => {
    // Simple Endpoint to get a user's browser location.
    // Use this to show the user's country in the phone picker
    axios
      .get("https://ipinfo.io")
      .then((response) => {
        this.setState({
          browserCountry: response.data.country,
        });
      })
      .catch((error) => {
        console.error("Unable to get IP details", error);
      });
  };

  handlePhoneChange = (event) => {
    this.setState({
      phone: event.target.value,
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleCodeChange = (event) => {
    this.setState({
      code: event.target.value,
    });
  };

  handleSnackClose = () => {
    this.setState({
      snackOpen: false,
    });
  };

  sendConfirmationCode = () => {
    this.sendConfirmationCodeChipperAuth();
  };

  sendEmailConfirmationCode = () => {
    this.sendEmailConfirmationCodeChipperAuth();
  };

  sendConfirmationCodeChipperAuth = async () => {
    console.log(`Send via Chipper Auth to ${this.state.phone}`);
    const { phone } = this.state;

    this.setState({
      isFetching: true,
    });

    try {
      console.log("api.authRoutes: ", api.authRoutes.otp);
      await api.authRoutes.otp.sendPhoneOtp(phone);
      this.setState({
        confirmationResult: "not_empty",
        isFetching: false,
      });
    } catch (error) {
      alert(error);
      this.setState({
        isFetching: false,
      });
    }
  };

  sendEmailConfirmationCodeChipperAuth = async () => {
    console.log(`Send via Chipper Auth to ${this.state.email}`);
    const { email } = this.state;

    this.setState({
      isFetching: true,
    });

    try {
      await api.authRoutes.otp.sendEmailOtp(email);
      this.setState({
        confirmationResult: "not_empty",
        isFetching: false,
      });
    } catch (error) {
      alert(error);
      this.setState({
        isFetching: false,
      });
    }
  };

  sendConfirmationCodeFirebase = () => {
    // Continue with authentication
    console.log(
      `Send confirmation code to ${this.state.phone} and ${this.state.password}`
    );

    this.setState({
      isFetching: true,
    });

    firebase
      .auth()
      .signInWithPhoneNumber(this.state.phone, recaptchaVerifier)
      .then((confirmationResult) => {
        this.setState({
          confirmationResult,
        });

        console.log(
          "Confirmation result - Show the form to enter code: ",
          confirmationResult
        );

        this.setState({
          isFetching: false,
        });
      })
      .catch((error) => {
        // TODO: IF this fails, needs to be fixed
        console.error("Error in signInWithPhoneNumber: ", error);

        this.setState({
          isFetching: false,
        });
      });
  };

  isValidConfirmationCodeFormat = (code) => {
    return code.length > 5;
  };

  verifyConfirmationCodeFirebase = () => {
    console.log("Verify the code: ", this.state.code);

    this.state.confirmationResult.confirm(this.state.code).then((result) => {
      console.log("Result from phone auth: ", result);
      const user = result.user;
      console.log("User: ", user);

      this.setState({
        isFetching: false,
      });

      // This is where you post to /otherLogin?
      // To initially save the user - part uno...
      console.log("User: ", user);
      console.log("About to post");
      console.log("User.uid: ", user.uid);
      console.log("User phone: ", user.phoneNumber);

      const token = user["pa"];
      this.completeAuthentication(token);
    });
  };

  verifyConfirmationCodeChipperAuth = async () => {
    const { code } = this.state;
    var identifier = "";
    var type = "";

    if (this.state.phone === "") {
      identifier = this.state.email;
      type = "EMAIL";
    } else if (this.state.email === "") {
      identifier = this.state.phone;
      type = "SMS";
    }

    const response = await api.authRoutes.jwt.getJwt(code, identifier, type);
    const token = response.data.token;

    console.log("Got this token: ", token);
    this.completeAuthentication(token);
  };

  verifyConfirmationCode = () => {
    // Make sure there's a 6 digit code in here,
    // else don't even let the person tap confirm
    this.setState({
      isFetching: true,
    });

    this.dismissKeyboard();

    if (window.authPlatform === "FIREBASE") {
      this.verifyConfirmationCodeFirebase();
    } else {
      this.verifyConfirmationCodeChipperAuth();
    }
  };

  loginWithGoogle = () => {
    console.log("Log in with Google..");
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      login_hint: "user@example.com",
    });
    //          provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log("Result: ", result);
        // This gives you a Google Access Token. You can use it to access the Google API.
        // Should I .. save this token? or what?
        // var token = result.credential.accessToken;
        // console.log("Token from login: ", token);
        var user = result.user;

        // Just take this user object and do it all.
        console.log("User returned from auth popup: ", user);
        this.completeAuthentication(user);
      })
      .catch((error) => {
        console.log("Error doing signInWithPopup: ", error);
      });
  };

  completeAuthentication(token) {
    console.log("Writing Access Token to local storage: ", token);
    localStorage.setItem("accessToken", token);
    axios.defaults.headers["Authorization"] = token;

    return axios
      .get("/users/status/", {
        // nothing
      })
      .then((response) => {
        console.log("Response from GET users/status: ", response.data);

        const data = response.data;

        if (data.code === 1001) {
          console.log("Push to activity");
          this.props.history.push("/activity");
        } else if (data.code === 1003) {
          // Account has not been created yet, move to register page

          alert(
            "Hi there! Looks like you do not have a Chipper account with this number. Please download the iOS or Android app from the App store in order to create a new account. Thank you!"
          );

          this.setState({
            isFetching: false,
          });

          //          this.props.history.push("/register");
        } else {
          // Account has been created. Continue to Activity
          // When does it ever reach here? It should return 1001. So it should never reach here.
        }
      })
      .catch((error) => {
        console.log("Error verifying this code: ", error);
        console.log("Error.code: ", error.code);

        // Why should getting an error here try to push you to Activity
        // when you just got a fucking error?

        let message = error.message;
        if (error.code === "auth/invalid-verification-code") {
          message = "Invalid Verification Code";
        }

        this.setState({
          snackOpen: true,
          message: message,
          isFetching: false,
        });
      });
  }

  dismissKeyboard() {
    if (this.phoneInput) {
      this.phoneInput.blur();
    }

    if (this.codeInput) {
      this.codeInput.blur();
    }
  }

  render() {
    const LoadingIndicator = () => (
      <div>
        <CircularProgress size={70} thickness={3} color={purple900} />
      </div>
    );

    const dividerStyle = {
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 20,
      marginTop: 40,
    };

    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <h3> Welcome to Chipper!</h3>

        {this.state.confirmationResult ? (
          ""
        ) : (
          <div>
            <input
              style={{
                width: 250,
                marginLeft: "auto",
                marginRight: "auto",
                fontSize: "1.3em",
                border: "none",
                borderBottom: "1px solid grey",
              }}
              // country={this.state.browserCountry}
              placeholder="Email"
              value={this.state.email}
              onChange={(event) => this.setState({ email: event.target.value })}
              // ref={c => (this.phoneInput = c)}
            />
            <RaisedButton
              id="loginButton"
              label="Continue"
              style={buttonStyle}
              labelColor={purple900}
              onTouchTap={this.sendEmailConfirmationCode}
              disabled={this.state.isFetching}
            />
            <hr />
            <h4> Enter your mobile number to continue </h4>
            <br />
            <Phone
              style={{
                width: 250,
                marginLeft: "auto",
                marginRight: "auto",
                fontSize: "1.3em",
              }}
              country={this.state.browserCountry}
              placeholder="Phone"
              value={this.state.phone}
              onChange={(phone) => this.setState({ phone })}
              ref={(c) => (this.phoneInput = c)}
            />
          </div>
        )}

        {this.state.confirmationResult ? (
          <div>
            <h4>
              {" "}
              Please enter the code sent to{" "}
              {formatPhoneNumber(
                parsePhoneNumber(this.state.phone),
                "National"
              )}{" "}
            </h4>

            <br />

            <TextField
              hintText="Confirmation Code"
              type="number"
              pattern="\d*"
              inputmode="numeric"
              floatingLabelText="Confirmation Code"
              floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
              underlineFocusStyle={fieldStyle.underlineStyle}
              value={this.state.code}
              onChange={this.handleCodeChange}
              ref={(c) => (this.codeInput = c)}
            />
          </div>
        ) : (
          ""
        )}

        <div>
          <br />
          {this.state.confirmationResult ? (
            <RaisedButton
              label="Next"
              style={buttonStyle}
              labelColor={purple900}
              onTouchTap={this.verifyConfirmationCode}
              disabled={
                this.state.isFetching ||
                !this.isValidConfirmationCodeFormat(this.state.code)
              }
            />
          ) : (
            <div>
              <RaisedButton
                id="loginButton"
                label="Continue"
                style={buttonStyle}
                labelColor={purple900}
                onTouchTap={this.sendConfirmationCode}
                disabled={
                  this.state.isFetching || !isValidPhoneNumber(this.state.phone)
                }
              />
              <br />
            </div>
          )}
          <br />

          {/* {showSignInWithGoogleOption ? (
            <div>
              <Divider style={dividerStyle} />
              OR
              <br />
              <RaisedButton
                label="Sign in with Google"
                style={buttonStyle}
                labelColor={purple900}
                onTouchTap={this.loginWithGoogle}
                disabled={this.state.isFetching}
              />
            </div>
          ) : (
            <div />
          )} */}
        </div>

        <br />

        {this.state.isFetching ? <LoadingIndicator /> : ""}

        <Snackbar
          open={this.state.snackOpen}
          message={this.state.message}
          autoHideDuration={4000}
          onRequestClose={this.handleSnackClose}
        />
      </div>
    );
  }
}

export default Login;

/*

    <TextField
        style={{textAlign: 'left'}}
        hintText="Phone"
        floatingLabelText="Phone"
        floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
        underlineFocusStyle={fieldStyle.underlineStyle}
        value={this.state.phone}
        onChange={this.handlePhoneChange}
        ref={(c) => this.phoneInput = c} />
    <br />

    <TextField
        type='password'
        style={{textAlign: 'left'}}
        hintText="Password"
        floatingLabelText="Password"
        floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
        underlineFocusStyle={fieldStyle.underlineStyle}
        value={this.state.password}
        onChange={this.handlePasswordChange}
        ref={(c) => this.passwordInput = c}/>


        <RaisedButton
            id="login"
            label="Verify"
            style={buttonStyle}
            labelColor={purple900}
            onTouchTap={this.verify}
            />

            <br />
            New to Chipper? <Link to="/register">Register</Link>
*/
