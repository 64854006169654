import React, { Component } from 'react';
import Card from '../tim-components/Card.jsx';
import {
    Grid,
    Row,
    Col
} from "react-bootstrap";
import '../index.css';
import formattedCurrency from '../util/currencyFormatter'
import _ from 'lodash'

const VolumeEntry = (props) => (
    <div>
        <span className="UserVolumeStyle" style={{ color: props.color }}> {formattedCurrency(props.volume, props.currency)}</span>
        <span className="UserVolumeLabel"><br />{props.label}</span>
    </div>
)

export default class UserVolumes extends Component {
    render() {
        const { user, volumes } = this.props
        const userVolumes = volumes[user.primary_currency]

        if (_.isEmpty(userVolumes)) {
            return <div />
        }

        return (
            <div>
                <Card
                    title={'Transaction Volumes'}
                    content={
                        <div>
                            <Grid fluid>
                                <Row>
                                    <Col md={4} >
                                        <VolumeEntry volume={userVolumes.total_credits} currency={this.props.currency} label={"Credit"} color={'#048444'} />
                                    </Col>
                                    <Col md={4} >
                                        <VolumeEntry volume={userVolumes.total_debits} currency={this.props.currency} label={"Debit"} color={'#CD2A34'} />
                                    </Col>
                                    <Col md={4} >
                                        <VolumeEntry volume={userVolumes.absolute_total} currency={this.props.currency} label={"Total"} color={'#404040'} />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                />
            </div>
        )
    }
}