import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import CircularProgress from "material-ui/CircularProgress";
import { Label, Table } from "semantic-ui-react";
import { colorForStatus } from "../util/colors";
import Avatar from "material-ui/Avatar";
import { UserCell, MobileMoneyProfile } from "../util/helpers";
import RaisedButton from "material-ui/RaisedButton";
import { Grid, Row, Col } from "react-bootstrap";
import { notification } from "antd";

import "../index.css";
notification.config({
  top: 100
});

export default class WithdrawalPage extends Component {
  state = {
    withdrawal: [],
    beyonic_id: null,
    beyonic_status: null,
    isFetchingVolume: true
  };

  componentDidMount() {
    this.fetchwithdrawal();
  }

  fetchwithdrawal = () => {
    const { id } = this.props.match.params;
    console.log("withdrawal: " + id);
    axios
      .get(`/admin/support/withdrawals/${id}`)
      .then(response => {
        console.log(response.data);
        this.setState({
          withdrawal: response.data,
          beyonic_id: response.data.details.beyonic_id,
          beyonic_status: response.data.details.state,
          isFetchingVolume: false
        });
      })
      .catch(error => {
        console.error("Error fetching withdrawal ", error);
      });
  };

  retryWithdrawal = tx => {
    const message = `You sure you want to retry this withdrawal to ${
      tx.display_name
      } of ${tx.mobile_money_currency} ${tx.origin_amount}?`;
    const response = window.confirm(message);
    if (response) {
      this.setState({
        isProcessing: true
      });
      const url = `/admin/support/withdrawals/retry/${tx.id}`;
      axios
        .post(url)
        .then(response => {
          alert("This transaction was successfully retried");
          this.fetchwithdrawal();
        })
        .catch(error => {
          this.setState({
            isProcessing: false
          });
          alert(error);
        });
    }
  };

  refundWithdrawal = tx => {
    const message = `You sure you want to refund this withdrawal from ${
      tx.display_name
      } of ${tx.mobile_money_currency} ${
      tx.origin_amount
      }? - The money will be sent back to ${tx.display_name}`;
    const response = window.confirm(message);
    if (response) {
      this.setState({
        isProcessing: true
      });
      const url = `/admin/support/withdrawals/refund/${tx.id}`;
      axios
        .post(url)
        .then(response => {
          alert("This transaction was successfully refunded");
          this.fetchwithdrawal();
        })
        .catch(error => {
          this.setState({
            isProcessing: false
          });
          alert(error);
        });
    }
  };

  markAsSettled = async tx => {
    const message = `Confirm you want to mark this withdrawal as settled? Only do this if this withdrawal has been processed, but it has the wrong state.`;
    const response = window.confirm(message);

    if (response) {
      const url = `/admin/support/withdrawals/mark_settled/${tx.id}`;

      try {
        await axios.post(url);
        notification.success({
          message: "Success!",
          description: "Successfully marked as settled"
        });
        this.fetchwithdrawal();
      } catch (error) {
        notification.error({
          message: "Error",
          description: `An error occurred trying to mark as settled ${
            error.message
            }`
        });
      }
    }
  };

  render() {
    const LoadingIndicator = () => (
      <div className="posCenter">
        <CircularProgress size={70} thickness={3} color="black" />
      </div>
    );

    const withdrawal = this.state.withdrawal;
    console.log("@@ Wid: ", withdrawal);

    return (
      <div style={{ padding: "0px 10%" }}>
        {this.state.isFetchingVolume ? (
          <LoadingIndicator />
        ) : (
            <div>
              <Grid fluid>
                <Row>
                  <h3>WITHDRAWAL ACCOUNT DETAILS</h3>
                  <div style={{ paddingBottom: "40px", paddingLeft: "50px" }}>
                    <Col md={6}>
                      <Avatar
                        src={withdrawal.avatar}
                        size={65}
                        style={{ marginBottom: "25px" }}
                      />
                    </Col>
                    <Col md={6}>
                      <div>
                        <br />
                        <Label
                          className="center"
                          color={colorForStatus(`${withdrawal.status}`)}
                          size={"medium"}
                        >
                          {withdrawal.status}
                        </Label>
                        {/* <span><b>Chipper withdrawal ID:</b> {withdrawal.id} </span> */}
                      </div>
                    </Col>
                  </div>

                  <Col md={12}>
                    <Table fixed basic>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Account ID</Table.HeaderCell>
                          <Table.HeaderCell>Account Name</Table.HeaderCell>
                          <Table.HeaderCell>
                            Chipper Withdrawal ID
                        </Table.HeaderCell>
                          <Table.HeaderCell>Error Message</Table.HeaderCell>
                          <Table.HeaderCell>Retry</Table.HeaderCell>
                          <Table.HeaderCell>Refund</Table.HeaderCell>
                          <Table.HeaderCell>Mark as Settled</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{withdrawal.user_id}</Table.Cell>
                          <Table.Cell>
                            {UserCell(
                              withdrawal.display_name,
                              withdrawal.user_id
                            )}
                          </Table.Cell>
                          <Table.Cell>{withdrawal.id}</Table.Cell>
                          <Table.Cell>{withdrawal.error_message}</Table.Cell>
                          <Table.Cell>
                            <span>
                              {(withdrawal.status === "EXTERNAL_FAILED" ||
                                withdrawal.status === "PAYMENT_SUBMITTED" || withdrawal.status === "PENDING") && (
                                  <RaisedButton
                                    label="Retry"
                                    onTouchTap={() =>
                                      this.retryWithdrawal(withdrawal)
                                    }
                                  />
                                )}
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <span>
                              {(withdrawal.status === "EXTERNAL_FAILED" ||
                                withdrawal.status === "PAYMENT_SUBMITTED" ||
                                withdrawal.status === "WAITING_APPROVAL" ||
                                withdrawal.status === "PENDING") && (
                                  <RaisedButton
                                    label="Refund"
                                    onTouchTap={() =>
                                      this.refundWithdrawal(withdrawal)
                                    }
                                  />
                                )}
                            </span>
                          </Table.Cell>

                          <Table.Cell>
                            <span>
                              {(withdrawal.status === "EXTERNAL_FAILED" ||
                                withdrawal.status === "PAYMENT_SUBMITTED") && (
                                  <RaisedButton
                                    label="Settle"
                                    onTouchTap={() =>
                                      this.markAsSettled(withdrawal)
                                    }
                                  />
                                )}
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Col>
                </Row>
              </Grid>
              <Grid fluid style={{ marginTop: "75px" }}>
                <Row>
                  <h3 style={{ marginBottom: "25px" }}>WITHDRAWAL PAYMENT</h3>
                  <Col md={12}>
                    <Table basic>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Provider</Table.HeaderCell>
                          <Table.HeaderCell>Account Type</Table.HeaderCell>
                          <Table.HeaderCell>Account Number</Table.HeaderCell>
                          <Table.HeaderCell>Amount</Table.HeaderCell>
                          <Table.HeaderCell>Updated At</Table.HeaderCell>
                          <Table.HeaderCell>Provider Link</Table.HeaderCell>
                          <Table.HeaderCell>Provider Status</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>BEYONIC</Table.Cell>
                          <Table.Cell>
                            {withdrawal.linked_account_type}
                          </Table.Cell>
                          <Table.Cell>
                            {MobileMoneyProfile(withdrawal.mobile_money_phone)}
                          </Table.Cell>
                          <Table.Cell>
                            {withdrawal.destination_currency}{" "}
                            {withdrawal.destination_amount}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(withdrawal.updated_at).format(
                              "YYYY-MM-DD | HH:mm"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <a
                              style={{ color: "#5433BF" }}
                              target="_blank"
                              href={`https://app.beyonic.com/items/batchpaymentschedule/read/${
                                this.state.beyonic_id
                                }/`}
                            >
                              {this.state.beyonic_id}
                            </a>
                          </Table.Cell>
                          {this.state.beyonic_status ? (
                            <Table.Cell>
                              {this.state.beyonic_status.toUpperCase()}
                            </Table.Cell>
                          ) : (
                              <Table.Cell>-</Table.Cell>
                            )}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Col>
                </Row>
              </Grid>
            </div>
          )}
      </div>
    );
  }
}
