import React, { Component } from 'react';
import Avatar from 'material-ui/Avatar';
import moment from 'moment';
import FlatButton from 'material-ui/FlatButton';
import currencyFormatter from 'currencyformatter.js';
import { purple900 } from 'material-ui/styles/colors';
import { Card, CardActions, CardHeader, CardTitle, CardText } from 'material-ui/Card';
import LinearProgress from 'material-ui/LinearProgress';
import axios from 'axios';
import Phone, { formatPhoneNumber, parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import formattedCurrency from './util/currencyFormatter'


const DepositCard = props => {
    let title = props.title;
    let amount = props.amount; // this is already formatted. wth?
    let deposit = props.deposit; // full deposit object
    let carrier = deposit.mobile_money_carrier == null ? deposit.card_network : deposit.mobile_money_carrier
    let phone = deposit.mobile_money_phone == null ? deposit.card_last_four : deposit.mobile_money_phone
    let country = deposit.mobile_money_country == null ? deposit.destination_currency : deposit.mobile_money_country

    let updatedAt = props.updatedAt;
    let user = props.user;
    let status = props.status;

    const avatarStyle = { objectFit: 'cover' };
    const formattedPhone = deposit.mobile_money_phone == null ? "···· ···· ···· " + deposit.card_last_four : formatPhoneNumber(parsePhoneNumber(phone, { country }), 'National');

    let description = "";
    if (status === 'SETTLED') {
        description = `Cash in via ${carrier}: ${formattedPhone} completed`;
    } else if (status === 'FAILED') {
        description = `Something went wrong with this cash in request. Perhaps it expired. Hmm.. maybe this card shouldn't be showed to the user?`
    } else if (status === 'WAITING_APPROVAL') {
        description = `Your cash in request from ${carrier}: ${formattedPhone} is waiting for your approval.`;
    }

    return (
        <div>
            <Card style={{ margin: 10 }}>
                <p style={{ textAlign: 'right', float: 'right', margin: 15, fontSize: 15 }}>{amount}</p>
                <CardHeader
                    title={title}
                    titleStyle={{ marginRight: 65 }}
                    subtitle={updatedAt}
                    avatar={<Avatar
                        src={user.avatar}
                        style={avatarStyle}
                    />}
                />
                <CardTitle title={description} titleStyle={{ fontSize: 20 }} />
            </Card>
        </div>
    );
};

export default DepositCard;
