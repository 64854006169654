import React, { Component } from 'react'
import { Grid, Row, Col } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import Card from '../tim-components/Card.jsx';
import '../index.css'

export default class KYCImageRender extends Component {

    state = {
        rotation: 0
    }

    rotate = () => {
        console.log(this.state.rotation)
        let newRotation = this.state.rotation + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotation: newRotation,
        })
    }

    render() {
        const { documentPhoto, proofPhoto } = this.props
        const { rotation } = this.state;
        return (
            <div>
                <Card
                    content={
                        <div>
                            <Grid fluid>
                                <Row>
                                    <Col md={6} lg={6}>
                                        <div>
                                            <h5 style={{ fontWeight: 600 }}>
                                                <Button size={'mini'} style={{ cursor: 'pointer' }} onClick={() => this.rotate()}>[Click To Rotate]</Button> Government Issued ID
                                            </h5>
                                            <a href={documentPhoto} target="_blank"><img alt="KYCImage" src={documentPhoto} width={"100%"} style={{ transform: `rotate(${rotation}deg)` }} /></a>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={6}>
                                        <div>
                                            <h5 style={{ fontWeight: 600 }}>
                                                Selfie & Issued ID
                                    </h5>
                                            <a href={proofPhoto} target="_blank"><img alt="KYCSelfie" src={proofPhoto} width={"100%"} /></a>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                />
            </div>
        )
    }
}