import React, { Component } from 'react';
import axios from 'axios';
import { Loader, Table } from 'semantic-ui-react'
import Card from '../tim-components/Card.jsx';
import { Grid, Row, Col } from 'react-bootstrap';
import { Flag } from 'semantic-ui-react'
import formattedCurrency from '../util/currencyFormatter'
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import { purple900 } from 'material-ui/styles/colors';
import MetaTags from 'react-meta-tags';

const KESUSD_RATE = 0.0097
const GHSUSD_RATE = 0.18
const UGXUSD_RATE = 0.00027
const TZSUSD_RATE = 0.00043
const RWFUSD_RATE = 0.0011
const NGNUSD_RATE = 0.0028
const ZARUSD_RATE = 0.064

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            volume: '',
            chipperBalance: '',
            beyonicBalance: '',
            isFetchingVolume: true,
            isFetchingBalance: true,
            value: 1,
        }
    }

    componentDidMount() {
        this.fetchVolume(1)
        this.fetchBeyonicBalances()
        this.fetchChipperBalances()
    }

    fetchVolume(value) {
        this.setState({
            isFetchingVolume: true
        });

        var volumeWindow;

        if (value === 0) {
            volumeWindow = '';
        } else {
            volumeWindow = value;
        }

        // add volumeWindow as parameter
        axios.get('/admin/support/volumes/' + volumeWindow)
            .then(response => {
                console.log(response)
                let volumes = response.data;

                let volumeGHS = 0
                let volumeUGX = 0
                let volumeKES = 0
                let volumeTZS = 0
                let volumeRWF = 0
                let volumeNGN = 0
                let volumeZAR = 0

                volumes.forEach(element => {
                    const volume = element.volume
                    console.log(volumes);
                    switch (element.currency) {
                        case 'GHS':
                            volumeGHS = volume
                            break;
                        case 'UGX':
                            volumeUGX = volume
                            break;
                        case 'KES':
                            volumeKES = volume
                            break;
                        case 'TZS':
                            volumeTZS = volume
                            break;
                        case 'RWF':
                            volumeRWF = volume
                            break;
                        case 'NGN':
                            volumeNGN = volume
                            break;
                        case 'ZAR':
                            volumeZAR = volume
                            break;
                        default:
                            break;
                    }
                });

                this.setState({
                    isFetchingVolume: false,
                    volumeGHS,
                    volumeUGX,
                    volumeKES,
                    volumeTZS,
                    volumeRWF,
                    volumeNGN,
                    volumeZAR
                });
            })
            .catch(error => {
                console.log("Error: ", error.response)
                this.setState({
                    isFetchingVolume: false
                });
            });
    }

    fetchBeyonicBalances() {
        this.setState({
            isFetchingBalance: true
        });

        axios.get('/admin/support/balances/')
            .then(response => {
                let beyonicBalances = response.data.external;
                console.log(response);
                let beyonicBalanceGHS = 0
                let beyonicBalanceUGX = 0
                let beyonicBalanceKES = 0
                let beyonicBalanceTZS = 0
                let beyonicBalanceRWF = 0
                let beyonicBalanceNGN = 0
                let beyonicBalanceZAR = 0

                beyonicBalances.forEach(element => {
                    console.log(beyonicBalances);
                    const beyonicBalance = element.balance
                    switch (element.currency) {
                        case 'GHS':
                            beyonicBalanceGHS = beyonicBalance
                            break;
                        case 'UGX':
                            beyonicBalanceUGX = beyonicBalance
                            break;
                        case 'KES':
                            beyonicBalanceKES = beyonicBalance
                            break;
                        case 'TZS':
                            beyonicBalanceTZS = beyonicBalance
                            break;
                        case 'RWF':
                            beyonicBalanceRWF = beyonicBalance
                            break;
                        case 'NGN':
                            beyonicBalanceNGN = beyonicBalance
                            break;
                        case 'ZAR':
                            beyonicBalanceZAR = beyonicBalance
                            break;
                        default:
                            break;
                    }
                });

                this.setState({
                    isFetchingVolume: false,
                    beyonicBalanceGHS,
                    beyonicBalanceUGX,
                    beyonicBalanceKES,
                    beyonicBalanceTZS,
                    beyonicBalanceRWF,
                    beyonicBalanceNGN,
                    beyonicBalanceZAR
                });
            })
            .catch(error => {
                console.log("Error: ", error.response)
                this.setState({
                    isFetchingBalance: false
                });
            });
    }

    fetchChipperBalances() {
        this.setState({
            isFetchingBalance: true
        });

        axios.get('/admin/support/balances/')
            .then(response => {
                let chipperBalances = response.data.userTotals;
                console.log(response);
                let chipperBalanceGHS = 0
                let chipperBalanceUGX = 0
                let chipperBalanceKES = 0
                let chipperBalanceTZS = 0
                let chipperBalanceRWF = 0
                let chipperBalanceNGN = 0
                let chipperBalanceZAR = 0

                chipperBalances.forEach(element => {
                    console.log(chipperBalances);
                    const chipperBalance = element.balance
                    switch (element.currency) {
                        case 'GHS':
                            chipperBalanceGHS = chipperBalance
                            break;
                        case 'UGX':
                            chipperBalanceUGX = chipperBalance
                            break;
                        case 'KES':
                            chipperBalanceKES = chipperBalance
                            break;
                        case 'TZS':
                            chipperBalanceTZS = chipperBalance
                            break;
                        case 'RWF':
                            chipperBalanceRWF = chipperBalance
                            break;
                        case 'NGN':
                            chipperBalanceNGN = chipperBalance
                            break;
                        case 'ZAR':
                            chipperBalanceZAR = chipperBalance
                            break;
                        default:
                            break;
                    }
                });

                this.setState({
                    isFetchingBalance: false,
                    chipperBalanceGHS,
                    chipperBalanceUGX,
                    chipperBalanceKES,
                    chipperBalanceTZS,
                    chipperBalanceRWF,
                    chipperBalanceNGN,
                    chipperBalanceZAR
                });
            })
            .catch(error => {
                console.log("Error: ", error.response)
                this.setState({
                    isFetchingVolume: false
                });
            });
    }

    handleChange = (event, index, value) => {
        this.setState({ value });

        switch (value) {
            case 1:
                this.fetchVolume(value)
                break;
            case 7:
                this.fetchVolume(value)
                break;
            case 30:
                this.fetchVolume(value)
                break;
            case 90:
                this.fetchVolume(value)
                break;
            case 364:
                this.fetchVolume(value)
                break;
            case 0:
                this.fetchVolume(value)
                break;
            default:
                break;
        }
    }

    render() {
        const KESUSD = this.state.volumeKES * KESUSD_RATE
        const GHSUSD = this.state.volumeGHS * GHSUSD_RATE
        const UGXUSD = this.state.volumeUGX * UGXUSD_RATE
        const TZSUSD = this.state.volumeTZS * TZSUSD_RATE
        const RWFUSD = this.state.volumeRWF * RWFUSD_RATE
        const NGNUSD = this.state.volumeNGN * NGNUSD_RATE
        const ZARUSD = this.state.volumeZAR * ZARUSD_RATE

        const { beyonicBalanceGHS, beyonicBalanceKES, beyonicBalanceUGX, beyonicBalanceTZS, beyonicBalanceRWF, beyonicBalanceNGN, beyonicBalanceZAR } = this.state
        const totalBeyonicUSD =
            beyonicBalanceGHS * GHSUSD_RATE +
            beyonicBalanceKES * KESUSD_RATE +
            beyonicBalanceRWF * RWFUSD_RATE +
            beyonicBalanceTZS * TZSUSD_RATE +
            beyonicBalanceUGX * UGXUSD_RATE +
            beyonicBalanceNGN * NGNUSD_RATE +
            beyonicBalanceZAR * ZARUSD_RATE

        const { chipperBalanceGHS, chipperBalanceKES, chipperBalanceRWF, chipperBalanceTZS, chipperBalanceUGX, chipperBalanceNGN, chipperBalanceZAR } = this.state
        const totalInWalletChipperUSD =
            chipperBalanceGHS * GHSUSD_RATE +
            chipperBalanceKES * KESUSD_RATE +
            chipperBalanceRWF * RWFUSD_RATE +
            chipperBalanceTZS * TZSUSD_RATE +
            chipperBalanceUGX * UGXUSD_RATE +
            chipperBalanceNGN * NGNUSD_RATE +
            chipperBalanceZAR * ZARUSD_RATE

        return (
            <div style={{ textAlign: 'center' }}>
                <MetaTags>
                    <title>Chipper Cash | Overview</title>
                    <meta property="og:title" content="Chipper Cash | Overview" />
                </MetaTags>
                <h3>Overview</h3>

                <div>
                    <DropDownMenu value={this.state.value} onChange={this.handleChange} selectedMenuItemStyle={{ color: purple900 }}>
                        <MenuItem value={0} primaryText="All Time" />
                        <MenuItem value={364} primaryText="Last Year" />
                        <MenuItem value={90} primaryText="Last 3 Months" />
                        <MenuItem value={30} primaryText="Last Month" />
                        <MenuItem value={7} primaryText="Last Week" />
                        <MenuItem value={1} primaryText="Last 24 Hours" />
                    </DropDownMenu>
                </div>

                <br />
                {this.state.isFetchingVolume ?
                    <Loader active inline='centered' /> :
                    <Grid fluid>
                        <div style={{ marginBottom: 20 }}>Total Volume: {formattedCurrency((UGXUSD + KESUSD + GHSUSD + TZSUSD + RWFUSD + NGNUSD + ZARUSD).toFixed(2), "USD")} (est.)</div>
                        <Row style={{ height: '30%' }}>
                            <Col md={3}>
                                <Card
                                    content={
                                        <div >
                                            <h3 style={{ marginTop: 70, fontWeight: 700 }}>
                                                {formattedCurrency(this.state.volumeUGX, "UGX")}</h3>
                                            <span style={{ marginTop: 70, fontWeight: 700, color: 'grey' }}>{formattedCurrency(UGXUSD.toFixed(2), "USD")}</span>
                                        </div>
                                    }
                                    legend={
                                        <div>
                                            <br />
                                        </div>
                                    }
                                    stats={
                                        <div> <Flag name='ug' />
                                            <label style={{ color: '#ccc' }}>Ugandan Shillings Volume</label></div>
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <Card
                                    content={
                                        <div>
                                            <h3 style={{ marginTop: 70, fontWeight: 700 }}>
                                                {formattedCurrency(this.state.volumeKES, "KES ")}</h3>
                                            <span style={{ marginTop: 70, fontWeight: 700, color: 'grey' }}>{formattedCurrency(KESUSD.toFixed(2), "USD")} </span>
                                        </div>
                                    }
                                    legend={
                                        <div>
                                            <br />
                                        </div>
                                    }
                                    stats={
                                        <div><Flag name='ke' />
                                            <label style={{ color: '#ccc' }}>Kenyan Shillings Volume</label></div>
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <Card
                                    content={
                                        <div>
                                            <h3 style={{ marginTop: 70, fontWeight: 700 }}>
                                                {formattedCurrency(this.state.volumeGHS, "GHS ")}</h3>
                                            <span style={{ marginTop: 70, fontWeight: 700, color: 'grey' }}>{formattedCurrency(GHSUSD.toFixed(2), "USD")}</span>
                                        </div>
                                    }
                                    legend={
                                        <div>
                                            <br />
                                        </div>
                                    }
                                    stats={
                                        <div><Flag name='gh' />
                                            <label style={{ color: '#ccc' }}>Ghanaian Cedi Volume</label></div>
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <Card
                                    content={
                                        <div>
                                            <h3 style={{ marginTop: 70, fontWeight: 700 }}>
                                                {formattedCurrency(this.state.volumeTZS, "TZS ")}</h3>
                                            <span style={{ marginTop: 70, fontWeight: 700, color: 'grey' }}>{formattedCurrency(TZSUSD.toFixed(2), "USD")}</span>
                                        </div>
                                    }
                                    legend={
                                        <div>
                                            <br />
                                        </div>
                                    }
                                    stats={
                                        <div><Flag name='tz' />
                                            <label style={{ color: '#ccc' }}>Tanzanian Shillings Volume</label></div>
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <Card
                                    content={
                                        <div >
                                            <h3 style={{ marginTop: 70, fontWeight: 700 }}>
                                                {formattedCurrency(this.state.volumeRWF, "RWF")}</h3>
                                            <span style={{ marginTop: 70, fontWeight: 700, color: 'grey' }}>{formattedCurrency(RWFUSD.toFixed(2), "USD")}</span>
                                        </div>
                                    }
                                    legend={
                                        <div>
                                            <br />
                                        </div>
                                    }
                                    stats={
                                        <div> <Flag name='rw' />
                                            <label style={{ color: '#ccc' }}>Rwandan Franc Volume</label></div>
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <Card
                                    content={
                                        <div >
                                            <h3 style={{ marginTop: 70, fontWeight: 700 }}>
                                                {formattedCurrency(this.state.volumeNGN, "NGN ")}</h3>
                                            <span style={{ marginTop: 70, fontWeight: 700, color: 'grey' }}>{formattedCurrency(NGNUSD.toFixed(2), "USD")}</span>
                                        </div>
                                    }
                                    legend={
                                        <div>
                                            <br />
                                        </div>
                                    }
                                    stats={
                                        <div> <Flag name='ng' />
                                            <label style={{ color: '#ccc' }}>Nigerian Naira Volume</label></div>
                                    }
                                />
                            </Col>
                            <Col md={3}>
                                <Card
                                    content={
                                        <div >
                                            <h3 style={{ marginTop: 70, fontWeight: 700 }}>
                                                {formattedCurrency(this.state.volumeZAR, "ZAR ")}</h3>
                                            <span style={{ marginTop: 70, fontWeight: 700, color: 'grey' }}>{formattedCurrency(ZARUSD.toFixed(2), "USD")}</span>
                                        </div>
                                    }
                                    legend={
                                        <div>
                                            <br />
                                        </div>
                                    }
                                    stats={
                                        <div> <Flag name='za' />
                                            <label style={{ color: '#ccc' }}>South African Rand Volume</label></div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Grid>
                }


                {this.state.isFetchingBalance ?
                    <Loader active inline='centered' /> :
                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                                <Table fixed>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Country</Table.HeaderCell>
                                            <Table.HeaderCell>Total Aggregator Balance</Table.HeaderCell>
                                            <Table.HeaderCell>Total In-Wallet Chipper Balance</Table.HeaderCell>
                                            <Table.HeaderCell>Outstanding Balance</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Flag name='ug' /> <label style={{ color: '#000' }}>Ugandan Shillings</label> </Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceUGX, "UGX")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.beyonicBalanceUGX * UGXUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.chipperBalanceUGX, "UGX")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.chipperBalanceUGX * UGXUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceUGX - this.state.chipperBalanceUGX, "UGX ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency((this.state.beyonicBalanceUGX - this.state.chipperBalanceUGX) * UGXUSD_RATE)})</i></label></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><Flag name='ke' /> <label style={{ color: '#000' }}>Kenyan Shillings</label> </Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceKES, "KES ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.beyonicBalanceKES * KESUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.chipperBalanceKES, "KES ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.chipperBalanceKES * KESUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceKES - this.state.chipperBalanceKES, "KES ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency((this.state.beyonicBalanceKES - this.state.chipperBalanceKES) * KESUSD_RATE)})</i></label></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><Flag name='gh' /> <label style={{ color: '#000' }}>Ghanaian Cedis</label> </Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceGHS, "GHS ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.beyonicBalanceGHS * GHSUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.chipperBalanceGHS, "GHS ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.chipperBalanceGHS * GHSUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceGHS - this.state.chipperBalanceGHS, "GHS ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency((this.state.beyonicBalanceGHS - this.state.chipperBalanceGHS) * GHSUSD_RATE)})</i></label></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><Flag name='tz' /> <label style={{ color: '#000' }}>Tanzanian Shillings</label> </Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceTZS, "TZS ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.beyonicBalanceTZS * TZSUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.chipperBalanceTZS, "TZS ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.chipperBalanceTZS * TZSUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceTZS - this.state.chipperBalanceTZS, "TZS ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency((this.state.beyonicBalanceTZS - this.state.chipperBalanceTZS) * TZSUSD_RATE)})</i></label></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><Flag name='rw' /> <label style={{ color: '#000' }}>Rwandan Francs</label> </Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceRWF, "RWF")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.beyonicBalanceRWF * RWFUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.chipperBalanceRWF, "RWF")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.chipperBalanceRWF * RWFUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceRWF - this.state.chipperBalanceRWF, "RWF")} <label style={{ color: '#aaa' }}><i>({formattedCurrency((this.state.beyonicBalanceRWF - this.state.chipperBalanceRWF) * RWFUSD_RATE)})</i></label></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><Flag name='ng' /> <label style={{ color: '#000' }}>Nigerian Naira</label> </Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceNGN, "NGN")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.beyonicBalanceNGN * NGNUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.chipperBalanceNGN, "NGN")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.chipperBalanceNGN * NGNUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceNGN - this.state.chipperBalanceNGN, "NGN ")} <label style={{ color: '#aaa' }}><i>({formattedCurrency((this.state.beyonicBalanceNGN - this.state.chipperBalanceNGN) * NGNUSD_RATE)})</i></label></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><Flag name='za' /> <label style={{ color: '#000' }}>South African Rand</label> </Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceZAR, "ZAR")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.beyonicBalanceZAR * ZARUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.chipperBalanceZAR, "ZAR")} <label style={{ color: '#aaa' }}><i>({formattedCurrency(this.state.chipperBalanceZAR * ZARUSD_RATE)})</i></label></Table.Cell>
                                            <Table.Cell>{formattedCurrency(this.state.beyonicBalanceZAR - this.state.chipperBalanceZAR, "ZAR")} <label style={{ color: '#aaa' }}><i>({formattedCurrency((this.state.beyonicBalanceZAR - this.state.chipperBalanceZAR) * ZARUSD_RATE)})</i></label></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell><label style={{ color: '#000' }}>Total Holdings (est.) USD</label> </Table.HeaderCell>
                                            <Table.HeaderCell>{formattedCurrency(totalBeyonicUSD, "USD")}</Table.HeaderCell>
                                            <Table.HeaderCell>{formattedCurrency(totalInWalletChipperUSD, "USD")}</Table.HeaderCell>
                                            <Table.HeaderCell>{formattedCurrency(totalBeyonicUSD - totalInWalletChipperUSD, "USD")}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </Col>
                        </Row>
                    </Grid>
                }
            </div>
        );
    }
}

export default Overview;
