import React, { Component } from "react";
import { Button } from "antd";
import Card from "../tim-components/Card.jsx";
import { notification } from "antd";
import api from "../util/api";
import axios from "axios";

export default class ResetPin extends Component {
  state = {
    loading: false
  };

  handleResetPIN = async () => {
    const { id: userId, display_name: displayName } = this.props.user;
    const message = `Are you sure you want to reset ${displayName}'s PIN?`;
    const response = window.confirm(message);

    if (response) {
      this.setState({
        loading: true
      });

      try {
        const response = await api.authRoutes.admin.resetPin(userId);
        const pin = response.data.pin;

        notification.success({
          message: "Success!",
          description: "PIN has been reset successfully"
        });

        this.setState({
          pin,
          loading: false
        });
      } catch (error) {
        console.log("Error resetting PIN....: ", error.response);
        notification.error({
          message: "Error",
          description: `An error occurred trying to reset this pin ${
            error.message
            }`
        });
        this.setState({
          loading: false
        });
      }
    }
  };

  render() {
    const { loading, pin } = this.state;
    const { user } = this.props;
    const description = `A random PIN will be generated and will be sent via sms to ${
      user.display_name
      } @ ${user.identifier}`;
    return (
      <Card
        title={"Reset PIN"}
        category={description}
        content={
          <div>
            <Button
              loading={loading}
              style={{ margin: 5 }}
              type={"primary"}
              onClick={this.handleResetPIN}
            >
              {"Reset PIN"}
            </Button>
            {pin && <b>New Pin: {pin} </b>}
          </div>
        }
      />
    );
  }
}
