import React, { Component } from "react";
import axios from "axios";
import { trackClick } from "./util/trackClick";

class Invite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectURL: ""
    };
  }

  async componentDidMount() {
    const { code } = this.props.match.params;
    trackClick(code);

    // Super simple referral link via Branch
    const url = `https://chippercash.app.link/referrals?invite_code=${code}&feature=referrals`;

    // Maybe this is bad.
    // And we should use the above instead?
    // const url = `https://chippercash.app.link/invite/${code}` // Pretty simple actually.

    /*
        alert(JSON.stringify(response.data))
        const { url } = response.data
        */

    // It shouldn't matter. You track on your own link. Still use

    // Maybe we generate the link over here?
    // That means we're generating a new one all the time?
    /*
        https://api2.branch.io/v1/url \
  -d '{
  "branch_key": "key_live_kaFuWw8WvY7yn1d9yYiP8gokwqjV0Swt",
  "channel": "facebook",
  "feature": "onboarding",
  "campaign": "new product",
  "stage": "new user",
  "tags": ["one", "two", "three"],
  "data": {
    "$canonical_identifier": "content/123",
    "$og_title": "Title from Deep Link",
    "$og_description": "Description from Deep Link",
    "$og_image_url": "http://www.lorempixel.com/400/400/",
    "$desktop_url": "http://www.example.com",
    "custom_boolean": true,
    "custom_integer": 1243,
    "custom_string": "everything",
    "custom_array": [1,2,3,4,5,6],
    "custom_object": { "random": "dictionary" }
  }
}'
*/

    // Is this the long link then? or what?
    // const dynamicLink = `https://chippercash.app.link/?foo=bar&baz=456&$fallback_url=https%3A%2F%2Fbranch.io%2F`

    //        "data":"{\"phonenumber\":\"9112345678\", \"email\":\"sidrama@gmail.com\", \"referralCode\":\"331852\", \"$android_url\":\"https://play.google.com/store/apps/details?id=com.mobile.ict.cart\", \"$android_deeplink_path\":\"lokacart://\", \"$android_uri_scheme\":\"lokacart://\", \"$android_package_name\":\"com.mobile.ict.cart\"}"

    /*
        Firebase link START
        const deeplink = `https://chipper.cash/invite/${code}`

        // Android parameters
        const apn = `com.chippercash`
        const amv = `1.3`

        // iOS parameters
        const bundleId = 'com.chippercash'
        const appStoreId = '1353631552'
        const urlScheme = 'chippercash'
        const minimumiOSVesion = '1.3'

        const dynamicLink = `https://chippercash.page.link/?link=${deeplink}&apn=${apn}&amv=${amv}&ibi=${bundleId}&isi=${appStoreId}&ius=${urlScheme}&imv=${minimumiOSVesion}&efr=1&ofl=https://chippercash.com`
        */

    setTimeout(() => {
      window.location = url;
    }, 4000);
  }

  async componentWillMount() {
    const { code } = this.props.match.params;

    const response = await axios.get(`/referrals/referrer/${code}`);
    const user = response.data;

    this.setState({
      displayName: user.display_name
    });
  }

  render() {
    const { displayName } = this.state;

    return (
      <div className="posCenter" style={{ textAlign: "center" }}>
        <h2>Congratulations!</h2>
        <h4>Welcome to the Future of African Payments.</h4>
        <br />

        {displayName && (
          <div>
            <h4 style={{ fontWeight: "bold" }}>{displayName}</h4>
            {displayName && (
              <h4>{`sent you an exclusive invite to Chipper Cash™`}</h4>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Invite;
