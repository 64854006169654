import React, { Component } from "react";
import axios from "axios";
import Avatar from "material-ui/Avatar";
import CircularProgress from "material-ui/CircularProgress";
import "./checkout.css";
import "./user_payment_page.css";
import "./App.css";
import logo from "./assets/img/black_transparent_logo.png";
import phoneIcon from "./assets/img/phone.png";
import boxlogo from "./assets/img/profile_picture_symbol_inverse.png";
import backArrow from "./assets/img/backArrow.png";
import verifiedIconPurple from "./assets/img/check-purple.png";
import verifiedIconWhite from "./assets/img/check-white.png";
import QRCode from "qrcode.react";
import Phone from "react-phone-number-input";
import { Spin, Icon } from "antd";
import { Flag } from "semantic-ui-react";
import { Divider, Segment, Message } from "semantic-ui-react";
import Intercom from "react-intercom";
import MetaTags from "react-meta-tags";
import { VerifyLogo } from "./assets/img/verified.png";
import { Image } from "semantic-ui-react";
import $ from "jquery";
import amplitude from 'amplitude-js';

const supportedCountries = ["GH", "KE", "RW", "TZ", "UG"];

const antIcon = (
  <Icon type="sync" style={{ fontSize: 40, color: "#ffffff" }} spin />
);

class UserPaymentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: true,
      user_id: null,
      avatar: null,
      display_name: null,
      first_name: null,
      last_name: null,
      primary_currency: null,
      tag: null,
      found: false,
      value: 1,
      browserCountry: "",
      country: "",
      amount: "",
      note: "",
      phone: "",
      amountValue: null,
      currency: null,
      visible: false,
      charge_id: null,
      timer: 0,
      phoneFocused: false,
      isSuccess: false,
      isError: false,
      processingPayment: false,
      status: "",
      statusState: null,
      appearForm: true,
      paymentComplete: false,
      isVerified: null,
    };
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
    } else {
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY_STAGING);
    }
    this.detectMobile()
    var passedTag;
    const { tag } = this.props.match.params;

    if (tag.substring(0, 1) == "@") {
      passedTag = tag.substring(1);
    } else {
      passedTag = tag;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const evnetProperties = {
      'Referrer': urlParams.get('referrer') || urlParams.get('ref')
    }

    amplitude.getInstance().logEvent("[Web] UserPaymentPage - Landed", evnetProperties);

    await this.getTag(passedTag);
    // this.navigateIfMobile();

    this.setState({
      tagName: passedTag,
      searchQuery: window.location.search
    });
  }

  detectMobile() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    this.setState({
      isMobile
    })
  }

  navigateIfMobile = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const userId = this.state.user_id;
    const searchQuery = this.state.searchQuery;
    const redirectUrl = `https://chippercash.app.link/pay/${userId}${searchQuery}`;

    console.log("User Agent: ", userAgent, redirectUrl);

    if (/android/i.test(userAgent)) {
      window.location.href = redirectUrl;
      alert("Android redirecting");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      $("<a></a>").attr("href", redirectUrl)[0].click();
    }
  };

  getTag = async (tagName) => {
    await axios
      .get(`/public/tag/${tagName}`)
      .then((response) => {
        this.setState({
          found: true,
          user_id: response.data.id,
          avatar: response.data.avatar,
          display_name: response.data.display_name,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          primary_currency: response.data.primary_currency,
          tag: response.data.tag,
          isProcessing: false,
        });
        this.isVerified();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          found: false,
          isProcessing: false,
        });
        this.isVerified();
      });
  };

  isVerified = () => {
    axios
      .get(`/public/verified/${this.state.user_id}`)
      .then((response) => {
        // console.log(response)
        // console.log(this.state.user_id)
        this.setState({
          isVerified: response.data.verified,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isVerified: false,
        });
      });
  };

  renderUserInfo() {
    const { avatar, user_id: userId, isMobile } = this.state
    const searchQuery = this.state.searchQuery;
    const chipperUrl = `https://chippercash.app.link/pay/${userId}${searchQuery}`;

    return (
      <div className="user-info-container">
        <div className="avatar-container">
          <center>
            <Avatar
              src={avatar}
              size={100}
              style={{
                marginBottom: 20
              }}
            />
          </center>
        </div>

        <p className="user-name" >
          {this.state.display_name + " "}
          {this.state.isVerified === true ? (
            <img className="purple-verify-icon" height={18} src={verifiedIconWhite} />
          ) : (
            <span></span>
          )}
        </p>
        <p className="user-tag">
          @{this.state.tag}
        </p>


        <div className="qr-container">
          <div id="QRCode">
            <center>
              <span>
                <div>
                  <QRCode
                    size={300}
                    value={chipperUrl}
                  />
                </div>
              </span>
            </center>
          </div>
        </div>


        <div className="pay-button-container">
          <center>
            <a
              className="pay-button"
              href={chipperUrl}
            >
              {`Pay ${this.state.first_name} with Chipper`}
            </a>
          </center>
        </div>

        <p className="scan-cta">
          Scan to pay with Chipper
        </p>
      </div>
    )
  }

  renderNotFound() {
    let fetchedTag = this.props.match.params.tag;
    const { isMobile } = this.state

    if (fetchedTag.substring(0, 1) == "@") {
      fetchedTag = fetchedTag.substring(1);
    } else {
      fetchedTag = fetchedTag;
    }

    return (
      <div className="not-found-container">
        {!isMobile && (
          <Avatar
            src={boxlogo}
            size={80}
            style={{
            }}
          />
        )}

        <p className="not-found-heading">Uh oh!</p>

        <p className="tag-not-found">
          @{fetchedTag} not found.
        </p>

        <p className="tag-not-found-explainer">
          You may have a wrong link or @chippertag. Please confirm
          with the intended recipient.
        </p>

      </div>
    )
  }

  renderLoadingState() {
    const { isMobile } = this.state
    const LoadingIndicator = () => (
      <div className="loading-container">
        <CircularProgress size={70} thickness={3} color={'white'} />
      </div>
    );

    return <LoadingIndicator />
  }

  renderCardContent() {
    const { isProcessing, isVerified, found } = this.state
    if (isProcessing) return this.renderLoadingState()

    if (found) return this.renderUserInfo()
    else return this.renderNotFound()
  }

  render() {
    const { display_name: userName } = this.state
    const pageTitle = userName ? `Pay ${userName} on Chipper` : 'Pay with Chipper'

    return (
      <div className="user-payment-page">
        <MetaTags>
          <title>{pageTitle}</title>
          <meta
            property="og:description"
            content={`Send a payment to ${this.state.display_name} from your mobile money number in our supported countries.`}
          />
          <meta
            property="og:title"
            content={`Pay ${this.state.display_name} - Chipper Cash`}
          />
        </MetaTags>

        <div className="centered-container">

          <div className="brand">
            <img height={40} src="https://uploads-ssl.webflow.com/63c81b0c3ad929013f062d70/65d392b85c5b3f9e4140aeba_logo-chipper--white-color.svg" />
          </div>

          <div className="card page-card user-info">
            {this.renderCardContent()}
          </div>

          <div className="links-container">
            <a href="https://chippercash.com/" className="get-the-app">
              <img width="20" src={phoneIcon} />
              Get the App
            </a>
          </div>

        </div>
      </div>
    );
  }
}

export default UserPaymentPage;
