
import React, { Component } from 'react';
import { Input, InputNumber, Button } from 'antd'
import Card from '../tim-components/Card.jsx';
const { TextArea } = Input

export default class AdminMoveMoney extends Component {
    state = {
        amount: '',
        note: ''
    }

    handleAmountChange = (value) => {
        this.setState({
            amount: value,
        });
    };

    handleNoteChange = (event) => {
        this.setState({
            note: event.target.value,
        });
    };

    handleAction = (event) => {
        const { onAction } = this.props
        const { amount, note } = this.state
        onAction(amount, note)
    }

    render() {
        const { currency, title, send } = this.props
        const { amount } = this.state

        const buttonTitle = send ? 'Send' : 'Deduct'
        const buttonType = send ? 'primary' : 'danger'

        return (
            <Card
            title={title}
            content={
                <div>
                    <InputNumber
                    style={{width: '100%', margin: 5}}
                    placeholder='Amount...'
                    size={'large'}
                    defaultValue={amount}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={this.handleAmountChange}
                    />
                    <br />
                    <TextArea placeholder="Note..." autosize={{ minRows: 3 }} onChange={this.handleNoteChange} style={{ margin: 5 }}/>
                    <br />
                    <Button style={{ margin: 5 }} type={buttonType} onClick={this.handleAction}>{`${buttonTitle} ${currency}`}</Button>
                </div>
                }
            />
        )
    }
}

