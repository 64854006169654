import React, { Component } from 'react';
import { purple900 } from 'material-ui/styles/colors';
import axios from 'axios';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';
import { Dimmer, Loader } from 'semantic-ui-react';

import FileUploader from 'react-firebase-file-uploader';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';

import Avatar from 'material-ui/Avatar';
import LinearProgress from 'material-ui/LinearProgress';

import Card from './tim-components/Card.jsx';
import UserCard from './tim-components/UserCard.jsx';

import {
    FormGroup, ControlLabel, FormControl,
    Grid, Row, Col
} from 'react-bootstrap';

const buttonStyle = {
    margin: 12
};

const avatarStyle = { objectFit: 'cover' };

const firebase = window.firebase;

const fieldStyle = {
    hintStyle: {
        color: purple900
    },
    underlineStyle: {
        borderColor: purple900
    },
    floatingLabelFocusStyle: {
        color: purple900
    },
};

class EditProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            first: '',
            last: '',
            username: '',
            avatar: '',
            uid: '',
            isProcessing: false,
            snackOpen: false,
            message: '',
            isUploading: false,
            progress: 0,
            avatarURL: '',

        };
    }

    componentWillMount() {

        this.setState({
            isProcessing: true
        });

        axios.get('/users/me')
            .then(response => {
                const me = response.data

                this.setState({
                    first: me.first_name,
                    last: me.last_name,
                    avatar: me.avatar,
                    uid: me.id,
                    isProcessing: false
                })
            })
            .catch(error => {
                console.error("Error getting me: ", error)
                this.setState({
                    isProcessing: false
                });
            })
    }

    handleFirstChange = (event) => {
        this.setState({
            first: event.target.value,
        });
    };

    handleLastChange = (event) => {
        this.setState({
            last: event.target.value,
        });
    };

    handleUsernameChange = (event) => {
        this.setState({
            username: event.target.value,
        });
    };

    handleAvatarChange = (event) => {
        this.setState({
            avatar: event.target.value,
        });
    };

    updateProfile = () => {
        this.dismissKeyboard();

        this.setState({
            isProcessing: true
        });

        axios.put('/users/me', {
            firstName: this.state.first,
            lastName: this.state.last,
            avatar: this.state.avatar
        })
            .then(response => {
                window.refreshUser()
                this.setState({
                    isProcessing: false,
                    snackOpen: true,
                    message: "Profile updated Successfully!"
                });
            })
            .catch(error => {
                this.setState({
                    isProcessing: false
                });
                const message = `Error updating profile: ${error}`
                window.notifications.addNotification({
                    level: 'error',
                    message,
                    position: 'bc'
                });

                console.log("Error updating the user: ", error);
            });
    }

    handleSnackClose = () => {
        this.setState({
            snackOpen: false,
        });
    };

    dismissKeyboard() {
        this.firstInput.blur();
        this.lastInput.blur();
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = (progress) => this.setState({ progress });
    handleUploadError = (error) => {
        this.setState({ isUploading: false });
        console.error(error);
    }
    handleUploadSuccess = (filename) => {
        this.setState({ avatar: filename, progress: 100, isUploading: false });

        firebase.storage().ref('images').child(filename).getDownloadURL()
            .then(url => {
                this.setState({
                    avatarURL: url,
                    avatar: url
                })

                this.updateProfile()
            });
    };

    render() {
        const LoadingIndicator = () => (
            <div>
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ marginTop: 50, marginLeft: '5%', marginRight: '5%' }}>
                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                                <Card
                                    content={
                                        <div>
                                            {this.state.avatar &&
                                                <div>
                                                    <center style={{ paddingTop: 50 }}>
                                                        <label style={{ paddingTop: 0, paddingBottom: 0, cursor: 'pointer' }}>
                                                            <Avatar
                                                                src={this.state.avatar}
                                                                size={100}
                                                                style={avatarStyle}
                                                            />
                                                            <p style={{ fontSize: '1em', color: '#452345', paddingLeft: 5, cursor: 'pointer', paddingTop: 15 }}>Edit Profile Picture </p>
                                                            <FileUploader
                                                                hidden
                                                                accept="image/*"
                                                                name="avatar"
                                                                randomizeFilename
                                                                storageRef={firebase.storage().ref('images')}
                                                                onUploadStart={this.handleUploadStart}
                                                                onUploadError={this.handleUploadError}
                                                                onUploadSuccess={this.handleUploadSuccess}
                                                                onProgress={this.handleProgress}
                                                            />
                                                        </label>
                                                        <hr />
                                                        <br />
                                                    </center>
                                                </div>
                                            }
                                            <br />
                                            <TextField
                                                hintText="First Name"
                                                floatingLabelText="First Name"
                                                floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                                                underlineFocusStyle={fieldStyle.underlineStyle}
                                                value={this.state.first}
                                                onChange={this.handleFirstChange}
                                                ref={(c) => this.firstInput = c} />

                                            <br />

                                            <TextField
                                                hintText="Last Name"
                                                floatingLabelText="Last Name"
                                                floatingLabelFocusStyle={fieldStyle.floatingLabelFocusStyle}
                                                underlineFocusStyle={fieldStyle.underlineStyle}
                                                value={this.state.last}
                                                onChange={this.handleLastChange}
                                                ref={(c) => this.lastInput = c} />

                                            <br /><br />
                                            <div>
                                                <RaisedButton
                                                    label="Save"
                                                    style={buttonStyle}
                                                    labelColor={purple900}
                                                    onTouchTap={this.updateProfile}
                                                    disabled={this.state.isProcessing} />
                                            </div>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                    <Dimmer active={this.state.isProcessing} inverted page>
                        <Loader></Loader>
                    </Dimmer>
                </Dimmer.Dimmable>

                <Snackbar
                    open={this.state.snackOpen}
                    message={this.state.message}
                    autoHideDuration={4000}
                    onRequestClose={this.handleSnackClose}
                />
            </div>
        );
    }
}

export default EditProfile;
