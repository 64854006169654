import React, { Component } from "react";
import axios from "axios";
import { Table } from "semantic-ui-react";
import { Grid, Row, Col } from "react-bootstrap";
import moment from "moment";
import BlackLogo from "./assets/img/black_logo_transparent.png"

export default class AccountStatement extends Component {
  state = {
    name: "",
    items: [],
    currentTime: null
  };

  async componentDidMount() {
    const { userId, token, year, month } = this.props.match.params;
    // Client forwards whatever Authorization Token they have directly in the url
    const instance = axios.create({
      baseURL: "https://api.chippercash.com/v1",
      timeout: 10000,
      headers: { Authorization: token }
    });

    let response;

    // If a year and month was passed in then, we should query for the year/month endpoint
    if (parseInt(year) && parseInt(month)) {
      response = await instance.get(`/users/statement/${year}/${month}`);
    } else {
      // Get the current
      response = await instance.get("/users/statement/current");
    }

    const { currency, starting_balance, end_balance, items } = response.data;
    console.log(response.data)
    const me = await instance.get("/users/me");

    let monthName;
    switch (month) {
      case "1":
        monthName = "January"
        break;
      case "2":
        monthName = "February"
        break;
      case "3":
        monthName = "March"
        break;
      case "4":
        monthName = "April"
        break;
      case "5":
        monthName = "May"
        break;
      case "6":
        monthName = "June"
        break;
      case "7":
        monthName = "July"
        break;
      case "8":
        monthName = "August"
        break;
      case "9":
        monthName = "September"
        break;
      case "10":
        monthName = "October"
        break;
      case "11":
        monthName = "November"
        break;
      case "12":
        monthName = "December"
        break;
      default:
        monthName = "-"
    }

    var currentTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    console.log(currentTime)

    this.setState({
      name: me.data.display_name,
      userId,
      currency,
      starting_balance,
      end_balance,
      month: monthName,
      year,
      items,
      currentTime
    });
  }

  render() {
    const { userId, name, starting_balance, end_balance, currency, items, month, year, currentTime } = this.state;

    if (items.length === 0) {
      return <div />;
    }

    return (
      <div style={{ padding: "50px 100px", color: "black" }}>
        <Grid fluid>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h3><b>Customer Account Statement</b></h3>
            <p><i>The information contained in this document is strictly private and confidential</i></p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "center" }}>
            <div style={{ textAlign: "right" }}>
              <span><img src={BlackLogo} width={172} /></span>
              <br />
              <i style={{ fontSize: "0.7em" }}>{currentTime}</i>
            </div>
          </Col>
        </Grid>

        <Grid fluid style={{}}>
          <Table fixed style={{ padding: "20px 0px" }}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <p style={{ color: "white", backgroundColor: "#39038E", fontSize: '1.0em', padding: '10px', fontWeight: 600 }}>Customer Details</p>
              <span><b>Customer Identification:</b> {userId}</span><br />
              <span><b>Customer Name:</b> {name}</span><br />
              <span><b>Transaction Count:</b> {items.length} entries</span><br />
              <span><b>Statement Period:</b> {month} {year}</span><br />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <p style={{ color: "white", backgroundColor: "#39038E", fontSize: '1.0em', padding: '10px', fontWeight: 600 }}>Statement Details</p>
              <span><b>Starting Balance:</b> <br />{currency} {starting_balance}</span><br />
              <span><b>Ending Balance:</b> <br />{currency} {end_balance}</span><br />
            </Col>
          </Table>
        </Grid>

        <br />

        <Grid fluid>
          <Table striped fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ backgroundColor: "#39038E", color: "white" }} >Type</Table.HeaderCell>
                <Table.HeaderCell style={{ backgroundColor: "#39038E", color: "white" }} >Transacted Party</Table.HeaderCell>
                <Table.HeaderCell style={{ backgroundColor: "#39038E", color: "white" }} >Note</Table.HeaderCell>
                <Table.HeaderCell style={{ backgroundColor: "#39038E", color: "white" }} >Date</Table.HeaderCell>
                <Table.HeaderCell style={{ backgroundColor: "#39038E", color: "white" }} >Amount</Table.HeaderCell>
                <Table.HeaderCell style={{ backgroundColor: "#39038E", color: "white" }} >Ending Balance</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell style={{ textTransform: 'capitalize' }}>{item.type}</Table.Cell>
                  <Table.Cell>{item.party}</Table.Cell>
                  <Table.Cell>{item.note}</Table.Cell>
                  <Table.Cell>
                    {moment(item.updated_at).format(
                      "dddd, MMMM Do YYYY, h:mm a"
                    )}
                  </Table.Cell>
                  <Table.Cell>{item.amount}</Table.Cell>
                  <Table.Cell>{item.running_balance}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid>
        <div style={{ marginBottom: "200px" }}>
          <br />
          <hr style={{ border: "5px solid black", color: "black" }} />
          <br />
          <Col xs={12} sm={12} md={6} lg={6}>
            <div style={{ textAlign: "left" }}>
              <h5 style={{ fontWeight: 600 }}>For disputes and inaccuracies</h5>
              <span style={{ fontSize: '0.8em', fontWeight: 600 }}>
                <br />
                [1] You reserve the right to raise any inaccuracies you discover with this customer statement.
                In the event where you have a dispute that you would like to raise against, write an email to
                disputes@chippercash.com. This email should include your
                Your name,
                Account identificatioon information (Your email and phone number),
                The equivalent amount of the dispute and the date of the transaction,
                Additional information from your bank or mobile money operator,
                Additional prooof you have to identify and elaborate on the dispute.
            <br />
                [2] We understand the complications that arise as a result of inaccuracies. Disputes can take
                between 2 days and a month to resolve depending on the nature of the dispute raised. Please afford
                the dispute resolution team ample time to investigate, and identify the source.
          </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            {/* <h5 style={{ fontWeight: 600 }}>General policy statements will go here </h5> */}
          </Col>
        </div>

      </div>
    );
  }
}
