import axios from 'axios';
import CircularProgress from 'material-ui/CircularProgress';
import RaisedButton from 'material-ui/RaisedButton';
import { purple900 } from 'material-ui/styles/colors';
import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import ReactTable from "react-table";
import '../assets/css/bootstrap.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import Card from '../tim-components/Card.jsx';
import { filter } from '../util/helpers';
import WhitelistedStocksManualAdd from './WhitelistedStocksManualAdd';

export default class AvailableStocks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stocks: [],
            rawStocks: [],
            isProcessing: false,
        };
    }

    componentDidMount() {
        this.fetchAllWhitelistedStocks();
    }

    fetchAllWhitelistedStocks = () => {
        this.setState({ isProcessing: true });
        axios.get('/admin/support/whitelisted_stocks')
            .then(response => {
                const stocks = response.data.map((stock, index) => {

                    return {
                        symbol: stock,
                        actions: (
                            <div>
                                <RaisedButton
                                    label="Delete"
                                    onTouchTap={() => {
                                        const response = window.confirm(
                                            `You sure you want delete ${stock} ?`
                                        );

                                        if (response) {
                                            const newStocksList = [...this.state.rawStocks]
                                            newStocksList.splice(index, 1)
                                            this.updateStockList(newStocksList)
                                        }
                                    }}
                                />
                            </div>
                        )
                    }
                });

                this.setState({
                    stocks,
                    rawStocks: response.data,
                    isProcessing: false
                });
            })
            .catch(error => {
                console.error("Error: ", error);
                this.setState({
                    isProcessing: false
                });
            });
    }

    handleManualAddAction = (newSymbol) => {
        this.updateStockList([...new Set([...this.state.rawStocks, newSymbol])])
    }

    updateStockList = (whitelistedStocks) => {
        this.setState({ isProcessing: true });
        axios.put('/admin/support/whitelisted_stocks', { whitelistedStocks })
            .then(() => {
                alert("List updated successfully!")
                this.fetchAllWhitelistedStocks()
            })
            .catch(error => {
                console.log('Error: ', error)
                alert(`Symbol addition failed: ${JSON.stringify(error.response.data)}`)
                this.setState({ isProcessing: false });
            })
    }

    render() {
        const LoadingIndicator = () => (
            <div className="posCenter">
                <CircularProgress
                    size={70}
                    thickness={3}
                    color={purple900} />
            </div>
        );
        const { stocks } = this.state

        return (
            <div>
                <h2 style={{ textAlign: 'center' }}> All Whitelisted Stocks ({stocks.length}) </h2>


                <div style={{ width: 500 }}>
                    <WhitelistedStocksManualAdd onAction={this.handleManualAddAction} />
                </div>


                <br /><br /><br />
                <div>
                    {this.state.isProcessing && stocks.length === 0 ? <LoadingIndicator /> :
                        <div className="main-content">
                            <Grid fluid>
                                <Row>
                                    <Col md={12}>
                                        <Card
                                            content={
                                                <ReactTable
                                                    data={stocks}
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: "Symbol",
                                                            accessor: "symbol",
                                                            width: 140
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    defaultPageSize={100}
                                                    defaultSorted={[
                                                        {
                                                            id: "symbol",
                                                            desc: false
                                                        }
                                                    ]}
                                                    showPaginationTop
                                                    showPaginationBottom
                                                    defaultFilterMethod={filter}
                                                    className="-striped -highlight"
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
