import React, { Component } from 'react'
import Card from '../tim-components/Card.jsx';
import { Button, Form, Message, Icon } from 'semantic-ui-react'
import { DatePicker, Select } from 'antd';
import { Grid, Row, Col } from "react-bootstrap";

const { Option } = Select;

export default class KYCFormRender extends Component {
    render() {
        const { dateOfBirth, documentType, expiresOn, firstName, lastName, middleName, issuedBy, issuedOn, documentNumber, submittedAt, handleFirstname, handleLastname, handleMiddlename, handleDob, handleIssuedon, handleExpireson, handleIssuedby, handleDocType, handledocNum, handleUpdate, handleSubmitToSmile, handleVerify, handleReject, isRejecting, isSubmiting, isVerifying, isUpdating, hideVerificationButtons } = this.props

        return (
            <div>
                <Card
                    content={
                        <div>
                            <div>
                                <Message color='yellow'><Icon name='warning sign' />Please carefully look through the ID documents for valid ID numbers.</Message>
                                <br />
                            </div>
                            <Form size={'mini'}>
                                <Form.Field>
                                    <label>First Name</label>
                                    <input onChange={(event) => handleFirstname(event.target.value)} placeholder='First Name' />
                                </Form.Field>
                                <Form.Field>
                                    <label>Last Name</label>
                                    <input onChange={(event) => handleLastname(event.target.value)} placeholder='Last Name' />
                                </Form.Field>
                                <Form.Field>
                                    <label>Middle Name(s)</label>
                                    <input onChange={(event) => handleMiddlename(event.target.value)} placeholder='Middle Name' />
                                </Form.Field>
                                <Form.Field>
                                    <label>Date Of Birth</label>
                                    <DatePicker format="DD-MM-YYYY" value={dateOfBirth} onChange={(value) => handleDob(value)} size={"medium"} />
                                </Form.Field>
                                <Form.Field style={{ display: 'none' }}>
                                    <label>Issued On</label>
                                    <DatePicker format="DD-MM-YYYY" value={issuedOn} onChange={(value) => handleIssuedon(value)} size={"medium"} />
                                </Form.Field>
                                <Form.Field style={{ display: 'none' }}>
                                    <label>Expires On</label>
                                    <DatePicker format="DD-MM-YYYY" value={expiresOn} onChange={(value) => handleExpireson(value)} size={"medium"} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Issuing Country</label>
                                    <Select onChange={(value) => handleIssuedby(value)} value={issuedBy}>
                                        <Option value="nil" disabled>Please select the issuing country</Option>
                                        <Option value="GH">🇬🇭 Ghana</Option>
                                        <Option value="KE">🇰🇪 Kenya</Option>
                                        <Option value="NG">🇳🇬 Nigeria</Option>
                                        <Option value="RW">🇷🇼 Rwanda</Option>
                                        <Option value="ZA">🇿🇦 South Africa</Option>
                                        <Option value="TZ">🇹🇿 Tanzania</Option>
                                        <Option value="UG">🇺🇬 Uganda</Option>
                                        <Option value="GB">🇬🇧 United Kingdom</Option>
                                    </Select>
                                </Form.Field>
                                <Form.Field>
                                    <label>Document Type</label>
                                    <Select onChange={(value) => handleDocType(value)} value={documentType}>
                                        <Option value="" disabled>Please select the document type</Option>
                                        <Option value="DRIVERS_LICENSE">Drivers License</Option>
                                        <Option value="PASSPORT">Passport</Option>
                                        <Option value="NATIONAL_ID">National ID</Option>
                                        <Option value="VOTER_ID">Voters ID</Option>
                                        <Option value="SSNIT">SSNIT</Option>
                                        <Option value="ALIEN_CARD">Alien Card</Option>
                                        <Option value="BVN">BVN</Option>
                                        <Option value="NIN">NIN</Option>
                                        <Option value="SELFIE">SELFIE</Option>
                                    </Select>
                                </Form.Field>
                                <Form.Field>
                                    <label>Document Number</label>
                                    <input value={documentNumber} onChange={(event) => handledocNum(event.target.value)} placeholder='Document Number' />
                                </Form.Field>
                                <Form.Field>
                                    <label>Uploaded At</label>
                                    <input value={submittedAt} placeholder='Submitted At' disabled />
                                </Form.Field>
                            </Form>
                            <br />
                            {
                                isUpdating ?
                                    <Button loading disabled size="mini">Loading</Button> :
                                    <Button style={{ marginTop: '5px' }} primary size="mini" onClick={() => handleUpdate()}>Update Document</Button>

                            }

                            {/* <span>
                                {
                                    isVerifying ?
                                        <Button loading disabled size="mini">Loading</Button> :
                                        <Button style={{ marginTop: '5px' }} positive size="mini" onClick={() => handleVerify()}>Verify Manually</Button>
                                }
                                {isSubmiting ?
                                    <Button loading disabled size="mini">Loading</Button> :
                                    <Button style={{ marginTop: '5px' }} color="teal" size="mini" onClick={() => handleSubmitToSmile()}>Verify With Smile</Button>
                                }
                            </span>

                            {
                                isRejecting ?
                                    <Button loading disabled size="mini">Loading</Button> :
                                    <Button style={{ marginTop: '5px' }} negative size="mini" onClick={() => handleReject()}>Reject Manually</Button>
                            } */}
                        </div>
                    }
                />
            </div>
        )
    }
}