import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import CircularProgress from "material-ui/CircularProgress";
import {
  UserAmplitudeCell,
  MobileMoneyProfile,
  handleError,
  ProfileDocumentForm
} from "../util/helpers";
import ActivityList from "../ActivityList";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../tim-components/Card.jsx";
import UserCard from "../tim-components/UserCard";
import formattedCurrency from "../util/currencyFormatter";
import MoveMoney from "./MoveMoney";
import { List, notification, Button } from "antd";
import ToggleUserLock from "./ToggleUserLock";
import SendNotification from "./SendNotification";
import SendIntercomNotification from "./SendIntercomNotification";
import ResetPin from "./ResetPin";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import UserVolumes from "./UserVolumes";
import UserPreferences from "./UserPreferences";
import UserReferrerCard from "./UserReferrerCard";
import UserTag from "./UserTag";
import InitiateDeposit from "./InitiateDeposit";
import "../index.css";
import MetaTags from 'react-meta-tags';
import verifiedIcon from "../assets/img/verified.png";

// Because it shows beneath the top black bar and needs to offset
notification.config({
  top: 100
});

export default class UserProfile extends Component {
  state = {
    feed: [],
    linkedAccounts: [],
    user: null,
    isFetching: true,
    fetchingLock: false,
    currency: "",
    lastActivity: null,
    volumes: null,
    referrer: null,
  };

  async componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    const { id } = this.props.match.params;
    const response = await axios.get(`/admin/support/users/${id}`);
    console.log("User Profile: ", response);

    const {
      feed,
      linked_accounts: linkedAccounts,
      user,
      volumes,
      referrer
    } = response.data;

    let presence;
    if (feed.length > 0) {
      presence = moment(feed[0].updatedAt).format("YYYY-MM-DD | HH:mm");
    } else {
      presence = "-";
    }

    this.setState({
      feed,
      linkedAccounts,
      user,
      referrer,
      currency: user.currency,
      userId: user.id,
      displayName: user.display_name,
      isFetching: false,
      fetchingLock: false,
      volumes: volumes,
      lastActivity: presence
    });
  };

  handleAction = () => {
    alert(
      "This feed only represents what a user sees. You are unable to take any actions on behalf of the user"
    );
  };

  // Move Money Functions

  handleSendMoney = (amount, note) => {
    const { userId, currency } = this.state;
    const response = window.confirm(
      `You sure you want to send ${currency} ${amount} ?`
    );

    if (response) {
      this.setState({
        isFetching: true
      });

      axios
        .post("/admin/super/payFromBase", {
          userId,
          amount,
          note
        })
        .then(response => {
          console.log("Response: ", response);
          this.fetchUser();
          alert("Successful");
        })
        .catch(error => {
          console.log("Error: ", error);
          alert("Transaction failed: ", error);
        });
    }
  };

  handleDeductMoney = (amount, note) => {
    const { userId, currency } = this.state;
    const response = window.confirm(
      `You sure you want to deduct ${currency} ${amount}?`
    );

    if (response) {
      this.setState({
        isFetching: true
      });

      axios
        .post("/admin/super/collectToBase", {
          userId,
          amount,
          note
        })
        .then(response => {
          console.log("Response: ", response);
          this.fetchUser();
          alert("Successful");
        })
        .catch(error => {
          console.log("Error: ", error);
          alert("Transaction failed: ", error);
        });
    }
  };

  handleTagTransfer = (userId, newTag) => {
    const { displayName } = this.state;
    var targetNewTag = String(newTag);
    var targetUserId = String(userId);

    this.setState({
      loading: true
    });

    axios
      .post("/admin/support/user_tags/transfer", {
        tag: targetNewTag,
        userId: targetUserId
      })
      .then(response => {
        console.log(response);
        const description = `${displayName}'s tag is now ${targetNewTag}`;
        notification.success({
          message: "Success!",
          description
        });

        this.fetchUser();
        this.setState({
          loading: false
        });
      })
      .catch(error => {
        const description = error.response.data.error.message + ".";
        notification.warning({
          message: "Uh oh!",
          description
        });

        console.error("Error: ", error);
        this.setState({
          loading: false
        });
      });
  };


  handleLockUser = (shouldLock, freezeNote) => {
    const { userId, displayName } = this.state;
    this.setState({ fetchingLock: true })
    axios
      .post("/admin/support/users/lock", {
        userId,
        isLocked: shouldLock,
        reason: freezeNote
      })
      .then(response => {
        const description = response.data.is_locked
          ? `${displayName} is now frozen`
          : `${displayName} has been unfrozen`;
        notification.success({
          message: "Boom!",
          description
        });
        this.fetchUser();
      })
      .catch(error => {
        console.log("Error: ", error);
        alert("Failed to update: ", error);
      });
  };

  addMaliciousLink = linked_id => {
    console.log("adding " + linked_id + " to malicious list.");
    axios
      .post("/admin/support/blocked_linked_accounts/add", {
        id: linked_id
      })
      .then(response => {
        console.log(response);
        const description = response.data.marked_as_malicious
          ? `Linked account has been marked as malicious`
          : `Linked account has restored`;
        notification.success({
          message: "Success",
          description
        });

        this.fetchUser();
      })
      .catch(error => {
        console.log("Error: ", error);
        alert("Failed to update: ", error);
      });
  };

  removeMaliciousLink = linked_id => {
    console.log("removing " + linked_id + " to malicious list.");
    axios
      .post("/admin/support/blocked_linked_accounts/remove", {
        id: linked_id
      })
      .then(response => {
        console.log(response);
        const description = response.data.marked_as_malicious
          ? `Linked account has been marked as malicious`
          : `Linked account has restored`;
        notification.success({
          message: "Success",
          description
        });

        this.fetchUser();
      })
      .catch(error => {
        console.log("Error: ", error);
        alert("Failed to update: ", error);
      });
  };

  initiateDeposit = async (amount, linkedAccountId) => {
    const { currency, displayName, userId } = this.state;

    console.log("Amount: ", amount, linkedAccountId);

    const response = window.confirm(
      `You sure you want to deposit ${amount} ${currency} to ${displayName}?`
    );

    if (response) {
      this.setState({
        isFetching: true
      });

      // Make the call to the web admin side
      try {
        const response = await axios.post("/admin/support/deposits/create", {
          amount,
          linkedAccountId,
          userId
        });

        console.log("Response: ", response);

        notification.success({
          message: "Boom!",
          description: "Deposit completed"
        });

        this.fetchUser();
      } catch (error) {
        handleError(error);

        notification.error({
          message: "Failed to initiate deposit",
          error: error
        });
      }
    }
  };

  render() {
    const LoadingIndicator = () => (
      <div className="posCenter">
        <CircularProgress size={70} thickness={3} color="black" />
      </div>
    );

    const {
      feed,
      linkedAccounts,
      user,
      isFetching,
      currency,
      referrer,
      fetchingLock
    } = this.state;

    // this.isVerified(user.id)
    console.log(linkedAccounts)

    const linkedAccountData = linkedAccounts.map(account => {
      var account_verified = account.is_verified;
      var account_label;
      if (account_verified == true) {
        account_label = (
          <span
            style={{
              color: "#3C8969",
              paddingLeft: "2px",
              paddingRight: "2px"
            }}
          >
            Verified
          </span>
        );
      } else {
        account_label = (
          <span
            style={{
              color: "#e74c3c",
              paddingLeft: "2px",
              paddingRight: "2px"
            }}
          >
            {" "}
            Unverified
          </span>
        );
      }

      var account_linked = account.is_linked;
      if (account_linked == true) {
        account_linked = true;
      } else {
        account_linked = false;
      }

      var marked_malicious = account.marked_as_malicious;
      if (marked_malicious == true) {
        marked_malicious = (
          <span
            onClick={() => this.removeMaliciousLink(account.id)}
            style={{
              color: "red",
              paddingLeft: "2px",
              paddingRight: "2px",
              cursor: "pointer"
            }}
          >
            {" "}
            Malicious ↻{" "}
          </span>
        );
      } else {
        marked_malicious = (
          <span
            onClick={() => this.addMaliciousLink(account.id)}
            style={{
              color: "black",
              paddingLeft: "2px",
              paddingRight: "2px",
              cursor: "pointer"
            }}
          >
            {" "}
            Normal ↻{" "}
          </span>
        );
      }



      // const phone = formatPhoneNumber(parsePhoneNumber(account.mobile_money_phone, { country: account.mobile_money_country }), 'International');
      // this.handleFetchLinkedStats(phone, account.mobile_money_phone, account.mobile_money_country, account.mobile_money_carrier);
      var obj = {
        phone: account.mobile_money_phone,
        mobile_money_carrier: account.mobile_money_carrier,
        mobile_money_country: account.mobile_money_country,
        bank_name: account.bank_name,
        bank_account_number: account.bank_account_number,
        bank_currency: account.bank_currency,
        card_last_four: account.card_last_four,
        card_network: account.card_network,
        card_type: account.card_type,
        account_label: account_label,
        account_linked: account_linked,
        marked_malicious: marked_malicious
      };

      return obj;

      // return `${phone} | ${account.mobile_money_carrier} | ${account.mobile_money_country} | ${account_label}`
    });

    if (isFetching) {
      return <LoadingIndicator />;
    }

    return (
      <div>
        <MetaTags>
          <title>{`${this.state.user.display_name} | Chipper Cash`}</title>
          <meta property="og:description" content={`${this.state.user.display_name} | Chipper Cash`} />
          <meta property="og:title" content={`${this.state.user.display_name} | Chipper Cash`} />
        </MetaTags>

        <Grid fluid>
          <Row>
            <Col md={4}>
              <UserCard
                bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                avatar={user.avatar}
                name={(this.state.user.verification.decision === "ACCEPTED") ? <span>{user.display_name} <img height={18} src={verifiedIcon} /></span> : <span>{user.display_name} <i style={{ color: 'red' }}>not verified</i></span>}
                userName={formattedCurrency(user.balance, user.currency)}
                description={
                  <div>
                    <p>{UserAmplitudeCell(user.id)}</p>
                    <p>Last activity: {this.state.lastActivity}</p>
                    <p>{user.identifier}</p>
                    <p><span>{this.state.user.verification.documentType} {this.state.user.verification.issuedBy} {this.state.user.verification.verificationType} {this.state.user.verification.decision}</span><br /><span>{ProfileDocumentForm(this.state.user.verification.id)}</span></p>
                  </div>
                }
              />
              <UserReferrerCard referrer={referrer} />
              <UserTag user={user} transfer={this.handleTagTransfer} />
              <ToggleUserLock user={user} fetchingLock={fetchingLock} lock={this.handleLockUser} />

              <Card
                title={"Linked Accounts"}
                content={
                  <List
                    dataSource={linkedAccountData}
                    renderItem={item =>
                      item.account_linked ? (
                        <List.Item>
                          <span className="MobileMoneyLink">
                            {" "}
                            {(item.card_last_four == null) ? ((item.phone == null) ? item.bank_account_number : MobileMoneyProfile(item.phone)) : "···· ···· ···· " + item.card_last_four}{" "}
                          </span> {" "} | {(item.card_network == null) ? ((item.mobile_money_carrier == null) ? item.bank_name : item.mobile_money_carrier) : item.card_network} | {" "} {(item.card_type == null) ? ((item.mobile_money_country == null) ? "" : item.mobile_money_country) : item.card_type} | {(item.phone == null) ? item.bank_currency : item.account_label} |{" "} {item.marked_malicious}
                        </List.Item>
                      ) : (
                          <List.Item className="UnlinkedMobileMoney">
                            <span className="MobileMoneyLink">
                              {" "}
                              {MobileMoneyProfile(item.phone)}{" "}
                            </span>{" "} | {item.mobile_money_carrier} | {" "} {item.mobile_money_country} | {item.account_label} | {" "} {item.marked_malicious}
                          </List.Item>
                        )
                    }
                  />
                }
              />
              <UserPreferences user={user} />
              <ResetPin user={user} />
            </Col>
            <Col md={8}>
              <UserVolumes
                volumes={this.state.volumes}
                currency={this.state.currency}
                user={this.state.user}
              />

              <Row>
                <Col md={6}>
                  <MoveMoney
                    send={true}
                    title={"Send Money"}
                    currency={currency}
                    onAction={this.handleSendMoney}
                  />
                </Col>
                <Col md={6}>
                  <MoveMoney
                    send={false}
                    title={"Deduct Money"}
                    currency={currency}
                    onAction={this.handleDeductMoney}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InitiateDeposit
                    linkedAccounts={linkedAccounts}
                    currency={currency}
                    onAction={this.initiateDeposit}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SendIntercomNotification user={user} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SendNotification user={user} />
                </Col>
              </Row>

              <ActivityList
                items={feed}
                handleCancel={this.handleAction}
                handleDecline={this.handleAction}
                handleApprove={this.handleAction}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
