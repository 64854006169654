import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link,
  Switch,
} from "react-router-dom";
import axios from "axios";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

// Components
import App from "./App";
import PayRequest from "./PayRequest";
import Activity from "./Activity";
import EditProfile from "./EditProfile";
import CashOut from "./CashOut";
import AddLinkedAccount from "./AddLinkedAccount";
import Invite from "./Invite";
import UserPayment from "./TagsPayment";
import UserPaymentPage from "./UserPaymentPage";
import Join from "./Join";
import Login from "./Login";
import Register from "./Register";

import AccountStatement from "./AccountStatement";

import Admin from "./admin/Admin";
import TagsPayment from "./TagsPayment";

function loggedIn() {
  // State of logged in depends on if there's a value in the local storage.
  const token = localStorage.getItem("accessToken");
  console.log("Token in routes.js: ", token);

  // Make sure the Authorization token is setup before routing anywhere
  axios.defaults.headers["Authorization"] = token;
  const result = token !== null;
  return result;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const routes = (
  <MuiThemeProvider>
    <Router>
      <Switch>
        <Route exact path="/invite/:code" component={Invite} />
        <Route path="/me/:tag" component={TagsPayment} />
        <Route path="/m/:tag" component={UserPaymentPage} />

        <Route path="/join/:id" component={Join} />
        <Route
          path="/account_statement/:userId/:token/:year/:month"
          component={AccountStatement}
        />

        <App>
          <Switch>
            <Route exact path="/" component={Activity} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />

            <PrivateRoute path="/activity" component={Activity} />
            <PrivateRoute path="/pay" component={PayRequest} />
            <PrivateRoute path="/request" component={PayRequest} />
            <PrivateRoute path="/linkAccount" component={AddLinkedAccount} />

            <PrivateRoute path="/profile" component={EditProfile} />
            <PrivateRoute path="/cashout" component={CashOut} />

            <PrivateRoute path="/admin" component={Admin} />
          </Switch>
        </App>
      </Switch>
    </Router>
  </MuiThemeProvider>
);

export default routes;
