import axios from "axios";

export const trackClick = async (id) => {
  const navigator = window.navigator;
  var _navigator = {};
  for (var i in navigator) {
    _navigator[i] = navigator[i];

    if (i === "connection") {
      let _connection = {};
      const connection = _navigator[i];
      for (var j in connection) {
        _connection[j] = connection[j];
      }
      _navigator[i] = _connection;
    }
  }

  delete _navigator.plugins;
  delete _navigator.mimeTypes;
  const parsed = JSON.stringify(_navigator);

  // Referrer
  const referrer = document.referrer;

  // Canonical URL
  const canonical_url = window.location.href;

  const data = {
    id,
    navigator: parsed,
    referrer,
    canonical_url,
  };

  const url = "http://localhost:3003";

  const instance = axios.create({
    baseURL: url,
    timeout: 5000,
  });

  const response = await instance.post("/initial", data);
  return response.data;

  /*
     .then(response => {
         // Use this to generate a url
         // https://developers.google.com/analytics/devguides/collection/android/v4/campaigns
         const playUrl = 'https://play.google.com/store/apps/details?id=com.chippercash&referrer=utm_source%3Dsms%26utm_campaign%3Dsms_campaign'

         setTimeout(() => {
             window.location = playUrl
         }, 1500);
     })
     .catch(error => {
         console.log("@@ Error firing method: ", error)
     })
     */

  /*
    const id = filter.pivotId || filter.id;
    const term = filter.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    return new RegExp(term, 'gi').test(row[id])
    */
};
