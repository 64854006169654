import React, { Component } from 'react';
import axios from 'axios';
import './index.css';

export default class Join extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: ''
        }
    }

    getOutput = (object) => {
        var output = '';
        for (var property in object) {
        output += property + ': ' + object[property]+'; ';
        }
        return output
    } 

    async componentWillMount() {
        const { id } = this.props.match.params
        const navigator = window.navigator

       var _navigator = {};
       for (var i in navigator) {
            _navigator[i] = navigator[i];

            if (i === 'connection') {
                // Can we save this in the same way?
                let _connection = {}
                const connection = _navigator[i]
                for (var j in connection) {
                    _connection[j] = connection[j]
                }
                _navigator[i] = _connection
            }
       }

       delete _navigator.plugins;
       delete _navigator.mimeTypes;
       const parsed = JSON.stringify(_navigator)

        const data = {
            id,
            navigator: parsed
        }

        const url = 'https://boulevard-podcast-analytics.herokuapp.com'
        // const url = 'http://localhost:3003'

        const instance = axios.create({
            baseURL: url,
            timeout: 5000
        });

        instance.post('/initial', data)
        .then(response => {
            // Use this to generate a url
            // https://developers.google.com/analytics/devguides/collection/android/v4/campaigns
            const playUrl = 'https://play.google.com/store/apps/details?id=com.chippercash&referrer=utm_source%3Dsms%26utm_campaign%3Dsms_campaign'

            setTimeout(() => {
                window.location = playUrl
            }, 1500);
        })
        .catch(error => {
            console.log("@@ Error firing method: ", error)
        })
    }

    render() { 
         return (
             <div className='posCenter' style={{ textAlign: 'center' }}>
                 <h2>Congratulations!</h2>
                 <h4>Welcome to the Future of African Payments.</h4>
                 <br />
             </div>
         )
     }
}
