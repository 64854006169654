import React from 'react';
import Avatar from 'material-ui/Avatar';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardTitle } from 'material-ui/Card';

const StrikethroughCard = props => {
    let title = props.title;
    let amount = props.amount;
    let note = props.note;
    let timestamp = props.timestamp;
    let user = props.user;

    const avatarStyle = { objectFit: 'cover' };
    const avatarSource = user ? user.avatar : 'https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png'

    return (
        <div  style={{cursor: 'pointer'}} onClick={props.handleFetch}>
          <Card style={{margin: 10}}>
            <p style={{textAlign: 'right', float: 'right', margin: 15, fontSize: 15, color: 'gray', textDecoration: 'line-through'}}>
            {amount}
            </p>
            <CardHeader
              title={title}
              titleStyle={{marginRight: 65}}
              subtitle={timestamp}
              avatar={<Avatar
                  src={avatarSource}
                  style={avatarStyle}
               />}
            />
            <CardTitle title={note} titleStyle={{fontSize: 20}}/>
          </Card>
        </div>
    );
};

StrikethroughCard.propTypes = {
    title: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
    updatedAt: PropTypes.instanceOf(Date).isRequired,
    amount: PropTypes.string.isRequired
};

export default StrikethroughCard;