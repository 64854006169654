import React, { Component } from 'react'
import { Grid, Row, Col } from "react-bootstrap";
import KYCFormRender from './KYCFormRender';
import KYCImageRender from './KYCImageRender';
import axios from 'axios';
import moment from 'moment';
import { notification } from 'antd';
import { Button } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { Label } from 'semantic-ui-react'
import { Modal, Radio, Input } from 'antd';

const baseURL=`${window.complianceUrl}manual_review`
let axiosConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
    baseURL
  };

const rejectionReason = [
    "Please provide a clear picture of your NIN and a selfie with your NIN",
    "Please provide a clear selfie with your ID",
    "Please provide a clear picture of your ID",
    "Please provide alternate identification and a selfie with your ID",
    "Please provide a clear picture of your ID and a selfie with your ID",
    "This ID is already associated with an account on Chipper Cash",
    "Please provide a picture of the original copy of your ID and a selfie with your original ID",
    "Please use your own ID and photographs for the account verification process",
    "You have made multiple submissions for the account verification process",
    "ID Type Unsupported: Please submit an alternative ID along with your selfie",
]
export default class PendingKYCDetails extends Component {
    state = {
        user: null,
        ownerId: "",
        status: "",
        documentDetails: [],
        isProcessing: true,
        pendingDocumentID: "",
        dob: "",
        documentType: "",
        expiresOn: "",
        firstName: null,
        lastName: null,
        middleName: null,
        issuedBy: "",
        issuedOn: "",
        documentNumber: "",
        documentPhoto: "",
        proofPhoto: "",
        submittedAt: "",
        isRejecting: false,
        isUpdating: false,
        isSubmiting: false,
        isVerifying: false,
        hideVerificationButtons: true,
        nextID: 0,
        prevID: 0,
        visible: false,
        value: 1,
        manualRejectionMessage: ""
    }



    componentDidMount() {
        console.log(this.props.match)
        var pendingDocumentID = this.props.match.params.id
        var nextID = parseInt(pendingDocumentID) + 1
        var prevID = parseInt(pendingDocumentID) - 1
        this.setState({
            pendingDocumentID,
            nextID,
            prevID
        }, () => this.getDetails());
    }

    getDetails() {
        var id = this.state.pendingDocumentID
        axios.get(`/id_document/${id}`,axiosConfig)
            .then(response => {
                const document = response.data;
                if (document.documentType === "BVN") {
                    var pendingDocumentID = parseInt(this.props.match.params.id) + 1;
                    console.log("@@ Looking at ", pendingDocumentID);
                    var nextID = parseInt(pendingDocumentID) + 1;
                    var prevID = parseInt(pendingDocumentID) - 1;
                    this.setState(
                        {
                            pendingDocumentID,
                            nextID,
                            prevID
                        },
                        () => this.getDetails()
                    );
                    return;
                }
                let dateOfBirth;
                dateOfBirth = "2000-01-01T01:10:02.680Z"
                let issuedDate;
                issuedDate = "2000-01-01T01:10:02.680Z"
                let expiredDate;
                expiredDate = "2000-01-01T01:10:02.680Z"
                this.setState({
                    displayName: document.owner,
                    ownerId: document.ownerId,
                    dob: dateOfBirth,
                    documentType: document.documentType,
                    expiresOn: expiredDate,
                    issuedBy: document.issuedBy,
                    issuedOn: issuedDate,
                    documentNumber: document.documentNumber,
                    documentPhoto: document.url,
                    proofPhoto: document.proofUrl,
                    isProcessing: false,
                    submittedAt: moment(document.submittedAt).format("DD-MM-YYYY")
                })

            })
            .catch(error => {

                this.setState({
                    isProcessing: false
                })
            })
    }

    handleDob = (dob) => {

        this.setState({ dob: dob })
    }

    handleDocType = (doctype) => {
        // console.log(doctype)
        this.setState({ documentType: doctype })
    }

    handleExpireson = (expireson) => {
        // console.log(expireson)
        this.setState({ expiresOn: expireson })
    }

    handleFirstname = (firstname) => {
        console.log(firstname)
        this.setState({ firstName: firstname })
    }

    handleLastname = (lastname) => {
        console.log(lastname)
        this.setState({ lastName: lastname })
    }

    handleMiddlename = (middlename) => {
        console.log(middlename)
        this.setState({ middleName: middlename })
    }

    handleIssuedby = (issuedby) => {
        // console.log(issuedby)
        this.setState({ issuedBy: issuedby })
    }

    handleIssuedon = (issuedon) => {
        // console.log(issuedon)
        this.setState({ issuedOn: issuedon })
    }

    handledocNum = (docnum) => {
        // console.log(docnum)
        this.setState({ documentNumber: docnum })
    }

    handleUpdate = async () => {
        this.setState({
            isUpdating: true
        })
        const { pendingDocumentID, documentType, firstName, lastName, middleName, issuedBy, documentNumber, dob, expiresOn, issuedOn, submittedAt } = this.state

        if (dob === "") {
            const description = "Date of birth cannot be empty";
            notification.error({
                message: "Uh-oh!",
                description
            });
        }
        if (expiresOn === "") {
            const description = "Expiry date cannot be empty";
            notification.error({
                message: "Uh-oh!",
                description
            });
        }
        if (issuedOn === "") {
            const description = "Issue date cannot be empty";
            notification.error({
                message: "Uh-oh!",
                description
            });
        }
        if (pendingDocumentID === "") {
            const description = "Document ID unindentified. Please contact engineering.";
            notification.error({
                message: "Uh-oh!",
                description
            });
        }
        if (issuedBy === "") {
            const description = "Issuing country can not be empty";
            notification.error({
                message: "Uh-oh!",
                description
            });
        }
        if (documentNumber === "") {
            const description = "Document ID number can not be empty";
            notification.error({
                message: "Uh-oh!",
                description
            });
        }
        if (documentType === "") {
            const description = "Document type can not be empty";
            notification.error({
                message: "Uh-oh!",
                description
            });
        }
       
        if (firstName || lastName || pendingDocumentID !== "" || issuedBy !== "" || documentNumber !== "" || documentType !== "") {
            axios.post(`/update/${pendingDocumentID}`, {
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                dob: moment(dob).format(),
                issuedOn: moment(issuedOn).format(),
                expiresOn: moment(expiresOn).format(),
                issuingCountry: issuedBy,
                idNumber: documentNumber,
                idType: documentType
            },axiosConfig)
                .then(response => {
                    const description = "ID Updated Successfully";
                    notification.success({
                        message: "Great",
                        description
                    });
                    this.setState({
                        isUpdating: false,
                        hideVerificationButtons: false,
                    })
                })
                .catch(error => {
                    console.log(error.response.data.error.message)
                    const description = "ID Update Failed. " + error.response.data.error.message;
                    notification.error({
                        message: "Error",
                        description
                    });
                    this.setState({
                        isUpdating: false
                    });

                })
        }
    }

    handleSubmitToSmile = () => {
        this.setState({
            isSubmiting: true
        })
        alert("This feature is not available yet")
        this.setState({
            isSubmiting: false,
            hideVerificationButtons: true,
        })
    }

    handleVerify = async () => {

        await this.handleUpdate()
        this.setState({
            isVerifying: true
        })
        var confirmVerify = window.confirm("Are you sure you want to manually verify this ID?");
        if (confirmVerify == true) {
            axios.post(`/accept/${this.state.pendingDocumentID}`,{},axiosConfig)
                .then(response => {
                    const description = "ID Manually Verified";
                    notification.success({
                        message: "Great",
                        description
                    });
                    this.setState({
                        isVerifying: false,
                        hideVerificationButtons: true,
                        status: "ACCEPTED"
                    })
                })
                .catch(error => {
                    console.log(error.response.data.error.message)
                    const description = "ID Manual Verification Failed. " + error.response.data.error.message;
                    notification.error({
                        message: "Error",
                        description
                    });
                    this.setState({
                        isVerifying: false,
                        status: "PENDING"
                    })
                })
        } else {
            // do nothing
        }
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleReject = (reason) => {
        var rejectionReason = reason;
        this.setState({
            isRejecting: true
        })

        if (this.state.value === 10) {
            rejectionReason = this.state.manualRejectionMessage
        }

        axios.post(`/reject/${this.state.pendingDocumentID}`, {
            reason: rejectionReason
        },axiosConfig)
            .then(response => {
                const description = "ID Rejected Manually";
                notification.success({
                    message: "Great",
                    description
                });
                this.setState({
                    isRejecting: false,
                    hideVerificationButtons: true,
                    status: "REJECTED"
                })
                // this.fetchUser(this.state.ownerId)
            })
            .catch(error => {
                console.log(error)
                const description = "ID Manual Rejection Failed. Please make sure a valid reason is passed in. " + error.message;
                notification.error({
                    message: "Error",
                    description
                });
                this.setState({
                    isRejecting: false,
                })
            })
        // } 
        // else {
        //do nothing 
        // }
        // } else {
        //do nothing
        // }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
        this.handleReject(rejectionReason[this.state.value])
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    rejectionMessage = (e) => {
        this.setState({
            manualRejectionMessage: e.target.value
        })
    }

    render() {
        const { hideVerificationButtons, isRejecting, isSubmiting, isVerifying, isUpdating, pendingDocumentID, documentPhoto, proofPhoto, dob, documentType, expiresOn, firstName, lastName, middleName, issuedBy, issuedOn, documentNumber, submittedAt } = this.state
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
            <div>
                <Modal
                    title="Basic Modal"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={1060}
                >
                    <Radio.Group onChange={this.onChange} value={this.state.value}>
                        <Radio style={radioStyle} value={0}>
                            Please provide a clear picture of your NIN and a selfie with your NIN
                        </Radio>
                        <Radio style={radioStyle} value={1}>
                            Please provide a clear selfie with your ID
                        </Radio>
                        <Radio style={radioStyle} value={2}>
                            Please provide a clear picture of your ID
                        </Radio>
                        <Radio style={radioStyle} value={3}>
                            Please provide alternate identification and a selfie with your ID
                        </Radio>
                        <Radio style={radioStyle} value={4}>
                            Please provide a clear picture of your ID and a selfie with your ID
                        </Radio>
                        <Radio style={radioStyle} value={5}>
                            This ID is already associated with an account on Chipper Cash
                        </Radio>
                        <Radio style={radioStyle} value={6}>
                            Please provide a picture of the original copy of your ID and a selfie with your original ID
                        </Radio>
                        <Radio style={radioStyle} value={7}>
                            Please use your own ID and photographs for the account verification process
                        </Radio>
                        <Radio style={radioStyle} value={8}>
                            You have made multiple submissions for the account verification process
                        </Radio>
                        <Radio style={radioStyle} value={9}>
                            We do not support that ID type. Please submit an alternative along with your selfie
                        </Radio>
                        <Radio style={radioStyle} value={10}>
                            Other Message: {this.state.value === 10 ? <Input onChange={(event) => this.rejectionMessage(event)} style={{ width: '100%', marginLeft: 0 }} /> : null}
                        </Radio>

                    </Radio.Group>
                </Modal>

                <Grid fluid>

                    <center>
                        <span style={{ fontSize: '1.5em', fontWeight: 600 }}>{this.state.displayName} </span>
                        <span style={{ marginBottom: '100px' }}>
                            {(this.state.status === "PENDING") ? <Label color={'orange'} key={'orange'}>{this.state.status}</Label> : <span></span>}
                            {(this.state.status === "REJECTED") ? <Label color={'red'} key={'red'}>{this.state.status}</Label> : <span></span>}
                            {(this.state.status === "ACCEPTED") ? <Label color={'green'} key={'green'}>{this.state.status}</Label> : <span></span>}
                        </span>
                    </center>
                     <Row style={{ margin: '10px 30px' }}>
                        <Col md={2} lg={2}>
                        
                    
                            <KYCFormRender
                                isRejecting={isRejecting}
                                isSubmiting={isSubmiting}
                                isVerifying={isVerifying}
                                isUpdating={isUpdating}
                                hideVerificationButtons={hideVerificationButtons}
                                id={pendingDocumentID}
                                dateOfBirth={moment(dob, "YYYY-MM-DD")}
                                expiresOn={moment(expiresOn, "YYYY-MM-DD")}
                                issuedOn={moment(issuedOn, "YYYY-MM-DD")}
                                documentType={documentType}
                                firstName={firstName}
                                lastName={lastName}
                                middleName={middleName}
                                issuedBy={issuedBy}
                                documentNumber={documentNumber}
                                submittedAt={submittedAt}
                                handleDob={this.handleDob}
                                handleDocType={this.handleDocType}
                                handleExpireson={this.handleExpireson}
                                handleFirstname={this.handleFirstname}
                                handleLastname={this.handleLastname}
                                handleMiddlename={this.handleMiddlename}
                                handleIssuedby={this.handleIssuedby}
                                handleIssuedon={this.handleIssuedon}
                                handledocNum={this.handledocNum}
                                handleUpdate={this.handleUpdate}
                                handleSubmitToSmile={this.handleSubmitToSmile}
                                handleVerify={this.handleVerify}
                                handleReject={this.handleReject} />
                        </Col>
                        <Col md={10} lg={10}>
                        <div>  <h4>{this.state.ownerId?<Link to={`/admin/user/${this.state.ownerId}`}>{this.state.ownerId}</Link>:""} </h4></div>
   
                            <h3 style={{ fontWeight: 600 }}>
                                {/* Customer Uploads */}
                            </h3>

                            <KYCImageRender documentPhoto={documentPhoto} proofPhoto={proofPhoto} />
                        </Col>
                    </Row>
                </Grid>
                <Grid>
                    <Row>
                        <Col xs={6} md={6} lg={6}>
                            {
                                isRejecting ?
                                    <Button style={{ position: 'fixed', bottom: 0, right: 4, width: '49.7%', height: '10%' }} loading disabled>Loading</Button> :
                                    <Button style={{ position: 'fixed', bottom: 0, left: 4, width: '49.7%', height: '10%' }} negative size="mini" onClick={() => this.showModal()}>Reject Manually</Button>
                            }
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                            {
                                isVerifying ?
                                    <Button style={{ position: 'fixed', bottom: 0, right: 0, width: '49.7%', height: '10%' }} loading disabled>Loading</Button> :
                                    <Button style={{ position: 'fixed', bottom: 0, right: 0, width: '49.7%', height: '10%' }} positive size="mini" onClick={() => this.handleVerify()}>Verify Manually</Button>
                            }
                        </Col>
                    </Row>
                </Grid>


                <Link
                    onClick={this.forceUpdate}
                    to={`/admin/pendingkycdetails/${this.state.prevID}`}
                >
                    <Button style={{ position: 'fixed', top: 70, left: 5, width: '15%', height: '7%' }} secondary size="mini">PREV</Button>
                </Link>

                <Link
                    onClick={this.forceUpdate}
                    to={`/admin/pendingkycdetails/${this.state.nextID}`}
                >
                    <Button style={{ position: 'fixed', top: 70, right: 0, width: '15%', height: '7%' }} secondary size="mini">NEXT</Button>
                </Link>

            </div>
        )
    }
}