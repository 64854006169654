import React, { Component } from 'react'
import axios from 'axios';
import {
    purple900,
} from 'material-ui/styles/colors';
import CircularProgress from 'material-ui/CircularProgress';
import moment from 'moment';
import { Label } from 'semantic-ui-react';
import { filter, UserCell, NumberCell, DocumentForm } from '../util/helpers'
import ReactTable from "react-table";
import {
    Grid, Row, Col
} from 'react-bootstrap';
import Card from '../tim-components/Card.jsx';
import '../assets/css/bootstrap.css';
import '../assets/sass/light-bootstrap-dashboard.css';
import '../assets/css/demo.css';
import '../assets/css/pe-icon-7-stroke.css';
import MetaTags from 'react-meta-tags';
const csv = require('csv-parser');
const fs = require('fs');

const shots = [

]

export default class ScriptSheet extends Component {
    state = {
    }

    getEntries = () => {
        // alert("Saw entries")
        // console.log(mylist)
        shots.map(item => {
            console.log("item => " + item)
            axios
                // .post(
                //     `${window.complianceUrl}manual_review/revoke/${item}`,
                //     {
                //         reason: 'Revoking and requesting BVN resubmission',
                //     }
                // )
                // .post(`/admin/support/nuban/deactivate/${item}`)
                .post(`/admin/support/withdrawals/refund/${item}`)
                // .post("/admin/super/payFromBase", {
                //     userId: item.userId,
                //     amount: item.amount,
                //     note: item.note
                // })
                .then(response => {
                    console.log("Response: ", response);
                    alert("Successfully refunded");
                })
                .catch(error => {
                    console.log("Error: ", error);
                    alert("Transaction failed: ", error);
                });
        })
    }
    // handleLockUser = (shouldLock, freezeNote) => {
    //     const { userId, displayName } = this.state;
    //     this.setState({ fetchingLock: true })
    //     axios
    //         .post("/admin/support/users/lock", {
    //             userId,
    //             isLocked: shouldLock,
    //             reason: freezeNote
    //         })
    //         .then(response => {
    //             const description = response.data.is_locked
    //                 ? `${displayName} is now frozen`
    //                 : `${displayName} has been unfrozen`;
    //             notification.success({
    //                 message: "Boom!",
    //                 description
    //             });
    //             this.fetchUser();
    //         })
    //         .catch(error => {
    //             console.log("Error: ", error);
    //             alert("Failed to update: ", error);
    //         });
    // };
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Chipper Cash | Script Sheet</title>
                    <meta property="og:title" content="Chipper Cash | Withdrawals" />
                </MetaTags>
                <h2 style={{ textAlign: 'center' }}> KYC Reviews Pending</h2>
                <center>
                    <h5>Click to Run Script</h5>
                    <button onClick={() => this.getEntries()}>Run</button>
                </center>
            </div>
        )
    }
}
//