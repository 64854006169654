import React, { Component } from "react";
import axios from "axios";
import Avatar from "material-ui/Avatar";
import CircularProgress from "material-ui/CircularProgress";
import "./checkout.css";
import "./App.css";
import logo from "./assets/img/black_logo_transparent.png";
import boxlogo from "./assets/img/profile_picture_symbol_inverse.png";
import backArrow from "./assets/img/backArrow.png";
import verifiedIcon from "./assets/img/verified.png";
import QRCode from "qrcode.react";
import Phone from "react-phone-number-input";
import { Spin, Icon } from "antd";
import { Flag } from "semantic-ui-react";
import { Divider, Segment, Message } from "semantic-ui-react";
import Intercom from "react-intercom";
import { trackClick } from "./util/trackClick";
import MetaTags from 'react-meta-tags';
import { VerifyLogo } from './assets/img/verified.png';
import { Image } from 'semantic-ui-react'

const supportedCountries = ["GH", "KE", "RW", "TZ", "UG"];

const antIcon = (
  <Icon type="sync" style={{ fontSize: 40, color: "#ffffff" }} spin />
);

class TagsPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: true,
      user_id: null,
      avatar: null,
      display_name: null,
      first_name: null,
      last_name: null,
      primary_currency: null,
      tag: null,
      found: false,
      value: 1,
      browserCountry: "",
      country: "",
      amount: "",
      note: "",
      phone: "",
      amountValue: null,
      currency: null,
      visible: false,
      charge_id: null,
      timer: 0,
      phoneFocused: false,
      isSuccess: false,
      isError: false,
      processingPayment: false,
      status: "",
      statusState: null,
      appearForm: true,
      paymentComplete: false,
      isVerified: null
    };
  }

  async componentDidMount() {
    var passedTag;
    const { tag } = this.props.match.params;
    trackClick(tag)

    if (tag.substring(0, 1) == "@") {
      passedTag = tag.substring(1);
    } else {
      passedTag = tag;
    }

    this.getTag(passedTag);
    this.setState({
      tagName: passedTag
    });

    this.fetchUserIPDetails();

  }

  getTag = tagName => {
    axios
      .get(`/public/tag/${tagName}`)
      .then(response => {
        this.setState({
          found: true,
          user_id: response.data.id,
          avatar: response.data.avatar,
          display_name: response.data.display_name,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          primary_currency: response.data.primary_currency,
          tag: response.data.tag,
          isProcessing: false
        });
        this.isVerified();
      })
      .catch(error => {
        console.log(error);
        this.setState({
          found: false,
          isProcessing: false
        });
        this.isVerified();
      });
  };

  isVerified = () => {
    axios
      .get(`/public/verified/${this.state.user_id}`)
      .then(response => {
        // console.log(response)
        // console.log(this.state.user_id)
        this.setState({
          isVerified: response.data.verified
        })
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isVerified: false
        })
      });
  };

  fetchUserIPDetails = () => {
    axios
      .get(`/public/ip`)
      .then(response => {
        // If the returned, country is not in the supported country list,
        // then default to GH.
        let browserCountry = response.data.country;
        if (!supportedCountries.includes(browserCountry)) {
          browserCountry = "GH";
        }
        this.setState({
          browserCountry
        });
      })
      .catch(error => {
        console.error("Unable to get IP details", error);
      });
  };

  handleChange = (event, index, value) => this.setState({ value });

  handlePhoneChange = event => {
    this.setState({
      phone: event.target.value
    });
  };

  handleAmountChange = event => {
    var intCheck = parseInt(event.target.value, 10);
    if (!isNaN(intCheck) || event.target.value == "") {
      var value = event.target.value;
      var parser = value.replace(/\$\s?|(,*)/g, "");
      var formatter = `${parser}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      this.setState({
        amount: formatter,
        amountValue: parser
      });
    }
  };

  handleNoteChange = event => {
    var value = event.target.value;
    this.setState({ note: value });
  };

  handleSubmitPayment = () => {

    //TEMPORARILY DISABLE KENYA
    // if (this.state.primary_currency === "KES") {
    //   alert("Payment links to Kenya are temporarily disabled. Full service will be restored soon.")
    //   return;
    // }

    this.setState({
      isError: false,
      isSuccess: false,
      paymentComplete: false,
      status: "",
      statusState: null
    });

    var currency = "";

    if (this.state.phone.length < 7) {
      this.setState({
        status:
          "Please enter a valid phone number within our supported countries.",
        statusState: false
      });
      return false;
    }

    if (this.state.phone.substring(0, 4) === "+233") {
      currency = "GHS";
      //dont take checkouts from GH numbers at this time
      alert("Payment links from Ghanaian numbers are temporarily disabled. Full service will be restored in due course. Please download the app on the Play Store / App Store and use the Chipper Cash app to send p2p transactions. Thank you!")
      return;
    } else if (this.state.phone.substring(0, 4) === "+254") {
      currency = "KES";
      //dont take checkouts from KE numbers at this time
      alert("Payment links from Kenyan numbers are temporarily disabled. Full service will be restored in due course. Please download the app on the Play Store / App Store and use the Chipper Cash app to send p2p transactions. Thank you!")
      return;
    } else if (this.state.phone.substring(0, 4) === "+250") {
      currency = "RWF";
      //dont take checkouts from RWF numbers at this time
      alert("Payment links from number in Rwanda are temporarily disabled. Full service will be restored in due course. Please download the app on the Play Store / App Store and use the Chipper Cash app to send p2p transactions. Thank you!")
      return;
    } else if (this.state.phone.substring(0, 4) === "+255") {
      currency = "TZS";
      //dont take checkouts from TZ numbers at this time
      alert("Payment links from Tanzanian numbers are temporarily disabled. Full service will be restored in due course. Please download the app on the Play Store / App Store and use the Chipper Cash app to send p2p transactions. Thank you!")
      return;
    } else if (this.state.phone.substring(0, 4) === "+256") {
      currency = "UGX";
      //dont take checkouts from UG numbers at this time
      alert("Payment links from Ugandan numbers are temporarily disabled. Full service will be restored in due course. Please download the app on the Play Store / App Store and use the Chipper Cash app to send p2p transactions. Thank you!")
      return;
    } else {
      this.setState({
        status:
          "Please enter a valid phone number within our supported countries.",
        statusState: false
      });
      return false;
    }

    if (this.state.phone.substring(0, 4) === "+255") {
      this.setState({
        status:
          "Sorry, Tanzanian transfers via checkout are currently disabled.",
        statusState: false
      });
      return false
    }

    if (this.state.amountValue == "" || this.state.amountValue == null) {
      this.setState({
        status: "Please specify the amount to begin payment.",
        statusState: false
      });
      return false;
    }
    if (this.state.note == "") {
      this.setState({
        status: `Please add a note for ${this.state.display_name
          } to begin payment.`,
        statusState: false
      });
      return false;
    } else {
      this.setState({
        processingPayment: true
      });

      axios
        .post("public/checkouts", {
          name: "Someone",
          currency: currency,
          note: this.state.note,
          account: this.state.phone,
          amount: this.state.amountValue,
          userId: this.state.user_id
        })
        .then(response => {
          console.log(response)
          this.setState({
            status: `Please check your mobile money device for a prompt to complete payment`,
            statusState: true
          });
          this.timer = setInterval(() => this.getItems(response.data.id), 1000);
          this.setState({ timer: this.timer });
        })
        .catch(error => {
          console.error("Error: ", error);
          this.setState({
            blocked: true
          });
        });
    }
  };

  getItems = id => {
    axios
      .get(`/public/checkout/${id}`)
      .then(response => {
        const { status, details } = response.data;
        console.log(response)
        if (
          status === "SETTLED" ||
          status === "FAILED" ||
          status === "EXPIRED"
        ) {
          clearInterval(this.timer);
          this.time = null;
          this.setState({
            processingPayment: false
          });
        }
        if (status === "SETTLED") {
          if (!this.state.isSuccess) {
            this.setState({
              status: `Your payment to ${this.state.display_name
                } has been completed!`,
              statusState: true
            });
          }
          this.setState({
            isSuccess: true,
            paymentComplete: true,
            note: "",
            amount: "",
            phone: ""
          });

          return;
        } else if (status === "FAILED" || status === "EXPIRED") {
          if (!this.state.isError) {
            var more_details = JSON.parse(details.status_failed)

            var string = more_details.error_message;
            var substring = "insufficient funds";
            var substringTwo = "enough credit";
            if (string.indexOf(substring) !== -1) {
              this.setState({
                status: "Uh oh, that mobile money account does not have enough funds to complete the transfer. Please top up the account and try again.",
                statusState: false
              });
            }
            else if (string.indexOf(substringTwo) !== -1) {
              this.setState({
                status: "Uh oh, that mobile money account does not have enough funds to complete the transfer. Please top up the account try.",
                statusState: false
              });
            } else {
              this.setState({
                status: `Failed to process your payment to ${this.state.display_name
                  }. Please try again later.`,
                statusState: false
              });
            }

            console.log(more_details.error_message)

          }
          this.setState({
            isError: true
          });
          return;
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  changePhoneInput = () => {
    document.getElementById("backQR").style.opacity = "1";

    this.setState({
      phoneFocused: true
    });
  };

  backToQR = () => {
    this.setState({ phoneFocused: false });
    document.getElementById("backQR").style.opacity = "0";
    this.setState({
      phone: "",
      note: "",
      amount: "",
      status: "",
      statusState: null,
      isSuccess: false,
      isError: false,
      paymentComplete: false
    });
  };

  render() {
    const LoadingIndicator = () => (
      <div className="subPosCenter">
        <CircularProgress size={70} thickness={3} color="black" />
      </div>
    );

    var fetchedTag = this.props.match.params.tag

    if (fetchedTag.substring(0, 1) == "@") {
      fetchedTag = fetchedTag.substring(1);
    } else {
      fetchedTag = fetchedTag;
    }


    return (
      <div className="tagBackground">
        <MetaTags>
          <title>{`Pay ${this.state.display_name} - Chipper Cash`}</title>
          <meta property="og:description" content={`Send a payment to ${this.state.display_name} from your mobile money number in our supported countries.`} />
          <meta property="og:title" content={`Pay ${this.state.display_name} - Chipper Cash`} />
        </MetaTags>

        <div style={{ zIndex: "3" }}>
          <Intercom appID="nqe8me36" />
        </div>
        <div
          className="container"
          style={{ marginTop: "5%", paddingLeft: "5%", paddingRight: "5%" }}
        >
          <br />

          <Segment raised
            id="mysegment"
            style={{ backgroundColor: "#F7F5F7", paddingBottom: "2%" }}
          >
            {this.state.isProcessing ? (
              <LoadingIndicator />
            ) : (
                <div>
                  {this.state.found ? (
                    <div>
                      <div
                        style={{
                          opacity: "0",
                          padding: "5%",
                          position: "absolute",
                          top: "0",
                          left: "0"
                        }}
                        id="backQR"
                        onClick={() => this.backToQR()}
                      >
                        <img height={40} src={backArrow} />
                      </div>

                      <center>
                        <Avatar
                          src={this.state.avatar}
                          size={90}
                          style={{
                            position: "absolute",
                            top: "-45px",
                            left: "0",
                            right: "0",
                            margin: "0 auto",
                            zIndex: "2",
                            boxShadow: "0.25px 0.25px 0.5px 0.5px #777"
                          }}
                        />
                      </center>
                      <br />
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "1.5em",
                          fontWeight: "700",
                          color: "black"
                        }}
                      >
                        {this.state.display_name + " "}
                        {
                          (this.state.isVerified === true) ?
                            <img height={18} src={verifiedIcon} /> :
                            <span></span>
                        }
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "1.0em",
                          fontWeight: "400",
                          color: "black"
                        }}
                      >
                        @{this.state.tag}
                      </div>

                      {
                        (this.state.isVerified === false) ?
                          <Message color='red' style={{ textAlign: 'center', fontFamily: 'graphik', fontSize: '0.8em', border: 'none' }}>Chipper Cash has not verified this account. Please make sure you know them personally before making any payments.</Message> :
                          <span></span>
                      }



                      <br />
                      {this.state.phoneFocused ? (
                        <div />
                      ) : (
                          <div className="qr_display">
                            <div id="QRCode">
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.6em",
                                  fontWeight: "600"
                                }}
                              >
                                SCAN TO PAY
                          </div>
                              <center>
                                <span>
                                  <Segment compact raised>
                                    <QRCode
                                      size={210}
                                      value={`chippercash://pay/${this.state.user_id
                                        }`}
                                    />
                                  </Segment>
                                </span>
                              </center>
                            </div>

                            <Divider
                              id="hrline"
                              horizontal
                              style={{ fontSize: "0.6em", fontWeight: "600" }}
                            >
                              OR PAY DIRECTLY WITH MOBILE MONEY
                        </Divider>
                          </div>
                        )}
                      <div id="guestForm">
                        <div className="phoneInputContainer">
                          <Phone
                            id="guestPhoneInput"
                            className="phoneInput"
                            style={{
                              boxShadow: "0.25px 0.25px 0.5px 0.5px #777"
                            }}
                            country={this.state.browserCountry}
                            placeholder="Enter mobile money number"
                            value={this.state.phone}
                            onFocus={phone => this.changePhoneInput(phone)}
                            onChange={phone => this.setState({ phone: phone })}
                            ref={c => (this.phoneInput = c)}
                            countries={supportedCountries}
                          />
                        </div>

                        {this.state.phoneFocused ? (
                          <div>
                            <center>
                              <div style={{ margin: "20px 0px" }}>
                                <input
                                  id="guestAmount"
                                  value={this.state.amount}
                                  onChange={event =>
                                    this.handleAmountChange(event)
                                  }
                                  type="text"
                                  pattern="[0-9]*"
                                  placeholder="Amount to be sent"
                                />
                              </div>
                              <div style={{ margin: "20px 0px" }}>
                                <input
                                  id="guestNote"
                                  type="text"
                                  value={this.state.note}
                                  placeholder={`Note for ${this.state.display_name
                                    }`}
                                  onChange={event => this.handleNoteChange(event)}
                                />
                              </div>

                              {this.state.paymentComplete ? (
                                <div class="svg">
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>
                                </div>
                              ) : (
                                  <div />
                                )}
                              <div
                                style={{ padding: "20px", fontSize: "1.2em" }}
                                className={
                                  this.state.statusState
                                    ? "successState"
                                    : "errorState"
                                }
                              >
                                {this.state.status}
                              </div>
                              {this.state.processingPayment ? (
                                <button
                                  disabled
                                  className="guestconfirm"
                                  id="guestconfirm"
                                  onClick={() => this.handleSubmitPayment()}
                                >
                                  WAITING...
                                  <Spin
                                    indicator={antIcon}
                                    id="momospinner"
                                    style={{
                                      paddingLeft: "10px",
                                      marginTop: "-23px"
                                    }}
                                  />
                                </button>
                              ) : (
                                  <button
                                    className="guestconfirm"
                                    id="guestconfirm"
                                    onClick={() => this.handleSubmitPayment()}
                                  >
                                    BEGIN PAYMENT
                                  </button>
                                )}
                            </center>
                          </div>
                        ) : (
                            <div />
                          )}
                      </div>
                      <center>
                        <ul
                          style={{ listStyle: "none" }}
                          className="availableCountries"
                        >
                          <br />
                          <li
                            style={{ display: "inline-block", margin: "5px 0px" }}
                          >
                            <Flag name="gh" />{" "}
                          </li>
                          <li
                            style={{ display: "inline-block", margin: "5px 0px" }}
                          >
                            <Flag name="ke" />{" "}
                          </li>
                          <li
                            style={{ display: "inline-block", margin: "5px 0px" }}
                          >
                            <Flag name="rw" />{" "}
                          </li>
                          <li
                            style={{ display: "inline-block", margin: "5px 0px" }}
                          >
                            <Flag name="tz" />{" "}
                          </li>
                          <li
                            style={{ display: "inline-block", margin: "5px 0px" }}
                          >
                            <Flag name="ug" />{" "}
                          </li>
                          <p style={{ fontSize: "0.8em", fontWeight: "600" }}>
                            Ghana • Kenya • Rwanda • Tanzania • Uganda
                        </p>
                        </ul>
                      </center>

                      {/* <Message color='orange' style={{ textAlign: 'center', fontFamily: 'graphik', fontSize: '0.8em', border: 'none' }}>
                        Payment links are temporarily unavailable for sending to <b>KENYA</b>. Please download the app for uninterrupted access. <br />
                        <a href="https://itunes.apple.com/us/app/chipper-cash/id1353631552?mt=8"> Apple App Store </a>|
                        <a href="https://play.google.com/store/apps/details?id=com.chippercash"> Android Play Store </a>
                      </Message> */}


                      <div style={{ position: "relative", marginTop: "30px" }}>
                        <br />{" "}
                        <img
                          style={{ position: "absolute", bottom: 0, right: 0 }}
                          height={30}
                          src={logo}
                        />
                      </div>
                    </div>
                  ) : (
                      <div>
                        <center>
                          <Avatar
                            src={boxlogo}
                            size={80}
                            style={{
                              position: "absolute",
                              top: "-40px",
                              left: "0",
                              right: "0",
                              margin: "0 auto",
                              zIndex: "2",
                              boxShadow: "0.25px 0.25px 0.5px 0.5px #777"
                            }}
                          />
                        </center>
                        <br />
                        <br />
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "1.5em",
                            fontWeight: "400",
                            color: "black"
                          }}
                        >
                          Uh oh!
                    </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "1.0em",
                            fontWeight: "700",
                            padding: "10%",
                            color: "black"
                          }}
                        >
                          @{fetchedTag} not found.
                    </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "1.0em",
                            fontWeight: "400",
                            color: "black"
                          }}
                        >
                          You may have a wrong link or @cashtag name. Please confirm
                          with the intended recipient.
                    </div>
                        <div style={{ position: "relative", marginTop: "30px" }}>
                          <br />{" "}
                          <img
                            style={{ position: "absolute", bottom: 0, right: 0 }}
                            height={30}
                            src={logo}
                          />
                        </div>
                      </div>
                    )}
                </div>
              )}
          </Segment>
        </div>
      </div>
    );
  }
}

export default TagsPayment;
