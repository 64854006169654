function colorForStatus (status) {
    switch (status) {
        case 'SETTLED':
            return 'green'
        case 'FAILED':
            return 'red'
        case 'CANCELLED':
            return 'grey'
        case 'PENDING':
            return 'blue'
        case 'PAYMENT_SUBMITTED':
            return 'yellow'
        default:
            return 'black'
    }
}

module.exports = {
    colorForStatus
}