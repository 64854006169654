import React, { Component } from "react";
import { InputNumber, Button, Select } from "antd";
import Card from "../tim-components/Card.jsx";

export default class InitiateDeposit extends Component {
  state = {
    amount: "",
    selectedLinkedAccountId: ""
  };

  handleAmountChange = value => {
    this.setState({
      amount: value
    });
  };

  handleLinkedAccountChange = linkedAccountId => {
    this.setState({
      selectedLinkedAccountId: linkedAccountId
    });
  };

  handleAction = event => {
    const { onAction } = this.props;
    const { amount, selectedLinkedAccountId } = this.state;
    onAction(amount, selectedLinkedAccountId);
  };

  render() {
    const { currency, linkedAccounts } = this.props;
    const { amount } = this.state;

    return (
      <Card
        title={`Initiate Deposit`}
        category={
          "Use this to create and complete a deposit to this users account. For when a user added cash but it never updated. Use this over the Admin Send Money card above"
        }
        content={
          <div>
            <InputNumber
              style={{ width: "100%", margin: 5 }}
              placeholder="Amount..."
              size={"large"}
              defaultValue={amount}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value.replace(/\$\s?|(,*)/g, "")}
              onChange={this.handleAmountChange}
            />
            <br />
            <Select
              showSearch
              placeholder={"Linked Account to show deposit source"}
              style={{ width: "100%", margin: 5 }}
              onChange={this.handleLinkedAccountChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {linkedAccounts.map((item, index) => {
                return (
                  (item.mobile_money_carrier === undefined && item.card_network === undefined) ?
                    <Select.Option key={index} value={item.id} disabled>
                      Banks are not available for this function
                    </Select.Option>
                    :
                    (item.mobile_money_carrier === undefined) ?
                      <Select.Option key={index} value={item.id}>
                        {`${item.card_network}  ${
                          ""
                          } | ···· ···· ···· ${item.card_last_four}`}
                      </Select.Option>
                      :
                      <Select.Option key={index} value={item.id}>
                        {`${item.mobile_money_carrier}  ${
                          item.mobile_money_country
                          } | ${item.mobile_money_phone}`}
                      </Select.Option>

                );


              })}
            </Select>
            <br />
            <Button
              style={{ margin: 5 }}
              type={"primary"}
              onClick={this.handleAction}
            >{`Complete ${currency} Deposit`}</Button>
          </div>
        }
      />
    );
  }
}
